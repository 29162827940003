import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../core/user.service';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { PermissionService } from './permission.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    public authService: AuthService, 
    public storageService: StorageService,
    private router: Router,
    public permisionService: PermissionService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let path: any = route.url;
    let root: any = route;
    let splt = root._routerState.url.split('/');        

    if(path[0])
    {
      console.log('route.url[0].path', path[0].path);
    }
    
    if(this.storageService.isAuthenticated()) {
      if(this.storageService.getCurrentDB() && splt[1] != this.storageService.getCurrentDB()){
        this.router.navigate(['/'+ this.storageService.getCurrentDB() + '/dashboard']);
        return false;
      }

      if(!this.storageService.getCurrentDB()){
        this.router.navigate(['/'+ splt[1] + '/login']);
        return false;
      }
      
      if(!this.authService.customerDB) {
        console.log('this.authService.customerDB', this.authService.customerDB);
        await this.authService.setDataBaseCustomer(this.storageService.getCurrentDB());
      }

      console.log('this.permisionService.getPermissions()', this.permisionService.getPermissions());
      if(path[0] && this.permisionService.getPermissions() != null)
      {
        
        if(splt[3])
        {
          if(splt[3] == 'view'){
            splt[3] = 'viewer';
          }
          console.log('this.permisionService.canDo(splt[2], splt[3]))', this.permisionService.canDo(splt[2], splt[3]));
          if(!this.permisionService.canDo(splt[2], splt[3])){
            this.router.navigate(['/'+ this.storageService.getCurrentDB() + '/dashboard']);
            return false;
          }
        }else{
          
          if(!this.permisionService.canMenu(splt[2])){
            this.router.navigate(['/'+ this.storageService.getCurrentDB() + '/dashboard']);
            return false;
          }
        }
        
      }

      return true;
    }

    
    this.router.navigate(['/login']);
    return false;
  }
}
