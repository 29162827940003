import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigCustomer } from '../models/customer';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public customerDB: firebase.database.Database = null;
  public customerSelected: ConfigCustomer = null;
  api_customer_key = '';
  constructor(public http: HttpClient,) {
    this.api_customer_key = sessionStorage.getItem('api_customer_key');
  }

  getNotificaction(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment.headerAuthorization,
      'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster +  '/getNotifications';
    return this.http.get(url, {headers});
  }

}
