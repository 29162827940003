import { NgModule } from '@angular/core';

import { TotalDurationDashboard } from './totalDuration.pipe';
import { DurationDashboard } from './duration.pipe';
import { ReverseDate } from './reverse.pipe';




@NgModule({
    declarations: [
        TotalDurationDashboard,
        DurationDashboard,
        ReverseDate,
    ],
    imports: [],
    exports: [
        TotalDurationDashboard,
        DurationDashboard,
        ReverseDate,
    ],
})
export class PipesModule {}
