import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { StorageService } from '../core/storage.service';
import { UtilsService } from '../core/helper/utils.helper';
import { Router } from '@angular/router';
import { ListComponent } from './list/list.component';
import { TranslateService } from '@ngx-translate/core';

import swal from 'sweetalert';

import * as mockUsers from '../mocks/user-list.json';
import {SettingsAdminwebService} from "@app/core/settings-adminweb.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { take } from 'rxjs/operators';
import { PermissionService } from '../core/permission.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  public user_email = '-----------------------------';
  public user_list: any = [];
  public users_by_page: number = 10;
  public user_current_page: number = 1;

  public user_filter: string = '';

  public current_component: any;

  isAdd: boolean = false;
  isEdit: boolean = false;
  @ViewChild('current_page') current_page;

  get currentCustomer() {
    return this.storageService.getCurrentDB();
  }

  /*get getUserList() {
    return this.user_list && this.user_list.filter((f) => {
      return this.utilsService.search(f, 'email', this.user_filter) || this.utilsService.search(f, 'role', this.user_filter)
    }).filter((item) => item.profile !== 'SUPERADMIN').length;
  }*/

  active = 0;
  inactive = 0;
  limit = 10;
  disabledbtn = true;
  constructor(
      public userService: UserService,
      public authService: AuthService,
      public storageService: StorageService,
      public router: Router,
      public utilsService: UtilsService,
      public translateService: TranslateService,
      public settingsService: SettingsAdminwebService,
      private spinner: NgxSpinnerService,
      private modalGeneric: ModalGenericService,
      public permisionService: PermissionService,

  ) {
    if(authService.customerDB != null){
      userService.customerDB = authService.customerDB;
      userService.customerSelected = authService.customerSelected;
      settingsService.customerDB = authService.customerDB;
      userService.getCurrentUser().then((user) => {
        this.user_email = user.email;
      });

      /*userService.getUsersListBigquery().subscribe((response) => {
        console.log('aqui estoy');
        this.user_list = response.data.map(r => {
          return { ...r, ...{ selected: false }}
        });
      });*/

      userService.getUserLimit().subscribe((response) => {
        this.active = response.active;
        this.inactive = response.inactive;
        this.limit = response.limit_user_creation;
        if(this.limit <= this.active){
          this.disabledbtn = true;
        }
        else{
          this.disabledbtn = false;
        }
      });
    }
    //this.isAdd =  this.outer.url.includes("add");
  }

  ngAfterViewChecked(){
  }

  ngOnInit() {
  }

  pageChanged($event){
    this.user_current_page = $event;
  }

  salir() {
    this.authService.doLogout().then(() => {
      if(this.storageService.getCurrentDB() != '') {
        const ruta = '/' + this.storageService.getCurrentDB() + '/login';
        this.storageService.logout();
        this.router.navigate([ruta]);
      }
    })
        .catch((err) => {
          // console.error('Error LogOut -> ' + err);
          if(this.storageService.getCurrentDB() != '') {
            const ruta = '/' + this.storageService.getCurrentDB() + '/login';
            this.storageService.logout();
            this.router.navigate([ruta]);
          }
        });
  }

  button_delete_action() {
    if (this.current_component) {
      let deleted_list: [] = this.current_component.user_list.filter(f => f.selected).map(m => m.uid);

      if (deleted_list.length > 0) {
        this.modalGeneric.onModelconfirmacion(
            false, //disableClose
            this.translateService.instant('PAGES.USER_DELETE.R_U_SURE_TEXT'), //message
            true,  // cancelButton
            this.translateService.instant('PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'), //confimButtonText
            true, // info
            false, //imagen info
            //title
        )
            .afterClosed()
            .pipe(take(1))
            .subscribe(resp => {
              if (resp === 'aceptar') {
                this.userService.deleteUserList(deleted_list).subscribe(response => {
                  if (response.status === 'Ok') {
                    this.sendSuccess('PAGES.USER_DELETE.DELETED')
                  } else {
                    this.sendModelError(this.translateService.instant('PAGES.USER_DELETE.ERRORS.RESPONSE'))
                  }
                });
              }
            })
      } /// end if deleted list
    }
  }

/*   buttonSetMaxUsers() {
    let dataSettings = JSON.parse(this.storageService.getCurrentSettingsAdminWeb());

    swal(this.translateService.instant('PAGES.USER_LIST.SET_MAX_USERS_MODAL'), {content: "input"} as any).then((value) => {
      this.spinner.show()
      let regNumber = new RegExp('^[0-9]+$');
      if (regNumber.test(value)) {
        this.settingsService.updateSettingsAdminWeb(dataSettings.id, {max_users: +value}).then((response) => {
          this.storageService.setCurrentSettingsAdminWeb(this.authService.customerDB);
          setTimeout(() => {
            this.spinner.hide()
            this.sendSuccess('PAGES.USER_LIST.SET_MAX_SUCCESSFUL')
          }, 4000);
        }).catch((error) => {
          this.sendModelError(this.translateService.instant('PAGES.USER_LIST.SET_MAX_USERS_ERROR', {error: error.message}))
        });
      } else {
        this.spinner.hide()
        this.sendModelError('PAGES.USER_LIST.SET_MAX_USERS_MODAL_ERROR')
      }
    });
  } */

  sendModelError(msg: string) {
    this.modalGeneric.onModelerror(
        false, //disableClose
        msg, //message
        false,  // cancelButton
        this.translateService.instant('MESSAGE.ACEPTAR'),  //confimButtonText
        true, // info
        false, //imagen info
    )
  }

  sendSuccess(txt: string) {
    this.modalGeneric.onModelexito(
        false, //disableClose
        txt, //message
        false,  // cancelButton
        this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
        true, // info
        false, //imagen info
    )
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          const ruta = '/' + this.storageService.getCurrentDB() + '/user-list';
          this.router.navigate([ruta]);
        })
  }

  onActivate(componentRef) {
    this.current_component = null;
    if (componentRef instanceof ListComponent) {
      this.current_component = componentRef;
    }
  }
}
