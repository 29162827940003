import { M } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
export class UtilValidator {
    static isUrlValid(control: FormControl): any {

        let url;

        if (!control.value) return null

        console.log('Validacion', control.value)
        try {
            url = new URL(control.value);
        } catch (_) {
            console.log('Error')
            return { 'url': true };
        }

        if (url.protocol === "http:" || url.protocol === "https:") {

            return null;

        }
        console.log('Error2')
        return { 'url': true };;
    }

    static isJsonValid(control: FormControl): any {


        console.log('isJsonValid');

        console.log(control.value);


        if (!control.value) return null

        try {
            JSON.parse(control.value);

            console.log('Es valido');
        } catch (e) {

            console.log('No es valido')
            return {'isJson':true};
        }
        return null;

    }


}