import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
//import * as geofirex from 'geofirex';
import { formatDate } from "@angular/common";
import { Observable, BehaviorSubject, from } from "rxjs";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { AgmMap } from "@agm/core";
//import { GeoFireQuery } from 'geofirex';
import { ItemEvent, Photos, questions } from "../models/evidence";
import { UserService } from "../core/user.service";
import { ModulosService } from "../core/modulos.service";
import { AuthService } from "../core/auth.service";
import { StorageService } from "../core/storage.service";
import { UtilsService } from "../core/helper/utils.helper";
import { AngularFireStorage } from "@angular/fire/storage";
import * as firebase from "firebase/app";

import { ExcelService } from "../services/excel.service";
import { MenuComponent } from "../components/menu/menu.component";

import * as jspdf from "jspdf";
import domToPdf from "dom-to-pdf";

declare let html2canvas: any;
//var domToPdf = require('dom

import { ScriptService } from "../script.service";
import * as pdfMake from "pdfmake";
//import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import * as crypto from "crypto-js";
import { EncryptionDecryptionService } from "@app/services/encryption-decryption.service";

interface config_firebase {
  apiKey: string;
  appId: string;
  authDomain: string;
  databaseURL?: string;
  measurementId: string;
  messagingSenderId: string;
  projectId: string;
}

interface client {
  name?: string;
  logo?: string;
  doc: string;
}

interface customer_object {
  config_firebase: config_firebase;
  customer_key: string;
  url_alias: string;
  domain: string;
  logo_url: string;
  name: string;
  path_login: string;
  rules_login: string;
  url_pdf: string;
}

interface settings {
  cloneOrders: boolean;
  createItems: boolean;
  createOrders: boolean;
  deleteItems: boolean;
  deleteOrders: boolean;
  descriptionEvidenceRequired: boolean;
  elastic_authorization: string;
  elastic_endpoint_search: string;
  icon_comentarios: string;
  icon_photo: string;
  icon_signature: string;
  label_name: string;
  relationOneToMany: boolean;
  url_num_orders: string;
  url_pdf_web: string;
  url_report: string;
  logo_client: string;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboardadmin.component.html",
  styleUrls: ["./dashboardadmin.component.scss"],
  providers: [ExcelService],
})
export class DashboardComponentAdmin implements OnInit {
  @ViewChild("menuHeader") menuHeader: MenuComponent;

  user_email = "-----------------------------";
  url_logo_client = "";
  public clientes: client[] = [];
  public showForm: Boolean = false;
  createCustomerForm: FormGroup;
  errorMessage: string = "";
  public users_by_page: number = 10;
  public user_current_page: number = 1;
  public label_button = "Add Customer";
  selectedImage: any = null;
  imgSrc: string = "/assets/images/image_placeholder.jpg";
  extencion_image: string = "";
  is_image: boolean = false;

  new_customer_object: customer_object = {
    config_firebase: {
      apiKey: "",
      appId: "",
      authDomain: "",
      databaseURL: "",
      measurementId: "",
      messagingSenderId: "",
      projectId: "",
    },
    customer_key: "",
    url_alias: "",
    domain: "",
    logo_url: "",
    name: "",
    path_login: "",
    rules_login: "",
    url_pdf: "",
  };

  settings: settings = {
    cloneOrders: true,
    createItems: true,
    createOrders: true,
    deleteItems: true,
    deleteOrders: true,
    descriptionEvidenceRequired: false,
    elastic_authorization: "",
    elastic_endpoint_search: "",
    icon_comentarios:
      "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Ficons_default_icons_default_drive.png?alt=media&token=94993b79-18a2-44b9-87d1-d344c7428d8e",
    icon_photo:
      "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Ficons_default_icons_default_insert_photo.png?alt=media&token=da377040-5c97-4d99-b7a0-cf3cb66cc88a",
    icon_signature:
      "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Ficons_default_icons_default_firma.png?alt=media&token=3b8987ce-129f-4f21-b325-3e7a2207cce4",
    label_name: "Actividad",
    relationOneToMany: true,
    logo_client: "",
    url_num_orders: "",
    url_pdf_web: "",
    url_report: "",
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserService,
    public authService: AuthService,
    public storageService: StorageService,
    public utilsService: UtilsService,
    private excelService: ExcelService,
    public modulosService: ModulosService,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private encryptDecryptService: EncryptionDecryptionService
  ) {
    if (authService.customerDB != null) {
      userService.customerDB = authService.customerDB;
      modulosService.customerDB = authService.customerDB;
      userService.getCurrentUser().then((user) => {
        this.user_email = user.email;
        this.url_logo_client = "";
      });
    }
    this.createForm();
  }

  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.extencion_image = event.target.files[0]["type"];
      var strArr = this.extencion_image.split("/");
      if (strArr[0] == "image") {
        this.extencion_image = strArr[1];
        console.log(this.extencion_image);
        const reader = new FileReader();
        reader.onload = (e: any) => (this.imgSrc = e.target.result);
        reader.readAsDataURL(event.target.files[0]);
        this.selectedImage = event.target.files[0];
        console.log(event.target.files[0]);
        console.log(event);
        this.errorMessage = "";
        this.is_image = true;
      } else {
        this.imgSrc = "/assets/images/image_placeholder.jpg";
        this.selectedImage = null;
        this.errorMessage = "Wrong format image";
        this.is_image = false;
      }
    } else {
      this.imgSrc = "/assets/images/image_placeholder.jpg";
      this.selectedImage = null;
      this.is_image = false;
    }
  }

  ngOnInit() {
    this.userService.customerDB.app
      .firestore()
      .collection("customers")
      .get()
      .then((snap) => {
        if (snap.size > 0) {
          var datos = snap.docs;
          datos.forEach((value) => {
            console.log(value.data());
            this.clientes.push({
              name: value.data().name,
              logo: value.data().logo_url,
              doc: value.id,
            });
          });
          console.log(this.clientes);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
      });
  }

  salir() {
    this.authService
      .doLogout()
      .then(() => {
        if (this.storageService.getCurrentDB() != "") {
          const ruta =
            "/sintra/" + this.storageService.getCurrentDB() + "/login";
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      })
      .catch((err) => {
        // console.error('Error LogOut -> ' + err);
        if (this.storageService.getCurrentDB() != "") {
          const ruta =
            "/sintra/" + this.storageService.getCurrentDB() + "/login";
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      });
  }
  trySend(value) {
    console.log("try send");
    this.new_customer_object.logo_url = "";
    console.log(value);
    console.log("imagen");
    console.log(this.selectedImage);

    var filepath = "/customers/" + value.name + "/logotipo.jpg";
    var fileRef = this.storage.ref(filepath);
    try {
      this.new_customer_object.config_firebase = JSON.parse(value.configjson);
    } catch (error) {
      this.errorMessage = "Wrong format in SDK field";
      return false;
    }

    this.storage
      .upload(filepath, this.selectedImage)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            this.new_customer_object.logo_url = "" + url + "";
            this.new_customer_object.name = value.name;
            this.new_customer_object.rules_login =
              '{"params_login": {"keys": [{"nameKey": "usuario","value": "","type": "text","options": ""},{"nameKey": "password","value": "","type": "password","options": ""}]} }';
            this.new_customer_object.customer_key = value.customerKey;
            this.new_customer_object.url_alias = value.urlAlias;
            this.new_customer_object.domain =
              "https://us-central1-" +
              this.new_customer_object.config_firebase.projectId +
              ".cloudfunctions.net";
            this.new_customer_object.url_pdf =
              "https://us-central1-" +
              this.new_customer_object.config_firebase.projectId +
              ".cloudfunctions.net/getPdfReport3";
            let configEncrypted =
              this.encryptDecryptService.encryptConfigFirebase(
                this.new_customer_object
              );
            this.userService.customerDB.app
              .firestore()
              .collection("customers")
              .add(configEncrypted)
              .then((snap) => {
                var customerApp_0 = firebase.initializeApp(
                  this.new_customer_object.config_firebase,
                  value.customerKey
                );
                var vacio = {};
                var template = { template_pdf: "" };
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("clone_orders")
                  .add(vacio);
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("completedorders")
                  .add(vacio);
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("items_config")
                  .add(vacio);
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("modules")
                  .add(vacio);
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("orders")
                  .add(vacio);
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("questionnaires")
                  .add(vacio);
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("reports")
                  .add(vacio);
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("templates")
                  .add(template);
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("user_details")
                  .add(vacio);
                this.settings.url_num_orders =
                  "https://us-central1-" +
                  this.new_customer_object.config_firebase.projectId +
                  ".cloudfunctions.net/getTotalOrders";
                this.settings.url_pdf_web =
                  "https://us-central1-" +
                  this.new_customer_object.config_firebase.projectId +
                  ".cloudfunctions.net/getPdfReport3?order_id=";
                this.settings.url_report =
                  "https://us-central1-" +
                  this.new_customer_object.config_firebase.projectId +
                  ".cloudfunctions.net/detail-order?order_uui=";
                this.settings.logo_client = this.new_customer_object.logo_url;
                customerApp_0
                  .database()
                  .app.firestore()
                  .collection("settings")
                  .add(this.settings);

                let currentUrl = this.router.url;
                this.router
                  .navigateByUrl("/", { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate([currentUrl]);
                  });
              })
              .catch((error) => {
                this.errorMessage =
                  "error al generar coleccion principal" + error;
                return false;
              });
          });
        })
      )
      .subscribe();
  }
  public editCustomer(docid, mode) {
    console.log(docid);
    var ruta =
      "/sintra/" +
      this.authService.customer_route +
      "/edit-customer/" +
      docid +
      "/" +
      mode;
    this.router.navigate([ruta]);
  }

  get currentCustomer() {
    return this.storageService.getCurrentDB();
  }
  createForm() {
    this.createCustomerForm = this.fb.group({
      configjson: ["", Validators.required],
      name: ["", Validators.required],
      customerKey: ["", Validators.required],
      urlAlias: ["", Validators.required],
      file: ["", Validators.required],
    });
  }

  addCustomer() {
    if (this.showForm) {
      this.showForm = false;
      this.label_button = "Add Customer";
    } else {
      this.showForm = true;
      this.label_button = "Return";
    }
  }

  get nameHasError(): boolean {
    return (
      this.createCustomerForm.get("name").invalid &&
      (this.createCustomerForm.get("name").dirty ||
        this.createCustomerForm.get("name").touched)
    );
  }

  get CustomerkeyHasError(): boolean {
    return (
      this.createCustomerForm.get("customerKey").invalid &&
      (this.createCustomerForm.get("customerKey").dirty ||
        this.createCustomerForm.get("customerKey").touched)
    );
  }

  get UrlAliasHasError(): boolean {
    return (
      this.createCustomerForm.get("urlAlias").invalid &&
      (this.createCustomerForm.get("urlAlias").dirty ||
        this.createCustomerForm.get("urlAlias").touched)
    );
  }

  get configjsonHasError(): boolean {
    return (
      this.createCustomerForm.get("configjson").invalid &&
      (this.createCustomerForm.get("configjson").dirty ||
        this.createCustomerForm.get("customerKey").touched)
    );
  }

  get fileHasError(): boolean {
    return (
      this.createCustomerForm.get("file").invalid &&
      (this.createCustomerForm.get("file").dirty ||
        this.createCustomerForm.get("file").touched)
    );
  }

  validForm() {
    return this.createCustomerForm.valid && this.is_image;
  }
}
