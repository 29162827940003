import { EquipmentService } from "./../../core/equipment.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../core/auth.service";
import { UtilsService } from "../../core/helper/utils.helper";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../../core/storage.service";
import { CustomersService } from "../../customers.service";
import { ModalGenericService } from "../../services/modal-generic.service";
import { PermissionService } from "../../core/permission.service";
import { UserService } from "../../core/user.service";
import { ordenesObj } from "../../models/order";
import { ModulosService } from "../../core/modulos.service";
import { programmingObj } from "@app/models/programming";
import { ProgramingService } from "../../services/programing.service";
import { EventsService } from "../../core/events.service";
import { take } from "rxjs/operators";
import { Params } from "@angular/router";
import { Observable, BehaviorSubject, iif } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ProgrammingAddComponent } from "../programming-add/programming-add.component";
import * as Moment from "moment";
import { formatDate } from "@angular/common";
import { TimezoneConversionService } from "@app/core/timezone-conversion.service";
import { ClassificationService } from "@app/core/classification.service";

@Component({
  selector: "app-programming-table",
  templateUrl: "./programming-table.component.html",
  styleUrls: ["./programming-table.component.scss"],
})
export class ProgrammingTableComponent implements OnInit {
  public programmings_by_page: number = 10;
  public programming_current_page: number = 1;

  public programming_filter: string = "";
  programming: programmingObj[] = [];

  private programmingList: programmingObj[];
  private programmingList_original: programmingObj[];
  private programmingList_serach_original: programmingObj[];
  programmin_filter: string = "";

  type_filter: string = "";
  public events_list: any = [];
  public events_list_original: any = [];
  filter_select = [
    "Programada",
    "Abierta",
    "En revisión",
    "Autorizada",
    "Rechazada",
    "Vencida",
    "Cerrada",
    "Archivada",
  ];
  estatusSelect: string = "";
  statusSelect: string = "";
  btn_save: boolean = false;
  type_event: string = "";
  status: string = "";
  fecha_creacion_inic: string;
  fecha_creacion_fin: string;
  fecha_programacion_inic: string;
  fecha_programacion_fin: string;
  fecha_limit_inic: string;
  fecha_limit_fin: string;
  period: string;

  date_programming_start: string;
  date_programming_end: string;
  time_event_select: string = "hoy";
  event_type_select: string = "";
  status_filter: string = "";
  text_search: string = "";

  time_list = [
    {
      name: "última semana",
    },
    {
      name: "último mes",
    },
    {
      name: "próxima semana",
    },
    {
      name: "próximo mes",
    },
    {
      name: "rango de fechas",
    },
  ];

  private ordenesSubject = new BehaviorSubject([]);
  public programmingList$: programmingObj[];
  periodicity: any[];
  type_send_scheduleds: any;
  show_date_range: boolean = false;
  show_all_events: boolean = false;
  status_filter_all: string = "status_filter_all";
  asignedTo_filter_all: string = "asignedTo_filter_all";
  client_filter_all: string = "client_filter_all";
  equipment_filter_all: string = "equipment_filter_all";
  classification_filter_all: string = "classification__filter_all";
  subclassification_filter_all: string = "subclassification_filter_all";
  array_asignedTo = [];
  array_client = [];
  array_equipment = [];
  filtersSheduledEvents: any = [];
  fiel_classification_title: string = "";
  fiel_subclassification_title: string = "";
  filter_classification_sub = false;
  categories_list: any[];
  subcategories_list: any[];
  array_categories = [];
  array_subcategories = [];
  get getFormsList() {
    let returns;
    switch (this.time_event_select) {
      case "última semana":
      case "último mes":
        returns =
          this.programmingList$ &&
          this.programmingList$
            .filter((f) => {
              return this.utilsService.search(
                f,
                "scheduled_name_event",
                this.programmin_filter
              );
            })
            .sort(function (a: any, b: any) {
              let adate = new Date(
                a.scheduled_programming_date._seconds * 1000
              );
              let bdate = new Date(
                b.scheduled_programming_date._seconds * 1000
              );

              //console.log(a.scheduled_hour_since, '\t', b.scheduled_hour_limit);

              let sice = a.scheduled_hour_since.split(":");
              let limit = b.scheduled_hour_since.split(":");

              adate.setHours(sice[0], sice[1], 0, 0);
              bdate.setHours(limit[0], limit[1], 0, 0);

              if (adate < bdate) {
                return 1;
              }
              if (adate > bdate) {
                return -1;
              }
              return 0;
            });
        break;
      case "rango de fechas":
      case "próxima semana":
      case "próximo mes":
        returns =
          this.programmingList$ &&
          this.programmingList$
            .filter((f) => {
              return this.utilsService.search(
                f,
                "scheduled_name_event",
                this.programmin_filter
              );
            })
            .sort(function (a: any, b: any) {
              let adate = new Date(
                a.scheduled_programming_date._seconds * 1000
              );
              let bdate = new Date(
                b.scheduled_programming_date._seconds * 1000
              );

              //console.log(a.scheduled_hour_since, '\t', b.scheduled_hour_limit);

              let sice = a.scheduled_hour_since.split(":");
              let limit = b.scheduled_hour_since.split(":");

              adate.setHours(sice[0], sice[1], 0, 0);
              bdate.setHours(limit[0], limit[1], 0, 0);

              if (adate > bdate) {
                return 1;
              }
              if (adate < bdate) {
                return -1;
              }
              return 0;
            });
        break;
      default:
        returns =
          this.programmingList$ &&
          this.programmingList$.filter((f) => {
            return this.utilsService.search(
              f,
              "scheduled_name_event",
              this.programmin_filter
            );
          });
        break;
    }

    return returns;
  }

  get getFormsListEvents() {
    return (
      this.events_list &&
      this.events_list
        .filter((f) => {
          return f.active === true;
        })
        .sort(function (a, b) {
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          return 0;
        })
    );
  }

  private searchfilters(name = "scheduled_name_event") {
    let valor = this.programmingList
      .filter((f) => {
        return this.utilsService.search(
          f,
          "scheduled_name_event",
          this.programmin_filter
        );
      })
      .filter((f) => {
        return f.status !== "5";
      });
    return valor;
  }

  pageChanged($event) {
    this.programming_current_page = $event;
  }

  round(value) {
    return Math.ceil(value);
  }

  updateCurrentPage(value) {
    try {
      if (this.programmings_by_page !== 1) {
        if (value == 1) {
          this.programming_current_page = 1;
        }
      }
    } catch (err) {}
  }

  getPeriodicidad(value: string): string {
    let returns: string = "";

    switch (value) {
      case "everyday":
        returns = "Cada día";
        break;
      case "everymonth":
        returns = "Cada mes";
        break;
      case "notrepeat":
        returns = "No se repite";
        break;
      case "mondaytosaturday":
        returns = "Todos los días de lunes a sábado";
        break;
      case "everyweek":
        returns = "Cada semana";
        break;
      case "mondaytofriday":
        returns = "Todos los días de lunes a viernes";
        break;
      case "everyfifteenday":
        returns = "Cada 15 días";
        break;
    }
    return returns;
  }

  show_classification = false;

  constructor(
    public authService: AuthService,
    public utilsService: UtilsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public translateService: TranslateService,
    public storageService: StorageService,
    public customerService: CustomersService,
    private dialog: MatDialog,
    public userService: UserService,
    private modalGeneric: ModalGenericService,
    public modulosService: ModulosService,
    public permisionService: PermissionService,
    public eventsService: EventsService,
    private programingservice: ProgramingService,
    public classificationService: ClassificationService,
    public equipmentService: EquipmentService
  ) {
    this.programingservice.getObservable().subscribe((data) => {
      console.log("Data received:>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", data);
      this.getSchedulesEvents();
    });
  }

  ngOnInit() {
    this.spinner.show();
    if (this.authService.customerDB != null) {
      this.programingservice.customerDB = this.authService.customerDB;
      this.programingservice.customerSelected =
        this.authService.customerSelected;
      //this.modulosService.getModulos();
      //this.Period();

      this.classificationService
        .getDataClassification()
        .subscribe(async (response) => {
          //console.log(response.data[0].categories);

          if (response) {
            if (response.data.length > 0) {
              this.fiel_classification_title = response.data[0].name_category;
              this.fiel_subclassification_title =
                response.data[0].name_subcategory;

              let data_categories: any = response.data[0].categories;
              this.categories_list = data_categories;

              let data_array_subcategories: any[] = [];
              data_categories.forEach((element) => {
                element.subcategories.forEach((e) => {
                  data_array_subcategories.push({
                    firebase_id: e.firebase_id,
                    name: e.name,
                    id: element.firebase_id,
                  });
                });
              });

              this.subcategories_list = data_array_subcategories;
            }
          }
        });

      this.eventsService.getEventsList().subscribe((response) => {
        this.events_list = response.data.map((r) => {
          return { ...r, ...{ selected: false } };
        });
        this.events_list.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        this.spinner.hide();
      });

      this.programingservice.getPeriodicty({}).subscribe(
        (response: any) => {
          console.log("response.data", response.periodicity);
          this.periodicity = response.periodicity;
        },
        (error) => {
          console.log("error", error);
        }
      );

      /*
        this.eventsService.customerDB = this.authService.customerDB;
        this. eventsService.customerSelected = this.authService.customerSelected;
        this.spinner.show();
        this.eventsService.getEventsList().subscribe((response) => {
          this.events_list = response.data.map((r) => {
            return { ...r, ...{ selected: false } };
          });
          this.events_list_original = this.events_list;
          console.log(this.events_list);

        });*/

      this.equipmentService.getEquipments({}).subscribe((equipment) => {
        this.array_equipment = equipment.data;
        if (this.client_filter_all !== "client_filter_all") {
          this.array_equipment = this.array_equipment.filter(
            (item) => item.customer_id == this.client_filter_all
          );
        }
      });
    }
    this.filtersSheduledEvents = this.storageService.getFiltersSheduledEvents();
    console.log(
      "filtersSheduledEvents",
      JSON.parse(this.filtersSheduledEvents)
    );
    if (this.filtersSheduledEvents != null) {
      this.filtersSheduledEvents = JSON.parse(this.filtersSheduledEvents);
      this.time_event_select = this.filtersSheduledEvents["time_event_select"];
      this.type_event = this.filtersSheduledEvents["type_event"];
      this.event_type_select = this.filtersSheduledEvents["event_type_select"];
      this.status_filter = this.filtersSheduledEvents["status_filter"];

      this.show_date_range = this.filtersSheduledEvents["show_date_range"];
      if (this.time_event_select == "rango de fechas") {
        this.date_programming_start =
          this.filtersSheduledEvents["date_programming_start"];
        this.date_programming_end =
          this.filtersSheduledEvents["date_programming_end"];
      }
      this.getSchedulesEvents(true);
    }
  }

  edit(edit_id: string, customer_uid: string) {
    console.log("GG Before", customer_uid);
    const dialogRef = this.dialog
      .open(ProgrammingAddComponent, {
        data: {
          id: edit_id,
          customer_uid: customer_uid,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp == "aceptar") {
          this.programingservice.publishSomeData({
            data: "actualizar",
          });
        }
      });
  }

  onChange(e) {
    console.log("entro", e.target.value);
    this.type_filter = e.target.value;
    this.type_event = null;
    this.status = "";
    this.fecha_creacion_inic = "";
    this.fecha_creacion_fin = "";
    this.fecha_programacion_inic = "";
    this.fecha_programacion_fin = "";
    this.fecha_limit_inic = "";
    this.fecha_limit_fin = "";
  }

  DetailGo(order: ordenesObj) {
    if (this.storageService.getCurrentDB() != "") {
      const urlActividades =
        this.authService.customerSelected.url_report + order.firebase_id;
      window.open(urlActividades, "blank");
    }
  }

  clearFilter() {
    this.type_filter = "";
    this.programmingList = [];
    this.spinner.show();
    this.type_event = null;
    this.status = "";
    this.fecha_creacion_inic = "";
    this.fecha_creacion_fin = "";
    this.fecha_programacion_inic = "";
    this.fecha_programacion_fin = "";
    this.fecha_limit_inic = "";
    this.fecha_limit_fin = "";

    this.programmingList = this.programmingList_original;
    setTimeout(() => {
      // this.programmingList$ = this.getOrdenes();
      this.refresh();
      this.spinner.hide();
    }, 2000);
  }

  onfiltered() {
    let Parameters = null;
    switch (this.type_filter) {
      case "1":
        Parameters = {
          type: 1,
          create_date_inicio: this.fecha_creacion_inic,
          create_date_fin: this.fecha_creacion_fin,
        };
        break;
      case "5":
        Parameters = {
          type: 5,
          limit_date_inicio: this.fecha_limit_inic,
          limit_date_fin: this.fecha_limit_fin,
        };
        break;
      case "4":
        Parameters = {
          type: 4,
          programming_date_inicio: this.fecha_programacion_inic,
          programming_date_fin: this.fecha_programacion_fin,
        };
        break;
      case "2":
        Parameters = {
          type: 2,
          type_event: this.type_event,
        };
        break;
      case "3":
        let statud_id = "";
        if (this.status == "Programada") {
          statud_id = "1";
        } else if (this.status == "Cerrada") {
          statud_id = "3";
        } else if (this.status == "Vencida") {
          statud_id = "5";
        } else if (this.status == "Abierta") {
          statud_id = "2";
        } else if (this.status == "Archivada") {
          statud_id = "4";
        } else if (this.status == "") {
          return;
        }
        Parameters = {
          type: 3,
          status: statud_id,
        };

        break;
      case "6":
        Parameters = {
          type: 8,
          period: this.period,
        };
        break;
    }
    console.log("onfiltered", JSON.stringify(Parameters));
    this.programmingList = [];
    this.spinner.show();
    this.programingservice.getScheduledEventsFilters(Parameters).subscribe(
      (response) => {
        console.log("response", response);
        if (response.data.length == 0) {
          //this.showModalNoResults();
        }
        this.programmingList = response.data.map((r) => {
          return { ...r, ...{ selected: false } };
        });
        this.spinner.hide();
        console.log("this.programmin_filter", this.programmin_filter);

        let search = null;
        search = this.programmingList.filter((f) => {
          return f.status !== "6";
        });
        console.log("search", search);
        this.programmingList = [];
        this.programmingList = search;
        if (this.programmin_filter != "") {
          this.onsearch(this.programmin_filter);
        } else {
          console.log("this.programmingList", this.programmingList);
          // this.programmingList$ = this.getOrdenes();
          this.refresh();
        }
      },
      (error) => {
        console.log("error", error);
        this.spinner.hide();
      }
    );
  }

  showModalNoResults() {
    this.modalGeneric.onModalSearch(
      false, //disableClose
      this.translateService.instant("TITLE.NO_RESULTS"), //title
      "No se encontraron las actividades registradas con los filtros de búsqueda seleccionados. Favor de actualizar los criterios y realizar la búsqueda nuevamente", //message
      "warning", //type
      true, // info
      false, //imagen info
      this.translateService.instant("MESSAGE.KEEP_LOOKING") //confimButtonText
    );
  }

  onsearch(name_search: string) {
    var array_search: programmingObj[] = [];
    this.text_search = name_search;
    for (let item of this.programmingList_original) {
      if (item.scheduled_name_event.includes(name_search)) {
        array_search.push(item);
      }
    }

    this.programmingList$ = array_search;
    if (array_search.length == 0) {
      //this.showModalNoResults();
    }
    this.filtersSheduledEvents["text_search"] = this.text_search;
    this.storageService.setFiltersSheduledEvents(this.filtersSheduledEvents);

    // this.programmingList
    // .filter((f) => {
    //   return this.utilsService.search(f, 'scheduled_name_event', this.programmin_filter);
    // })

    // this.programmin_filter = d;
    // console.log(this.programmin_filter);
    // if(this.programmin_filter != "")
    // {
    //   this.programmingList_serach_original = [];
    //     this.programmingList_serach_original = this.programmingList;
    //     let search =
    //     this.programmingList
    //       .filter((f) => {
    //         return this.utilsService.search(f, 'scheduled_name_event', this.programmin_filter);
    //       })
    //       .filter((f) => {
    //         return f.status !== "6";
    //       });
    //       this.programmingList = [];
    //       this.programmingList = search;
    //       // this.programmingList$ = this.getOrdenes();
    //       this.refresh();
    // }else{
    //     console.log('this.programmingList_serach_original 1', this.programmingList_serach_original);
    //     if(this.programmingList_serach_original)
    //     {
    //       if(this.programmingList_serach_original.length > 0){
    //         this.programmingList = this.programmingList_serach_original;
    //         // this.programmingList$ = this.getOrdenes();
    //         this.refresh();
    //         this.programmingList_serach_original = [];
    //       }
    //     }
    //     console.log('this.programmingList_serach_original 2', this.programmingList_serach_original);
    // }
  }

  onChangeEstatus(estatus) {
    this.estatusSelect = estatus;
    this.statusSelect = estatus;
  }

  private fechainarray(date, tipo) {
    let spli = null;
    if (tipo == 1) {
      let formatted_date_fin =
        date.getDate().toString().length == 1
          ? "0" + date.getDate() + "/"
          : date.getDate() + "/";
      if ((date.getMonth() + 1).toString().length == 1) {
        formatted_date_fin += "0" + (date.getMonth() + 1);
      } else {
        formatted_date_fin += date.getMonth() + 1;
      }
      formatted_date_fin += "/" + date.getFullYear();
      spli = formatted_date_fin.split("/");
    } else {
      spli = Moment(date).format("Y-MM-DD");
    }
    return spli;
  }

  deleteEvent(cust: any, scheduled_periodicity: string, serie: string) {
    console.log(cust);
    var date = new Date(cust.scheduled_programming_date._seconds * 1000);

    let fecha = this.fechainarray(date, 2);
    console.log(fecha);
    this.modalGeneric
      .onModelconfirmacionEvent(
        scheduled_periodicity,
        false, //disableClose
        "¿Está seguro de eliminar esta actividad?", //message
        true, // cancelButton
        this.translateService.instant(
          "PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE"
        ), //confimButtonText
        true, // info
        false //imagen info
        //title
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp.button === "aceptar") {
          this.spinner.show();
          let ide = cust.firebase_id;
          let tipo = 2;
          if (resp.radiodelete == "all") {
            ide = serie;
            tipo = 1;
          }
          let params = { type: tipo, id: ide, fecha: fecha };
          console.log(params);
          this.programingservice.deleteProgrammingEvent(params).subscribe(
            (response) => {
              console.log("response", response);
              if (response.status == "ok") {
                this.spinner.hide();
                this.sendSuccess("La actividad se ha eliminado correctamente");
              }
            },
            (error) => {
              console.log("error", error);
            }
          );
        }
      });
  }

  storeEvent(cust: any, scheduled_periodicity: string, serie: string) {
    console.log(cust);
    var date = new Date(cust.scheduled_programming_date._seconds * 1000);

    let fecha = this.fechainarray(date, 2);
    console.log(fecha);
    this.modalGeneric
      .onModelconfirmacionEvent(
        scheduled_periodicity,
        false, //disableClose
        "¿Está seguro de archivar esta actividad?", //message
        true, // cancelButton
        this.translateService.instant(
          "PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE"
        ), //confimButtonText
        true, // info
        false, //imagen info
        "", //title
        "Si, archivar esta actividad.", //radio1
        "Si, archivar todas las actividades de aquí en adelante." //radio2
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp.button === "aceptar") {
          this.spinner.show();
          let ide = cust.firebase_id;
          let tipo = 2;
          if (resp.radiodelete == "all") {
            ide = serie;
            tipo = 1;
          }
          let params = { type: tipo, id: ide, status: "4", fecha: fecha };
          console.log(params);
          this.programingservice.updateStatusOrder(params).subscribe(
            (response) => {
              console.log("response", response);
              if (response.status == "ok") {
                this.spinner.hide();
                this.sendSuccess(
                  "La actividad se ha archivado correctamente",
                  false
                );
              }
            },
            (error) => {
              console.log("error", error);
            }
          );
        }
      });
  }

  sendSuccess(text: string, isDelete: boolean = true) {
    this.modalGeneric
      .onModelexito(
        false, //disableClose
        text, //message
        false, // cancelButton
        this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
        true, // info
        false //imagen info
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.btn_save = true;
        this.spinner.hide();
        if (isDelete) {
          this.getSchedulesEvents();
        } else {
          this.programingservice.publishSomeData({
            data: "actualizar",
          });
        }
      });
  }

  getOrdenes(): Observable<programmingObj[]> {
    return this.ordenesSubject.asObservable();
  }

  private refresh() {
    return new Promise((resolve) => {
      this.ordenesSubject.next(this.programmingList);
      setTimeout(resolve, (this.programmingList.length * 5000) / 900, true);
    });
  }

  onChangeTime(event) {
    this.time_event_select = event;
    if (this.time_event_select == "rango de fechas") {
      this.date_programming_start = "";
      this.date_programming_end = "";
      this.text_search = "";
      this.show_date_range = true;
      this.filtersSheduledEvents["date_programming_start"] =
        this.date_programming_start;
      this.filtersSheduledEvents["date_programming_end"] =
        this.date_programming_end;
      this.filtersSheduledEvents["show_date_range"] = this.show_date_range;
      this.storageService.setFiltersSheduledEvents(this.filtersSheduledEvents);
    } else {
      this.show_date_range = false;
      this.getSchedulesEvents();
    }
  }

  onChangeEvent(event) {
    this.event_type_select = event;
    this.status_filter = "";
    this.status_filter_all = "status_filter_all";
    this.asignedTo_filter_all = "asignedTo_filter_all";
    this.client_filter_all = "client_filter_all";
    this.classification_filter_all = "classification_filter_all";
    this.subclassification_filter_all = "subclassification_filter_all";
    this.equipment_filter_all = "equipment_filter_all";

    let find = this.events_list.find((x) => x.uid == event);

    if (find && find.active_category == true) {
      this.filter_classification_sub = true;
    }

    this.getSchedulesEvents();
  }

  async getSchedulesEvents(init = false) {
    if (!init) {
      this.status_filter = "";
      this.status_filter_all = "status_filter_all";
      this.asignedTo_filter_all = "asignedTo_filter_all";
      this.client_filter_all = "client_filter_all";
      this.classification_filter_all = "classification_filter_all";
      this.subclassification_filter_all = "subclassification_filter_all";
      this.equipment_filter_all = "equipment_filter_all";
      this.text_search = "";
    }

    if (this.event_type_select != "") {
      this.spinner.show();

      await this.getToDates();

      let Parameters = null;

      Parameters = {
        type: this.type_send_scheduleds,
        programming_date_inicio: this.date_programming_start,
        programming_date_fin: this.date_programming_end,
        type_event: this.event_type_select,
      };

      console.log("Parameters", Parameters);

      if (!init) {
        this.filtersSheduledEvents = {
          Parameters: Parameters,
          show_date_range: this.show_date_range,
          date_programming_start: this.date_programming_start,
          date_programming_end: this.date_programming_end,
          event_type_select: this.event_type_select,
          time_event_select: this.time_event_select,
          type_event: this.type_event,
          status_filter: this.status_filter,
          status_filter_all: this.status_filter_all,
          asignedTo_filter_all: this.asignedTo_filter_all,
          client_filter_all: this.client_filter_all,
          classification_filter_all: this.classification_filter_all,
          subclassification_filter_all: this.subclassification_filter_all,
          equipment_filter_all: this.equipment_filter_all,
          text_search: this.text_search,
        };
        this.storageService.setFiltersSheduledEvents(
          this.filtersSheduledEvents
        );
      } else {
        this.filtersSheduledEvents["Parameters"] = Parameters;
        this.storageService.setFiltersSheduledEvents(
          this.filtersSheduledEvents
        );
      }

      this.programingservice.getScheduledEventsFilters(Parameters).subscribe(
        (response) => {
          console.log("response <<<<<<<<<<<<<<<<<<<<<", response);
          if (response.data.length == 0) {
            /* this.modalGeneric.onModalSearch(
              false, //disableClose
              this.translateService.instant("TITLE.NO_RESULTS"), //title
              "No se encontraron actividades registradas con los filtros de búsqueda seleccionados. Favor de actualizar los criterios y realizar la búsqueda nuevamente", //message
              "warning", //type
              true, // info
              false, //imagen info
              this.translateService.instant("MESSAGE.KEEP_LOOKING") //confimButtonText
            );*/
          }
          this.programmingList = response.data.map((r) => {
            return { ...r, ...{ selected: false } };
          });
          this.spinner.hide();

          let search = this.programmingList.filter((f) => {
            return f.status !== "6";
          });
          this.programmingList = [];
          this.programmingList = search;

          if (this.programmingList.length > 0) {
            this.show_all_events = true;
          } else {
            this.show_all_events = false;
          }
          this.programmingList$ = this.programmingList;
          this.programmingList_original = this.programmingList$;
          this.show_classification = response.active_category;
          this.filter_classification_sub = response.active_category;

          this.getArrayAsigned(this.programmingList);

          this.refresh();

          if (init) {
            console.log(
              "getSchedulesEvents",
              true,
              this.filtersSheduledEvents["status_filter"],
              true,
              this.filtersSheduledEvents["asignedTo_filter_all"],
              true,
              this.filtersSheduledEvents["client_filter_all"],
              true,
              this.filtersSheduledEvents["classification_filter_all"],
              true,
              this.filtersSheduledEvents["subclassification_filter_all"],
              true,
              this.filtersSheduledEvents["equipment_filter_all"]
            );

            this.onChangeFilters(
              true,
              this.filtersSheduledEvents["status_filter_all"],
              true,
              this.filtersSheduledEvents["asignedTo_filter_all"],
              true,
              this.filtersSheduledEvents["client_filter_all"],
              true,
              this.filtersSheduledEvents["classification_filter_all"],
              true,
              this.filtersSheduledEvents["subclassification_filter_all"],
              true,
              this.filtersSheduledEvents["equipment_filter_all"]
            );

            this.onsearch(this.filtersSheduledEvents["text_search"]);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }

  async getToDates() {
    this.type_send_scheduleds = 9;
    var today = new Date();
    var day_now = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    var now = formatDate(day_now, "yyyy-MM-dd", "en");

    if (this.time_event_select == "última semana") {
      this.date_programming_start = await this.LastNextWeek("lastWeek");
      this.type_send_scheduleds = 10;
      this.date_programming_end = now;
    } else if (this.time_event_select == "último mes") {
      this.date_programming_start = await this.LastNextMonth("lastMonth");
      this.type_send_scheduleds = 10;
      this.date_programming_end = now;
    } else if (this.time_event_select == "próxima semana") {
      this.date_programming_start = now;
      this.date_programming_end = await this.LastNextWeek("");
    } else if (this.time_event_select == "próximo mes") {
      this.date_programming_start = now;
      this.date_programming_end = await this.LastNextMonth("");
    } else if (this.time_event_select == "rango de fechas") {
      console.log("NO NO NO STAAAA", this.date_programming_start);
      console.log("NO NO NO ENDDDD ", this.date_programming_end);

      if (this.date_programming_start && this.date_programming_end) {
        if (this.date_programming_start > this.date_programming_end) {
          this.type_send_scheduleds = 10;
        }
      }
    } else {
      this.date_programming_start = now;
      this.date_programming_end = now;
    }
  }

  LastNextWeek(param) {
    var today = new Date();
    var day = today.getDate() + 7;
    if (param == "lastWeek") {
      day = today.getDate() - 7;
    }
    var lastWeek = new Date(today.getFullYear(), today.getMonth(), day);
    var last = formatDate(lastWeek, "yyyy-MM-dd", "en");
    return last;
  }

  LastNextMonth(param) {
    var today = new Date();
    if (param == "lastMonth") {
      today.setMonth(today.getMonth() - 1);
    } else {
      today.setMonth(today.getMonth() + 1);
    }
    var lastWeek = new Date(today);
    var last = formatDate(lastWeek, "yyyy-MM-dd", "en");

    return last;
  }

  changeDates() {
    if (
      this.date_programming_start &&
      this.date_programming_end &&
      this.event_type_select != ""
    ) {
      this.getSchedulesEvents();
    }
  }

  async onChangeFilters(
    filterStatus,
    valueStatus,
    filterAssigned = false,
    valueAssigned = "",
    filterClient = false,
    valueCliet = "",
    filterCategory = false,
    valueCategory = "",
    filterSubCategory = false,
    valueSubCategory = "",
    filterEquipment = false,
    valueEquipment = ""
  ) {
    var ordenes_filtradas: programmingObj[] = this.programmingList;
    console.log(
      "onChangeFilters",
      filterStatus,
      valueStatus,
      filterAssigned,
      valueAssigned,
      filterClient,
      valueCliet,
      filterCategory,
      valueCategory,
      filterSubCategory,
      valueSubCategory,
      filterEquipment,
      valueEquipment
    );
    let is_autorizer = false;
    let autorizer_status;
    if (filterStatus) {
      // ['Programada','Abierta', 'En revisión', 'Autorizada', 'Rechazada', 'Vencida', 'Cerrada', 'Archivada'];
      if (valueStatus == "Programada") {
        this.status_filter_all = "1";
      } else if (valueStatus == "Abierta") {
        this.status_filter_all = "2";
      } else if (valueStatus == "Cerrada") {
        this.status_filter_all = "3";
      } else if (valueStatus == "En revisión") {
        is_autorizer = true;
        this.status_filter_all = "3";
        autorizer_status = 1;
      } else if (valueStatus == "Autorizada") {
        is_autorizer = true;
        this.status_filter_all = "3";
        autorizer_status = 2;
      } else if (valueStatus == "Rechazada") {
        is_autorizer = true;
        this.status_filter_all = "2";
        autorizer_status = 3;
      } else if (valueStatus == "Vencida") {
        this.status_filter_all = "5";
      } else if (valueStatus == "Archivada") {
        this.status_filter_all = "4";
      } else {
        console.log(" onChangeFilters ELSEEEE");
        this.status_filter_all = "status_filter_all";
      }

      this.filtersSheduledEvents["status_filter"] = valueStatus;
      this.filtersSheduledEvents["status_filter_all"] = this.status_filter_all;
    }
    if (filterAssigned) {
      console.log("TYYYYPE :: ", filterAssigned);
      this.asignedTo_filter_all = valueAssigned;
      console.log("asignedTo_filter_all :: ", this.asignedTo_filter_all);

      this.filtersSheduledEvents["asignedTo_filter_all"] =
        this.asignedTo_filter_all;
    }
    if (filterClient) {
      this.client_filter_all = valueCliet;

      this.array_equipment = this.array_equipment.filter(
        (item) => item.customer_id == valueCliet
      );
      this.filtersSheduledEvents["client_filter_all"] = this.client_filter_all;
    }

    if (filterEquipment) {
      this.filtersSheduledEvents["equipment_filter_all"] = valueEquipment;
      this.equipment_filter_all = valueEquipment;
    }

    if (filterCategory) {
      this.classification_filter_all = valueCategory;

      this.filtersSheduledEvents["classification_filter_all"] =
        this.classification_filter_all;
      this.filtersSheduledEvents["category_active"] =
        this.filter_classification_sub;
    }

    if (filterSubCategory) {
      this.subclassification_filter_all = valueSubCategory;
      this.filtersSheduledEvents["subclassification_filter_all"] =
        this.subclassification_filter_all;
    }

    if (this.status_filter_all != "status_filter_all") {
      if (is_autorizer) {
        ordenes_filtradas = await ordenes_filtradas.filter(
          (orden) =>
            orden.status == this.status_filter_all &&
            orden.authorization_status == autorizer_status
        );
      } else {
        ordenes_filtradas = await ordenes_filtradas.filter(
          (orden) => orden.status == this.status_filter_all
        );
      }
      await this.getArrayAsigned(ordenes_filtradas);
    }

    if (this.asignedTo_filter_all != "asignedTo_filter_all") {
      ordenes_filtradas = await ordenes_filtradas.filter(
        (orden) => orden.scheduled_user_name == this.asignedTo_filter_all
      );
    }

    if (this.client_filter_all != "client_filter_all") {
      ordenes_filtradas = await ordenes_filtradas.filter(
        (orden) => orden.scheduled_client_uuid == this.client_filter_all
      );
    }

    if (this.classification_filter_all != "classification_filter_all") {
      ordenes_filtradas = await ordenes_filtradas.filter(
        (orden) =>
          orden.scheduled_clasification_name == this.classification_filter_all
      );
    }

    if (this.subclassification_filter_all != "subclassification_filter_all") {
      ordenes_filtradas = await ordenes_filtradas.filter(
        (orden) =>
          orden.scheduled_subclasification_name ==
          this.subclassification_filter_all
      );
    }

    if (this.equipment_filter_all != "equipment_filter_all") {
      ordenes_filtradas = await ordenes_filtradas.filter(
        (orden) => orden.scheduled_equipment_uid == this.equipment_filter_all
      );
    }

    if (this.text_search != "") {
      var array_search: programmingObj[] = [];
      for (let item of ordenes_filtradas) {
        if (item.scheduled_name_event.includes(this.text_search)) {
          array_search.push(item);
        }
      }
      ordenes_filtradas = array_search;
    }

    this.programmingList$ = ordenes_filtradas;
    this.programmingList_original = this.programmingList$;

    if (ordenes_filtradas.length == 0) {
      //this.showModalNoResults();
    }

    this.storageService.setFiltersSheduledEvents(this.filtersSheduledEvents);
  }

  getArrayAsigned(ordenes_filtradas) {
    this.array_asignedTo = [];
    this.array_client = [];
    this.array_categories = [];
    this.array_subcategories = [];
    console.log("INICIA >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    for (let item_ of ordenes_filtradas) {
      if (this.array_asignedTo.length == 0) {
        this.array_asignedTo.push(item_.scheduled_user_name);
      } else {
        this.array_asignedTo.indexOf(item_.scheduled_user_name) == -1
          ? this.array_asignedTo.push(item_.scheduled_user_name)
          : "";
      }

      if (this.array_client.length == 0) {
        this.array_client.push({
          id: item_.scheduled_client_uuid,
          name: item_.scheduled_client_name,
        });
      } else {
        this.array_client.indexOf({
          id: item_.scheduled_client_uuid,
          name: item_.scheduled_client_name,
        }) == -1
          ? ""
          : this.array_client.push({
              id: item_.scheduled_client_uuid,
              name: item_.scheduled_client_name,
            });
      }

      console.log("INICIA", item_.scheduled_clasification);

      console.log("INICIA", item_.scheduled_subclasification);

      if (this.array_categories.length == 0) {
        if (
          item_.scheduled_clasification_name != undefined &&
          item_.scheduled_clasification_name != null &&
          item_.scheduled_clasification_name != ""
        )
          this.array_categories.push(item_.scheduled_clasification_name);
      } else {
        if (
          item_.scheduled_clasification_name != undefined &&
          item_.scheduled_clasification_name != null &&
          item_.scheduled_clasification_name != ""
        )
          this.array_categories.indexOf(item_.scheduled_clasification_name) ==
          -1
            ? this.array_categories.push(item_.scheduled_clasification_name)
            : "";
      }

      if (this.array_subcategories.length == 0) {
        if (
          item_.scheduled_subclasification_name != undefined &&
          item_.scheduled_subclasification_name != null &&
          item_.scheduled_subclasification_name != ""
        )
          this.array_subcategories.push(item_.scheduled_subclasification_name);
      } else {
        if (
          item_.scheduled_subclasification_name != undefined &&
          item_.scheduled_subclasification_name != null &&
          item_.scheduled_subclasification_name != ""
        )
          this.array_subcategories.indexOf(
            item_.scheduled_subclasification_name
          ) == -1
            ? this.array_subcategories.push(
                item_.scheduled_subclasification_name
              )
            : "";
      }
    }
    console.log("INICIA finaliza >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

    this.array_asignedTo.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    this.array_client.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    this.array_categories.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    this.array_subcategories.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  clearFilters(type, updateStorage = true) {
    var value;
    if (type == 1) {
      this.status_filter = "";
      this.status_filter_all = "status_filter_all";
      value = this.status_filter;
      if (updateStorage) {
        this.filtersSheduledEvents["status_filter"] = value;
        this.filtersSheduledEvents["status_filter_all"] =
          this.status_filter_all;
      }
    } else if (type == 2) {
      this.asignedTo_filter_all = "asignedTo_filter_all";
      value = this.asignedTo_filter_all;
      if (updateStorage) {
        this.filtersSheduledEvents["asignedTo_filter_all"] = value;
      }
    } else if (type == 3) {
      this.client_filter_all = "client_filter_all";
      value = this.client_filter_all;
      if (updateStorage) {
        this.filtersSheduledEvents["client_filter_all"] = value;
      }
      this.equipmentService.getEquipments({}).subscribe((equipment) => {
        this.array_equipment = equipment.data;
      });
    } else if (type == 4) {
      this.classification_filter_all = "classification_filter_all";
      value = this.classification_filter_all;
      if (updateStorage) {
        this.filtersSheduledEvents["classification_filter_all"] = value;
      }
    } else if (type == 5) {
      this.subclassification_filter_all = "subclassification_filter_all";
      value = this.subclassification_filter_all;
      if (updateStorage) {
        this.filtersSheduledEvents["subclassification_filter_all"] = value;
      }
    } else if (type == 8) {
      this.equipment_filter_all = "equipment_filter_all";
      value = this.equipment_filter_all;
      if (updateStorage) {
        this.filtersSheduledEvents["equipment_filter_all"] = value;
      }
    }

    console.log("updateStorage", updateStorage, type, value);
    if (updateStorage) {
      this.storageService.setFiltersSheduledEvents(this.filtersSheduledEvents);
      this.onChangeFilters(type, value);
    }
  }

  clearSearchFilter(updateStorage = true) {
    this.text_search = "";
    if (updateStorage) {
      this.onChangeFilters(4, "");
      this.filtersSheduledEvents["text_search"] = this.text_search;
      this.storageService.setFiltersSheduledEvents(this.filtersSheduledEvents);
    }
  }

  cleanFilters() {
    this.storageService.removeFiltersSheduledEvents();
    this.clearFilters(1, false);
    this.clearFilters(2, false);
    this.clearFilters(3, false);
    this.clearFilters(4, false);
    this.clearFilters(5, false);
    this.clearFilters(8, false);
    this.clearSearchFilter(false);
    this.time_event_select = "hoy";
    this.event_type_select = "";
    this.programmingList = [];
    this.type_event = "";
    this.filtersSheduledEvents = [];
    this.programmingList$ = [];
    this.programmingList_original = [];
    this.show_all_events = false;
    this.show_date_range = false;
  }
}
