import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from "@app/core/user.service";
import {AuthService} from "@app/core/auth.service";
import {StorageService} from "@app/core/storage.service";
import {Router} from "@angular/router";
import {UtilsService} from "@app/core/helper/utils.helper";
import {TranslateService} from "@ngx-translate/core";
import {SettingsAdminwebService} from "@app/core/settings-adminweb.service";
import { CustomerTableComponent } from "@app/customer-list/customer-table/customer-table.component";
import { PermissionService } from '../core/permission.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

  public current_component: any;

  get currentCustomer() {
    return this.storageService.getCurrentDB();
  }

  @ViewChild('current_page') current_page;

  constructor(
      public userService: UserService,
      public authService: AuthService,
      public storageService: StorageService,
      private router: Router,
      public utilsService: UtilsService,
      public translateService: TranslateService,
      public settingsService: SettingsAdminwebService,
      public permisionService: PermissionService

  ) { }

  ngOnInit() {
  }

  salir() {
    this.authService.doLogout().then(() => {
      if(this.storageService.getCurrentDB() != '') {
        const ruta = '/' + this.storageService.getCurrentDB() + '/login';
        this.storageService.logout();
        this.router.navigate([ruta]);
      }
    })
    .catch((err) => {
      // console.error('Error LogOut -> ' + err);
      if(this.storageService.getCurrentDB() != '') {
        const ruta = '/' + this.storageService.getCurrentDB() + '/login';
        this.storageService.logout();
        this.router.navigate([ruta]);
      }
    });
  }

  onActivate(componentRef) {
    this.current_component = null;
    if (componentRef instanceof CustomerTableComponent) {
      this.current_component = componentRef;
    }
  }

}
