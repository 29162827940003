import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FormsService } from "../../core/forms.service";
import * as cloneDeep from "lodash/cloneDeep";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../core/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalGenericService } from "../../services/modal-generic.service";
import { StorageService } from "../../core/storage.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { take } from "rxjs/operators";
import { formatDate } from "@angular/common";
import { ModalImageComponent } from "@app/user-list/modal-image/modal-image.component";
import * as Moment from "moment";
import { UserService } from "@app/core/user.service";
import {
  ButtonsConfig,
  ButtonsStrategy,
  Description,
  DescriptionStrategy,
  Image,
  KS_DEFAULT_BTN_CLOSE,
  PreviewConfig,
} from "@ks89/angular-modal-gallery";
import { SlideConfig } from "@ks89/angular-modal-gallery/lib/model/slide-config.interface";

@Component({
  selector: "app-equipment-template-form-add",
  templateUrl: "./equipment-template-form-add.component.html",
  styleUrls: ["./equipment-template-form-add.component.scss"],
})
export class EquipmentTemplateFormAddComponent implements OnInit {
  public current_component: any;

  get currentTemplate() {
    return this.storageService.getCurrentDB();
  }

  buttonsConfigCustom: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.CUSTOM,
    buttons: [KS_DEFAULT_BTN_CLOSE],
  };

  customDescription: Description = {
    strategy: DescriptionStrategy.ALWAYS_HIDDEN,
    imageText: "",
    numberSeparator: "",
    beforeTextDescription: "",
  };

  previewConfigCustomSize: PreviewConfig = {
    number: 5,
    visible: false,
    size: { width: "90px", height: "90px" },
  };

  height: number; //Para calcular el tamaño del item
  public paramResponse: string;
  public new_name = "";
  public id_doc = "";

  formularioNombre: FormControl;
  form = new FormGroup({
    questions: new FormArray([], [Validators.required]),
  });

  formulario: any = {
    displayName: "",
    date_created: "",
    questions: [],
  };

  uid: string = "";
  validate_definition = true;
  type: string = "input";
  validate_url = true;
  validate_json = true;
  confirmed_array_elements = [];
  validation = false;
  public photos_: any[] = [];
  previewUrl: any = "";

  get isEdit(): Boolean {
    return this.uid !== "";
  }

  get questions() {
    return this.form.get("questions") as FormArray;
  }

  get orderQuestions() {
    return (this.form.get("questions") as FormArray).controls.sort((a, b) => {
      if (a.get("order").value < b.get("order").value) {
        return -1;
      }
      if (a.get("order").value > b.get("order").value) {
        return 1;
      }
      return 0;
    });
  }

  get titleHasError(): boolean {
    return (
      this.form.get("title").invalid &&
      (this.form.get("title").dirty || this.form.get("title").touched)
    );
  }

  get questionsHasError(): boolean {
    return this.questions.invalid;
  }
  bodies: any[] = [];
  event_id = "";
  thumbnailToShow: any[] = [];
  @ViewChild("mainContent")
  private mainContentDiv!: ElementRef<HTMLElement>;
  @ViewChild("customList")
  private customList!: ElementRef<HTMLElement>;
  @ViewChildren("question") questionss: QueryList<ElementRef>;

  constructor(
    public authService: AuthService,
    private dialogRef: MatDialogRef<EquipmentTemplateFormAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private modalGeneric: ModalGenericService,
    public storageService: StorageService,
    private router: Router,
    public formsService: FormsService,
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public modelGeneric: ModalGenericService,
    public userService: UserService,
    private cd: ChangeDetectorRef
  ) {
    if (this.data.type !== "") {
      console.log("data passed in is:", this.data.type);
      if (this.data.type != "") {
        this.type = this.data.type;
      }
      this.uid = this.data.id;
      this.event_id = this.data.event_id;
      console.log("data passed event_id:", this.event_id);
      if (this.event_id == "") {
        if (this.data.confirmed_array_elements) {
          this.additemsbodies(this.data.confirmed_array_elements);
        }
      }
    }

    if (authService.customerDB != null) {
      formsService.customerDB = authService.customerDB;
      formsService.customerSelected = authService.customerSelected;
    }
  }

  ngOnInit() {
    // this.sub = this.route.params.subscribe(params => {
    // this.uid = this.uid;
    if (this.uid !== "") {
      this.spinner.show();
      this.formsService.getForm({ id: this.uid }).subscribe((response) => {
        let selected_form = response.data;
        if (selected_form) {
          this.formulario.displayName = selected_form.questionnaire_name;
          this.new_name = selected_form.questionnaire_name;

          this.formulario.date_created = selected_form.date_created;
          (selected_form.questions || []).forEach((q, qi) => {
            let answers = q.dropdown_answers.map((a) => {
              return new FormControl(a);
            });
            if (q.type === "api_questions") {
              this.type = "api_questions";
              this.data.type = "api_questions";
            } else {
              this.data.type = "";
            }

            let textValidators = [Validators.required];

            if (q.type == "instruction") {
              this.photos_[qi] = q.dropdown_answers;
              textValidators = null;
            }
            this.questions.push(
              new FormGroup({
                dropdown_answers: new FormArray(answers),
                input_type: new FormControl(q.input_type),
                text: new FormControl(q.text, textValidators),
                type: new FormControl(q.type),
                required: new FormControl(q.required ? q.required : false),
                show_message: new FormControl(
                  q.show_message ? q.show_message : false
                ),
                message: new FormControl(
                  q.message ? q.message : "Tu respuesta es requerida.",
                  q.type == "instruction" ? [Validators.required] : []
                ),
                order: new FormControl(qi + 1),
              })
            );
          });

          this.form.get("title").patchValue(selected_form.questionnaire_name);
          this.spinner.hide();
        } else {
          this.cancelSave();
          this.spinner.hide();
        }
      });
    } else {
      let orderArray = this.questions.controls.map((c) => c.get("order").value);
      let text = this.type != "input" ? "SN" : "";
      this.questions.push(
        new FormGroup({
          dropdown_answers: new FormArray([]),
          input_type: new FormControl("text"),
          text: new FormControl(text, [Validators.required]),
          required: new FormControl(true),
          show_message: new FormControl(false),
          message: new FormControl("Tu respuesta es requerida."),
          type: new FormControl(this.type),
          order: new FormControl(
            (isFinite(Math.max.apply(null, orderArray))
              ? Math.max.apply(null, orderArray)
              : 0) + 1
          ),
        })
      );
    }
    if (this.event_id !== "") {
      //Call this.additemsbodies(response.data.rows);
    }
    // });
  }
  private additemsbodies(data) {
    data.forEach((e) => {
      if (e.questionary_id != "") {
        if (e.questionary_id != this.uid || this.uid == "") {
          this.bodies.push(e);
        }
      }
    });
  }
  // ngOnDestroy() {
  //   this.sub.unsubscribe();
  // }

  cancelSave() {
    const ruta = "/" + this.storageService.getCurrentDB() + "/equipments";
    this.router.navigate([ruta]);
  }

  addQuestion() {
    let orderArray = this.questions.controls.map((c) => c.get("order").value);
    this.questions.push(
      new FormGroup({
        dropdown_answers: new FormArray([]),
        input_type: new FormControl("text"),
        text: new FormControl("", [Validators.required]),
        required: new FormControl(true),
        show_message: new FormControl(false),
        message: new FormControl("Tu respuesta es requerida."),
        type: new FormControl("input"),
        order: new FormControl(
          (isFinite(Math.max.apply(null, orderArray))
            ? Math.max.apply(null, orderArray)
            : 0) + 1
        ),
      })
    );

    console.log(this.questions.controls);
    this.cd.detectChanges();
    this.questionss.last.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }

  cloneQuestion(field, index) {
    let searchIndex = this.questions.controls.findIndex(
      (f) => f.get("order").value == field.get("order").value
    );
    let indexnew = index + 1;
    let copytmp = this.questions.controls[searchIndex];
    if (copytmp.value["type"] == "instruction") {
      this.photos_[indexnew] = this.photos_[index];
    }
    let copy = cloneDeep(copytmp); //se realiza una copia ya que si metemos el orginal se modifican los 2
    this.questions.push(copy);
    this.cd.detectChanges();
    this.questionss.last.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }

  removeQuestion(field, index) {
    let removeIndex = this.questions.controls.findIndex(
      (f) => f.get("order").value == field.get("order").value
    );
    this.questions.removeAt(removeIndex);
  }

  moveUpQuestion(field, index) {
    let lastOrder = field.get("order").value;
    const nextControl = this.questions.controls.find(
      (f) => f.get("order").value === lastOrder - 1
    );
    if (nextControl) {
      field.get("order").value = nextControl.get("order").value;
      nextControl.get("order").patchValue(lastOrder);
    }
  }

  moveDownQuestion(field, index) {
    let lastOrder = field.get("order").value;
    const nextControl = this.questions.controls.find(
      (f) => f.get("order").value === lastOrder + 1
    );
    if (nextControl) {
      field.get("order").value = nextControl.get("order").value;
      nextControl.get("order").patchValue(lastOrder);
    }
  }

  isTextInput(field) {
    return field.get("type").value == "input";
  }

  isNumericInput(field) {
    return field.get("type").value == "numeric";
  }

  isDropDown(field) {
    return field.get("type").value == "dropdown";
  }

  isPhotoInput(field) {
    return field.get("type").value == "photo";
  }

  removeAnswer(field, index) {
    (field.get("dropdown_answers") as FormArray).removeAt(index);
  }

  addAnswer(field, index) {
    (field.get("dropdown_answers") as FormArray).push(new FormControl(""));
  }

  saveForm() {
    this.validation = true;

    if (this.validForm()) {
      console.log("GG");
      let form_json = this.form.getRawValue();
      let api_id = 0;
      let data: any = {
        questionnaire_name: form_json.title,
        date_created: formatDate(new Date(), "dd/MM/yyyy", "en"),
        questions: form_json.questions.map((q, index) => {
          if (!q.required) {
            q.required = false;
          }
          if (q.type === "api_dropdown") {
            api_id += 1;
          }
          return {
            dropdown_answers: q.dropdown_answers,
            input_type: q.input_type,
            text: q.text,
            required: true,
            show_message: q.show_message,
            message: q.message,
            type: q.type,
          };
        }),
      };

      if (this.uid) {
        data.id = this.uid;
      }

      this.dialogRef.close({ type: "add", items: data });
    }
  }

  cleanForm() {
    while (this.questions.length) {
      this.questions.removeAt(0);
    }

    this.form.patchValue({
      title: "",
    });
  }

  cancelForm() {
    this.dialogRef.close({ type: "cancel", items: [] });
  }

  hasErrorEmptyAnswer(field, index): any {
    console.log(index);
    console.log(field);

    if (field.value !== "") {
      return false;
    }

    return true;
  }

  hasErrorNumericValid(field, min, max): any {
    if (field.value >= min && field.value <= max && field.value !== "") {
      return false;
    }

    return true;
  }

  hasErrorQuestion(field, index): any {
    if (
      field.get("text").invalid &&
      (field.get("text").dirty || field.get("text").touched)
    ) {
      return { required: true };
    }

    return false;
  }

  getFilledAnswers(field): any[] {
    return (field.get("dropdown_answers") as FormArray).controls.filter(
      (f) => f.value !== ""
    );
  }

  validForm() {
    this.cd.detectChanges();
    let filledAnswers = true;
    this.questions.controls.forEach((q) => {
      if (this.isDropDown(q) && this.getFilledAnswers(q).length < 2)
        filledAnswers = false;
    });
    return this.form.valid && filledAnswers;
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("Reconociendo el drop");
    if (
      this.questions.controls.some(
        (item) => item.get("type").value === "instruction"
      )
    ) {
      moveItemInArray(this.photos_, event.previousIndex, event.currentIndex);
    }
    moveItemInArray(
      (this.form.get("questions") as FormArray).controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  /**
   *Altura dinamica del cada tarea
   * @param event
   */
  cdkDragStarted(event: any) {
    this.height = 50; //event.source.element.nativeElement.offsetHeight / 3.5;
  }

  onChange(deviceValue, field, index) {
    var count = (field.get("dropdown_answers") as FormArray).length;
    console.log("COUNT " + count);
    console.log("deviceValue " + deviceValue);
    if (deviceValue == "dropdown" || deviceValue == "numeric") {
      if (count < 2) {
        (field.get("dropdown_answers") as FormArray).push(new FormControl(""));
        (field.get("dropdown_answers") as FormArray).push(new FormControl(""));
      }
    }

    field.get("message").setValidators(null);
    field.get("text").setValidators([Validators.required]);
    field.get("required").setValue(true);

    field.updateValueAndValidity();
    this.validation = false;
  }
}
