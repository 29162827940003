import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth.service';
import * as jspdf from 'jspdf';
import { Subscription } from 'rxjs';
declare let html2canvas: any;

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {

  capturedImage;
  errorMessage: string = '';
  clientCodeForm: FormGroup;
  validation_message
  form$: Subscription

  constructor(public authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    if (this.form$) this.form$.unsubscribe()
  }

  createForm() {
    this.clientCodeForm = new FormGroup({
      clientCode: new FormControl('', Validators.required)
    }, { updateOn: 'change' });
    this.setValidationMsg()
    this.watchForm()
  }

  setValidationMsg() {
    this.validation_message = {
      clientCode: [
        { type: 'required', message: 'Por favor, ingresa un código de cliente.' },
      ]
    }
  }

  watchForm() {
    this.clientCodeForm.valueChanges.subscribe(values => {
      this.errorMessage = ''
    })
  }

  validateClientCode(value) {
    this.clientCodeForm.controls.clientCode.markAsTouched()
    this.clientCodeForm.controls.clientCode.markAsDirty()
    this.errorMessage = ''
    if (this.clientCodeForm.invalid) {
      return
    }
    console.log("entra al código del cliente");
    //this.userService.customerDB = this.authService.customerDB;
    //this.userService.customer_route = this.authService.customer_route;
    this.authService.getValidateExistCustomerMaster(value).subscribe((response) => {
      if(response.statusCode == 200){
        var ruta = '/' + value.clientCode + '/login';
        this.router.navigate([ruta]);
      }else{
        this.errorMessage = 'Por favor, ingresa el código del cliente correctamente.';
      }
    })
  }
  /*
  clickme() {
    html2canvas(document.querySelector("#capture")).then(canvas => {

       debugger;

      /// document.body.appendChild(canvas);
      this.capturedImage = canvas.toDataURL();
      console.log("canvas.toDataURL() -->" + this.capturedImage);
      // this will contain something like (note the ellipses for brevity), console.log cuts it off 
      // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAa0AAAB3CAYAAACwhB/KAAAXr0lEQVR4Xu2dCdiNZf7HP/ZQkpQtaUxDjYYoTSYlURMhGlmKa..."

      
      canvas.toBlob(function (blob) {
        
        //  just pass blob to something expecting a blob
        // somfunc(blob);

        // Same as canvas.toDataURL(), just longer way to do it.
        var reader = new FileReader();
        debugger;
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          console.log("Base64--> " + base64data);

          let pdf = new jspdf('p', 'cm', 'a4'); //Generates PDF in portrait mode
          pdf.addImage(base64data, 'PNG', 0, 0, 29.0, 29.0);  
          pdf.save('Filename.pdf');   
        }

      });


    });
  }
  */

  
  makeMultiPage() {

    var quotes = document.getElementById('content');
    html2canvas(quotes).then(canvas => {
      //! MAKE YOUR PDF
      var pdf = new jspdf('p', 'pt', 'letter');

      for (var i = 0; i <= quotes.clientHeight/980; i++) {
          //! This is all just html2canvas stuff
          var srcImg  = canvas;
          var sX      = 0;
          var sY      = 980*i; // start 980 pixels down for every new page
          var sWidth  = 900;
          var sHeight = 980;
          var dX      = 0;
          var dY      = 0;
          var dWidth  = 900;
          var dHeight = 980;

          let onePageCanvas = document.createElement("canvas");
          onePageCanvas.setAttribute('width', '900');
          onePageCanvas.setAttribute('height', '980');
          var ctx = onePageCanvas.getContext('2d');
          // details on this usage of this function: 
          // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
          ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);

          // document.body.appendChild(canvas);
          var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

          var width         = onePageCanvas.width;
          var height        = onePageCanvas.clientHeight;

          //! If we're on anything other than the first page,
          // add another page
          if (i > 0) {
              pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
          }
          //! now we declare that we're working on that page
          pdf.setPage(i+1);
          //! now we add content to that page!
          pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width*.62), (height*.62));

      }
      //! after the for loop is finished running, we save the pdf.
      pdf.save('Test.pdf');
    });

  }

}
