import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import * as Moment from 'moment';

@Pipe({ name: 'dateFormat' })
@Injectable()
export class DateFormatPipe implements PipeTransform {
  transform(date: any, format: string): any {
    if (date) {
      return Moment(date).format(format);
    }
    return '--';
  }

}
