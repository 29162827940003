import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ENTER, COMMA, I } from '@angular/cdk/keycodes';
import { Observable, Subject } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { UtilsService } from '@app/core/helper/utils.helper';
import { UserService } from '@app/core/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-transfer-activity',
  templateUrl: './transfer-activity.component.html',
  styleUrls: ['./transfer-activity.component.scss']
})
export class TransferActivityComponent implements OnInit {
  order: any;
  userCtrl = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  errorEmpty: boolean = false;
  filteredUsers: Observable<string[]>;
  public user_list: any = [];
  usuario_select_name: string = '';
  usuario_select_uid: string = '';
  errorEqual: boolean = false;
  form: FormGroup;


  formulario = {
    scheduled_assigned_to: null,
  };

  get getUserList(){
    return(
      this.user_list &&
      this.user_list.filter((f) => {
        return this.utilsService.search(f, 'displayName', "");
        }).filter((f) => {
          return f.disabled !== true;
        }).sort(function (a, b) {
        if (a.displayName.toUpperCase()  > b.displayName.toUpperCase() ) {
          return 1;
        }
        if (a.displayName.toUpperCase()   < b.displayName.toUpperCase()  ) {
          return -1;
        }
        return 0;
      }).map((u) =>  Object({ id: u.uid, text: u.displayName  })
      ).filter(
        (value, index, self) =>
          self.findIndex((f) => f.text == value.text) === index
      )
    );
  }


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TransferActivityComponent>,
    public utilsService: UtilsService,
    public userService: UserService,
    private spinner: NgxSpinnerService,
  ) {
    if (this.data != '') {
      this.order = this.data.order;
    }

    console.log('ORDERN RECIVED ', this.order );


    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(''),
      map((user: string | null) => (user ? this._filter(user) : this.getUserList.slice())),
    );

   }

  ngOnInit() {
    this.setForm();
    this.userService.getUsersList().subscribe((response) => {
      console.log('users', response);
      this.user_list = response.data.map((r) => {
        return { ...r, ...{ selected: false } };
      });
    });
  }
  setForm() {
    this.form = new FormGroup({
      scheduled_assigned_to: new FormControl(
        this.formulario.scheduled_assigned_to,
        Validators.required
      ),    
    });
  }

  transfer(){

    this.errorEqual = false;
    this.errorEmpty = false;

    if(!this.form.controls.scheduled_assigned_to.value || this.form.controls.scheduled_assigned_to.value == null){
      this.errorEmpty = true;
    }else{

      this.form.controls.scheduled_assigned_to.markAsTouched();
      let user_selected = this.user_list.find(element => element.uid == this.form.controls.scheduled_assigned_to.value[0].id);

      if(user_selected.uid == this.order.user_uid){
        this.errorEqual = true;
      }else{
        
        this.spinner.show();

        let body = {
          firebase_id: this.order.id,
          usuario_reassigned_name: user_selected.displayName,
          usuario_reassigned_uid: user_selected.uid
        }
  
        this.userService.reasignedUser(body).subscribe((response) => {
          this.spinner.hide();
          if(response.statusCode == 200){
            this.dialogRef.close('OK');
          }else{
            this.dialogRef.close('err');
          }
  
        });

      }

    }
  }

  cancelTransfer(){
    this.dialogRef.close('close');
  }


  selected(event: MatAutocompleteSelectedEvent): void {
    //alert(event.option.value.uid);
    this.errorEqual = false;
    this.errorEmpty = false;
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);

    console.log('USUARIO SELEC ', event.option.value );

    this.usuario_select_name = event.option.value.displayName;
    this.usuario_select_uid = event.option.value.uid;

    if(event.option.value.uid == this.order.user_uid){
      this.errorEqual = true;
    }

  }

  private _filter(value: string): any[] {
    const filterValue = value['displayName'] ?  value['displayName'] .toLowerCase() : value.toLowerCase();
    return this.getUserList.filter((user) => new RegExp(value, 'gi').test(user['displayName']));
  }

}
