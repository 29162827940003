import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
} from "@angular/material";
import { AuthService } from "@app/core/auth.service";
import { StorageService } from "@app/core/storage.service";
import * as moment from "moment";
import { DialogData } from "../add-item-drag/add-item-drag.component";

@Component({
  selector: "app-download-xlsx-new",
  templateUrl: "./download-xlsx-new.component.html",
  styleUrls: ["./download-xlsx-new.component.scss"],
})
export class DownloadXlsxNewComponent implements OnInit {
  report: string = "fm";
  name_event: string;
  date_ini: string = "";
  hour_ini: string = "00:00";
  date_end: string = "";
  hour_end: string = "23:59";
  showError = false;
  min: string;
  max: string;

  minHour: string;
  maxHour: string;

  typeReport = "fm";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public storageService: StorageService,
    public authService: AuthService
  ) {}

  ngOnInit() {}

  closeDialog() {
    this.dialog.closeAll();
  }

  changeInit() {
    this.date_end = this.date_ini;
    this.min = moment(this.date_ini).format("YYYY-MM-DD");
    this.max = moment(this.min).add(2, "M").format("YYYY-MM-DD");
    this.showError = false;
  }

  changeHour() {
    this.hour_ini = moment(this.date_ini).format("HH:mm");
  }

  donwloadXlsx() {
    if (this.date_ini == "" || this.date_end == "") {
      this.showError = true;
    } else {
      this.showError = false;

      let ini = moment(this.date_ini).format("YYYY-MM-DD");
      let end = moment(this.date_end).format("YYYY-MM-DD");

      let startHour = `${this.hour_ini}:00`;
      let endHour = `${this.hour_end}:59`;

      if (this.storageService.getCurrentDB() != "") {
        if (this.report == "fm") {
          const urlActividades =
            this.authService.customerSelected.reportExcelBigQuery +
            "&start_date=" +
            ini +
            "&start_hour=" +
            startHour +
            "&end_date=" +
            end +
            "&end_hour=" +
            endHour;
          window.open(urlActividades, "blank");
        } else {
          const urlActividades =
            this.authService.customerSelected.reportDelays +
            "&start_date=" +
            ini +
            "&start_hour=" +
            startHour +
            "&end_date=" +
            end +
            "&end_hour=" +
            endHour;
          console.log(urlActividades);
          window.open(urlActividades, "blank");
        }
      }
    }
  }
}
