import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
//import * as geofirex from 'geofirex';
import { formatDate } from "@angular/common";
import { Observable, BehaviorSubject, from } from "rxjs";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { AgmMap } from "@agm/core";
//import { GeoFireQuery } from 'geofirex';
import { ItemEvent, Photos, questions } from "../../models/evidence";
import { UserService } from "../../core/user.service";
import { ModulosService } from "../../core/modulos.service";
import { AuthService } from "../../core/auth.service";
import { StorageService } from "../../core/storage.service";
import { UtilsService } from "../../core/helper/utils.helper";
import { AngularFireStorage } from "@angular/fire/storage";
import * as firebase from "firebase/app";

import { ExcelService } from "../../services/excel.service";
import { MenuComponent } from "../../components/menu/menu.component";

import * as jspdf from "jspdf";
import domToPdf from "dom-to-pdf";

declare let html2canvas: any;
//var domToPdf = require('dom

import { ScriptService } from "../../script.service";
import * as pdfMake from "pdfmake";
//import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface config_firebase {
  apiKey: string;
  appId: string;
  authDomain: string;
  databaseURL?: string;
  measurementId: string;
  messagingSenderId: string;
  projectId: string;
}

interface client {
  name?: string;
  logo?: string;
  doc: string;
}

interface customer_object {
  config_firebase: config_firebase;
  customer_key: string;
  url_alias: string;
  domain: string;
  logo_url: string;
  name: string;
  path_login: string;
  rules_login: string;
  url_pdf: string;
}

interface settings {
  cloneOrders: boolean;
  createItems: boolean;
  createOrders: boolean;
  deleteItems: boolean;
  deleteOrders: boolean;
  descriptionEvidenceRequired: boolean;
  elastic_authorization: string;
  elastic_endpoint_search: string;
  icon_comentarios: string;
  icon_photo: string;
  icon_signature: string;
  label_name: string;
  relationOneToMany: boolean;
  url_num_orders: string;
  url_pdf_web: string;
  url_report: string;
  logo_client: string;
}

@Component({
  selector: "app-customer-edit",
  templateUrl: "./edit-customer.component.html",
  styleUrls: ["./edit-customer.component.scss"],
})
export class EditCustomerComponent implements OnInit {
  @ViewChild("menuHeader") menuHeader: MenuComponent;
  uid: string = "";
  mode: string = "edit";
  readOnly: boolean = true;

  user_email = "-----------------------------";
  url_logo_client = "";
  public clientes: client[] = [];
  editCustomerForm: FormGroup;
  errorMessage: string = "";
  public users_by_page: number = 10;
  public user_current_page: number = 1;
  public label_button = "Add Customer";
  selectedImage: any = null;
  imgSrc: string = "/assets/images/image_placeholder.jpg";
  extencion_image: string = "";
  is_image: boolean = false;
  new_customer_object: customer_object = {
    config_firebase: {
      apiKey: "",
      appId: "",
      authDomain: "",
      databaseURL: "",
      measurementId: "",
      messagingSenderId: "",
      projectId: "",
    },
    customer_key: "",
    url_alias: "",
    domain: "",
    logo_url: "",
    name: "",
    path_login: "",
    rules_login: "",
    url_pdf: "",
  };

  settings: settings = {
    cloneOrders: true,
    createItems: true,
    createOrders: true,
    deleteItems: true,
    deleteOrders: true,
    descriptionEvidenceRequired: false,
    elastic_authorization: "",
    elastic_endpoint_search: "",
    icon_comentarios:
      "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Ficons_default_icons_default_drive.png?alt=media&token=94993b79-18a2-44b9-87d1-d344c7428d8e",
    icon_photo:
      "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Ficons_default_icons_default_insert_photo.png?alt=media&token=da377040-5c97-4d99-b7a0-cf3cb66cc88a",
    icon_signature:
      "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Ficons_default_icons_default_firma.png?alt=media&token=3b8987ce-129f-4f21-b325-3e7a2207cce4",
    label_name: "Actividad",
    relationOneToMany: true,
    logo_client: "",
    url_num_orders: "",
    url_pdf_web: "",
    url_report: "",
  };
  public sdk = "";
  public name = "";
  public cutomerkey = "";
  public urlAlias = "";
  public settingsid = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserService,
    public authService: AuthService,
    public storageService: StorageService,
    public utilsService: UtilsService,
    public modulosService: ModulosService,
    private fb: FormBuilder,
    private storage: AngularFireStorage
  ) {
    if (authService.customerDB != null) {
      userService.customerDB = authService.customerDB;
      modulosService.customerDB = authService.customerDB;
      userService.customerSelected = authService.customerSelected;
      userService.getCurrentUser().then((user) => {
        this.user_email = user.email;
        this.url_logo_client = "";
      });
    }
    this.createForm();
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.uid = params["id"] || "";
      this.mode = params["mode"];
      if (this.mode == "edit") {
        this.readOnly = false;
      }
      this.userService
        .getTenancyParams()
        .collection("customers")
        .doc(this.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Document data:", doc.data());
            var data = JSON.stringify(doc.data());
            this.sdk = JSON.stringify(doc.data()["config_firebase"]);
            this.name = doc.data()["name"];
            this.cutomerkey = doc.data()["customer_key"];
            this.urlAlias = doc.data()["url_alias"];
            this.imgSrc = doc.data()["logo_url"];
            this.new_customer_object = JSON.parse(data);
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        });
    });
  }

  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.extencion_image = event.target.files[0]["type"];
      var strArr = this.extencion_image.split("/");
      if (strArr[0] == "image") {
        this.extencion_image = strArr[1];
        console.log(this.extencion_image);
        const reader = new FileReader();
        reader.onload = (e: any) => (this.imgSrc = e.target.result);
        reader.readAsDataURL(event.target.files[0]);
        this.selectedImage = event.target.files[0];
        console.log(event.target.files[0]);
        console.log(event);
        this.errorMessage = "";
        this.is_image = true;
      } else {
        this.imgSrc = "/assets/images/image_placeholder.jpg";
        this.selectedImage = null;
        this.errorMessage = "Wrong format image";
        this.is_image = false;
      }
    } else {
      this.imgSrc = "/assets/images/image_placeholder.jpg";
      this.selectedImage = null;
      this.is_image = false;
    }
  }

  salir() {
    this.authService
      .doLogout()
      .then(() => {
        if (this.storageService.getCurrentDB() != "") {
          const ruta =
            "/sintra/" + this.storageService.getCurrentDB() + "/login";
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      })
      .catch((err) => {
        // console.error('Error LogOut -> ' + err);
        if (this.storageService.getCurrentDB() != "") {
          const ruta =
            "/sintra/" + this.storageService.getCurrentDB() + "/login";
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      });
  }

  public editClient(docid) {
    console.log(docid);
    var ruta =
      "/sintra/" +
      this.authService.customer_route +
      "/edit-customer/" +
      docid +
      "/edit";
    this.router.navigate([ruta]);
  }

  get currentCustomer() {
    return this.storageService.getCurrentDB();
  }
  createForm() {
    this.editCustomerForm = this.fb.group({
      configjson: ["", Validators.required],
      name: ["", Validators.required],
      customerKey: ["", Validators.required],
      urlAlias: ["", Validators.required],
      file: ["", Validators.required],
    });
  }

  get nameHasError(): boolean {
    return (
      this.editCustomerForm.get("name").invalid &&
      (this.editCustomerForm.get("name").dirty ||
        this.editCustomerForm.get("name").touched)
    );
  }

  get CustomerkeyHasError(): boolean {
    return (
      this.editCustomerForm.get("customerKey").invalid &&
      (this.editCustomerForm.get("customerKey").dirty ||
        this.editCustomerForm.get("customerKey").touched)
    );
  }

  get UrlAliasHasError(): boolean {
    return (
      this.editCustomerForm.get("urlAlias").invalid &&
      (this.editCustomerForm.get("urlAlias").dirty ||
        this.editCustomerForm.get("urlAlias").touched)
    );
  }

  get configjsonHasError(): boolean {
    return (
      this.editCustomerForm.get("configjson").invalid &&
      (this.editCustomerForm.get("configjson").dirty ||
        this.editCustomerForm.get("customerKey").touched)
    );
  }

  get fileHasError(): boolean {
    return (
      this.editCustomerForm.get("file").invalid &&
      (this.editCustomerForm.get("file").dirty ||
        this.editCustomerForm.get("file").touched)
    );
  }

  validForm() {
    return this.editCustomerForm.valid && this.is_image;
  }

  trySend(value) {
    if (this.selectedImage != null) {
      var filepath = "/customers/" + value.name + "/logotipo.jpg";
      var fileRef = this.storage.ref(filepath);

      this.storage
        .upload(filepath, this.selectedImage)
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((url) => {
              console.log(url);
              this.new_customer_object.logo_url = "" + url + "";
              this.new_customer_object.name =
                value.name != "" ? value.name : this.new_customer_object.name;
              this.new_customer_object.customer_key =
                value.customerKey != ""
                  ? value.customerKey
                  : this.new_customer_object.customer_key;
              this.new_customer_object.url_alias =
                value.urlAlias != ""
                  ? value.urlAlias
                  : this.new_customer_object.url_alias;

              this.userService.customerDB.app
                .firestore()
                .collection("customers")
                .doc(this.uid)
                .set(this.new_customer_object)
                .then((snap) => {
                  var customerApp_0 = firebase.initializeApp(
                    this.new_customer_object.config_firebase,
                    this.new_customer_object.customer_key
                  );
                  customerApp_0
                    .firestore()
                    .collection("settings")
                    .get()
                    .then((snap) => {
                      if (snap.size > 0) {
                        var datos = JSON.stringify(snap.docs[0].data());
                        this.settings = JSON.parse(datos);
                        this.settings.logo_client =
                          this.new_customer_object.logo_url;
                        this.settingsid = snap.docs[0].id;
                        customerApp_0
                          .firestore()
                          .collection("settings")
                          .doc(this.settingsid)
                          .set(this.settings)
                          .then((snap) => {
                            const ruta =
                              "/sintra/" +
                              this.authService.customer_route +
                              "/dashboard-admin";
                            this.router.navigate([ruta]);
                          })
                          .catch((error) => {
                            this.errorMessage =
                              "error al generar coleccion principal" + error;
                            return false;
                          });
                      }
                    })
                    .finally(() => {
                      console.log("finalize");
                    })
                    .catch((error) => {
                      console.log("error");
                      console.log(error);
                    });
                })
                .catch((error) => {
                  this.errorMessage =
                    "error al generar coleccion principal" + error;
                  return false;
                });
            });
          })
        )
        .subscribe();
    } else {
      this.new_customer_object.name =
        value.name != "" ? value.name : this.new_customer_object.name;
      this.new_customer_object.customer_key =
        value.customerKey != ""
          ? value.customerKey
          : this.new_customer_object.customer_key;
      this.new_customer_object.url_alias =
        value.urlAlias != ""
          ? value.urlAlias
          : this.new_customer_object.url_alias;

      this.userService.customerDB.app
        .firestore()
        .collection("customers")
        .doc(this.uid)
        .set(this.new_customer_object)
        .then((snap) => {
          const ruta =
            "/sintra/" + this.authService.customer_route + "/dashboard-admin";
          this.router.navigate([ruta]);
        })
        .catch((error) => {
          this.errorMessage = "error al generar coleccion principal" + error;
          return false;
        });
    }
  }

  listCustomer() {
    const ruta =
      "/sintra/" + this.authService.customer_route + "/dashboard-admin";
    this.router.navigate([ruta]);
  }
}
