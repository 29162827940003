export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyC6ihCkaGEvaPE667shS3DZurgvSDZr6-g",
    authDomain: "sintra-master-tp.firebaseapp.com",
    databaseURL: "https://sintra-master-tp.firebaseio.com",
    projectId: "sintra-master-tp",
    storageBucket: "sintra-master-tp.appspot.com",
    messagingSenderId: "875114764779",
    appId: "1:875114764779:web:3015e4e8237e0d6c6ce1ae",
    measurementId: "G-KQX4E71GVS",
  },
  urlEndpointMaster: "https://sintra-master-tp.uc.r.appspot.com",
  //urlEndpointMaster: "http://localhost:4000",
  headerAuthorization: "Bearer 975dd9f6-4a89-4825-9a6d-deae71304a30",
  consoleActive: false,
};
