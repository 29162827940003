import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, from, throwError, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { Http } from '@angular/http';

import 'rxjs/add/operator/toPromise';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { Router } from '@angular/router';
import { ConfigCustomer } from '../models/customer';


@Injectable()
export class ModulosService {

  public customerDB: firebase.database.Database = null;
  public customerSelected: ConfigCustomer = null;

  customer_route = '';


  constructor (
    public http: HttpClient,
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private router: Router
  ) {
  }

  getModulos() {
    return new Promise((resolve, reject) => {
      let this_ = this;

      var current_doc = '';

      this.customerDB.app.firestore().collection("modulos").get()
      .then((querySnapshot) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

}
