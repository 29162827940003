import { Injectable } from '@angular/core';
import * as firebase from "firebase";

@Injectable({
  providedIn: 'root'
})
export class SettingsAdminwebService {

  public customerDB: firebase.database.Database = null;

  constructor() { }

  getSettingsAdminWeb(): Promise<any> {
      return this.customerDB.app.firestore().collection('settings_adminweb').get();
  }

  updateSettingsAdminWeb(idDoc: string, body: any): Promise<any> {
      return this.customerDB.app.firestore().collection('settings_adminweb').doc(idDoc).set(body, {merge: true});
  }
}
