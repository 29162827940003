import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../core/events.service';
import { AuthService } from '../../core/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../core/user.service';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ModalImageComponent } from '@app/user-list/modal-image/modal-image.component';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { UtilValidator } from '@app/validators/UtilValidator';

@Component({
  selector: 'app-configuration-list',
  templateUrl: './configuration-list.component.html',
  styleUrls: ['./configuration-list.component.scss'],
})
export class ConfigurationListComponent implements OnInit {
  formColorPdf: FormGroup = new FormGroup({
    color_pdf: new FormControl('', [Validators.required]),
  });

  previewUrl: any = '';
  public settings_client: any = [];
  fileData: File = null;
  base64;

  constructor(
    public eventsService: EventsService,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    public userService: UserService,
    private modalGeneric: ModalGenericService,
    public translateService: TranslateService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    userService.customerDB = authService.customerDB;
    userService.customerSelected = authService.customerSelected;
    userService.getCurrentUser().then((user) => {});

    this.spinner.show();
    userService.getSettingsClient().subscribe((response) => {
      console.log('RESPONSE DE GET SETTINGS ', response);
      console.log('RESPONSE DE GET SETTINGS ----- ', response.data[0]);
      this.settings_client = response.data[0];
      this.previewUrl = this.settings_client.logo_client;

      this.formColorPdf
        .get('color_pdf')
        .setValue(
          this.settings_client.color_pdf
            ? this.settings_client.color_pdf
            : '#52C491'
        );

      this.spinner.hide();
    });
  }

  ngOnInit() {}

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  getImage() {
    const dialogRef = this.dialog
      .open(ModalImageComponent, {
        data: {
          cutout_shape: 'rectangle',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp.button == 'aceptar') {
          this.previewUrl = resp.imageB64;
          this.base64 = resp.imageB64;
        }
      });

    // const input = document.getElementById('file');
    // input.click();
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.base64 = reader.result;
    };
  }

  async saveConfiguration() {
    this.spinner.show();
    var configuratio_client = {};
    if (this.base64) {
      console.log('SI IAMGE LOGO');
      const downloadUrl = await this.userService.uploadLogoClient(
        'logo_client',
        this.base64
      );
      console.log('URL IMAGEN DE PHOTO CLIENT ', downloadUrl);

      configuratio_client = {
        url: downloadUrl,
        color_pdf: this.formColorPdf.get('color_pdf').value,
      };
    } else {
      configuratio_client = {
        color_pdf: this.formColorPdf.get('color_pdf').value,
      };
    }
    console.log('COLOR: ', this.formColorPdf.get('color_pdf').value);
    this.userService
      .saveConfigurationClient(configuratio_client)
      .subscribe((response) => {
        this.spinner.hide();
        if (response.status === 'ok') {
          this.sendSuccess(
            this.translateService.instant('PAGES.CONFIGURATION_CLIENT.LOGOTIPO')
          );
        } else {
          this.sendModelError(
            this.translateService.instant('PAGES.CONFIGURATION_CLIENT.ERROR')
          );
        }
      });
  }

  sendSuccess(txt: string) {
    this.modalGeneric
      .onModelexito(
        false, //disableClose
        txt, //message
        false, // cancelButton
        this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
        true, // info
        false //imagen info
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.spinner.show();
        window.location.reload();
      });
  }

  sendModelError(msg: string) {
    this.modalGeneric.onModelerror(
      false, //disableClose
      msg, //message
      false, // cancelButton
      this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
      true, // info
      false //imagen info
    );
  }
}
