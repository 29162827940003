import {Component, Inject, Injectable} from  '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from  '@angular/material/dialog';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss']
})
export class ModalGenericComponent {
  is_normal: boolean = true;

  constructor(private  dialogRef:  MatDialogRef<ModalGenericComponent>, @Inject(MAT_DIALOG_DATA) public  data:  any) { }
  radiodelete: string = "one";
  ngOnInit() {
    console.log(this.data.subtitle_message);
    console.log('DATA DE MODAL ', this.data);
    if(this.data.subtitle_message){
      this.is_normal = false;
    }
  }

  public  closeMe() {
      this.dialogRef.close('cerrar');
  }

  public  onAceptar() {
      console.log(this.radiodelete);
      console.log(this.data.typeevent);
      if(this.data.typeevent)
        this.dialogRef.close({button: 'aceptar', radiodelete:this.radiodelete});
      else{
        this.dialogRef.close('aceptar');
      }
  }

  public  onCancelar() {
    if(this.data.typeevent)
        this.dialogRef.close({button: 'cancelar', radiodelete:this.radiodelete});
      else{
        this.dialogRef.close('cancelar');
      }
}

}
