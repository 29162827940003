import {
  Component,
  Inject,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ModalGenericService } from '../../../services/modal-generic.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from '@app/core/storage.service';
import { MatDialog } from '@angular/material';
import { ShareDialogComponent } from '@app/detail-order/components/share-dialog/share-dialog.component';
import { OrdersService } from '../../../orders.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgrammingAddComponent } from '../../programming-add/programming-add.component';
import { take } from 'rxjs/operators';
import { AuthService } from '@app/core/auth.service';
import { ProgramingService } from '../../../services/programing.service';
import { PermissionService } from '../../../core/permission.service';
import * as moment from 'moment';

@Component({
  selector: 'app-programming-detail-modal',
  templateUrl: './programming-detail-modal.component.html',
  styleUrls: ['./programming-detail-modal.component.scss'],
})
export class ProgrammingDetailModalComponent implements OnInit {
  periodicity: any[];
  order: [] = [];
  event: any;

  @Input() name_category: string = '';
  @Input() name_subcategory: string = '';

  @Input() mode: string = 'normal';

  @Output() detail_go = new EventEmitter<any>();
  @Output() open_share_Event = new EventEmitter<any>();
  @Output() go_to_pdf = new EventEmitter<any>();
  @Output() go_reports = new EventEmitter<any>();
  @Output() archived_order = new EventEmitter<any>();
  @Output() deleted_order = new EventEmitter<any>();
  @Output() transfer_order = new EventEmitter<any>();
  @Output() open_gallery = new EventEmitter<any>();

  all_reports = [];
  showEmptyReports: boolean = false;

  requested: boolean = false;
  photoUser: string = '';
  colorStatus: [] = [];
  endDate: string = '';

  colors: any = {
    cerrado: {
      text: 'Cerrada',
      color: '#06D6A0',
      image:
        'https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fcerrado.svg?alt=media&token=d9bb5ef8-bf16-48af-bc63-2c12d144eac2',
    },
    abierto: {
      text: 'Abierta',
      color: '#118AB2',
      image:
        'https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fabierto.svg?alt=media&token=a91871f0-9422-4b71-a538-fa56614d05e6',
    },
    archivada: {
      text: 'Archivada',
      color: '#073B4C',
      image:
        'https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Farchivado.svg?alt=media&token=bb2cce4f-f731-48bc-b1ba-4ac396740d30',
    },
    autorizada: {
      text: 'Autorizada',
      color: '#4FBAD3',
      image:
        'https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fautorizado.svg?alt=media&token=7ed00fd9-d2cc-4a73-94ed-45edbe7e4848',
    },
    programada: {
      text: 'Programada',
      color: '#FFD166',
      image:
        'https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fprogramado.svg?alt=media&token=736f4843-4be6-4c3b-9258-11637b3c10fa',
    },
    revision: {
      text: 'En revisión',
      color: '#C9CC52',
      image:
        'https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Frevision.svg?alt=media&token=b9db4267-7de0-4885-8480-c8c871545710',
    },
    rechazada: {
      text: 'Rechazada',
      color: '#CE5151',
      image:
        'https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Frechazado.svg?alt=media&token=8eacc472-ace3-47a5-8405-f8a6485b281b',
    },
    vencida: {
      text: 'Vencida',
      color: '#EF476F',
      image:
        'https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fvencido.svg?alt=media&token=0d28fdf5-2bf2-4a16-977a-f8109dce488c',
    },
  };

  constructor(
    private router: Router,
    public translateService: TranslateService,
    private programingservice: ProgramingService,
    public dialog: MatDialog,
    public ordersService: OrdersService,
    public permisionService: PermissionService,
    public storageService: StorageService,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    private modalGeneric: ModalGenericService,
    public dialogRef: MatDialogRef<ProgrammingDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.event = data.event.extendedProps.data;
  }

  async ngOnInit() {
    this.spinner.show();
    if (this.authService.customerDB != null) {
      this.programingservice.customerDB = this.authService.customerDB;
      this.programingservice.customerSelected =
        this.authService.customerSelected;

      await this.programingservice
        .getInfoOrder(this.event.firebase_id)
        .subscribe(
          (response: any) => {
            this.order = response.data;

            if (
              this.order['users_all_array'][0].photo_url === undefined ||
              this.order['users_all_array'][0].photo_url === ''
            ) {
              this.photoUser =
                'https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/avatares%2Fcampo%2Fcampo-verde.svg?alt=media&token=10c24aa1-aee9-43e3-b9a3-2f69970cb50e';
            } else {
              this.photoUser = this.order['users_all_array'][0].photo_url;
            }

            this.endDate = moment(
              this.event['scheduled_limit_date']['value']
            ).format('DD-MM-YYYY HH:mm');
            this.colorStatus = this.getColorStatus(
              this.event.status,
              this.event.authorization_status
            );

            console.log('Event Data', this.event);
            console.log('Order Data', this.order);

            this.spinner.hide();
          },
          (error) => {
            console.log('error', error);
          }
        );

      this.programingservice.getPeriodicty({}).subscribe(
        (response: any) => {
          console.log('response.data', response.periodicity);
          this.periodicity = response.periodicity;
        },
        (error) => {
          console.log('error', error);
        }
      );
    }
  }

  getColorStatus(status: any, authorization_status: any): [] {
    if (
      status == 2 &&
      (authorization_status === '0' || authorization_status === null)
    ) {
      return this.colors.abierto;
    } else if (status === 3 && authorization_status === '0') {
      return this.colors.cerrado;
    } else if (status === 3 && authorization_status === '1') {
      return this.colors.revision;
    } else if (status === 3 && authorization_status === '2') {
      return this.colors.autorizada;
    } else if (status === 2 && authorization_status === '3') {
      return this.colors.rechazada;
    } else if (status === 5) {
      return this.colors.vencida;
    } else if (status === 4) {
      return this.colors.archivada;
    } else if (status === 1) {
      return this.colors.programada;
    }
  }

  getPeriodicidad(value: string): string {
    let returns: string = '';

    switch (value) {
      case 'everyday':
        returns = 'Cada día';
        break;
      case 'everymonth':
        returns = 'Cada mes';
        break;
      case 'notrepeat':
        returns = 'No se repite';
        break;
      case 'mondaytosaturday':
        returns = 'Todos los días de lunes a sábado';
        break;
      case 'everyweek':
        returns = 'Cada semana';
        break;
      case 'mondaytofriday':
        returns = 'Todos los días de lunes a viernes';
        break;
    }
    return returns;
  }

  onClickActions(data: any) {
    this.dialogRef.close(data);
  }
  DetailGo(order) {
    if (this.storageService.getCurrentDB() != '') {
      const urlActividades =
        this.authService.customerSelected.url_report + order.firebase_id;
      window.open(urlActividades, 'blank');
    }
  }

  openShareEvent(order) {
    this.open_share_Event.emit(order);
    if (order.dynamic_url_web) {
      let urlEvento = order.dynamic_url_web;

      this.dialog.open(ShareDialogComponent, {
        height: '230px',
        width: '600px',
        data: {
          urlEvento,
        },
      });
    } else {
      if (this.storageService.getCurrentDB() != '') {
        const urlEvento =
          this.authService.customerSelected.url_report + order.id;

        this.ordersService
          .getInfoOrderWithUrl(order.id, urlEvento)
          .subscribe((respose: any) => {
            console.error(respose);
            let urlEvento = respose.data.dynamic_url_web;
            console.log('~ urlEvento', urlEvento);

            this.dialog.open(ShareDialogComponent, {
              height: '230px',
              width: '600px',
              data: {
                urlEvento,
              },
            });
          });
      }
    }
  }

  goToPdf(order) {
    this.go_to_pdf.emit(order);
    var url_customer = this.authService.customerSelected.url_pdf;
    const link = document.createElement('a');
    link.target = '_blank';
    var url_full = url_customer + this.order['firebase_id'];
    console.log(url_full);
    link.href = url_full;
    link.setAttribute('visibility', 'hidden');
    link.click();
  }

  async goReports(order) {
    if (!this.requested) {
      this.all_reports = [];
      this.showEmptyReports = false;
      let firebase_tenan = await this.authService.getTenancyParams();
      await firebase_tenan
        .collection('modules')
        .where('category', '==', order.category)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc: any) => {
            var reports = doc.data().reports;

            console.log(reports);

            if (reports) {
              await reports.forEach((i) => {
                i.get().then(async (property) => {
                  console.log(property);
                  if (property.data().available) {
                    this.all_reports.push(property.data());

                    if (this.all_reports.length <= 0) {
                      this.showEmptyReports = true;
                    }
                  } else {
                    this.showEmptyReports = true;
                  }
                });
              });
            } else {
              this.showEmptyReports = true;
            }
          });
        })
        .catch((err) => {
          console.log('Error Firebae -> ' + err);
        });

      this.requested = true;
    }
  }

  getReportSelected(order, report) {
    const url_file = report.url_file + order.id;
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url_file;
    link.download = 'reporte.xlsx';
    link.setAttribute('visibility', 'hidden');
    link.click();
  }

  archivedOrder(order) {
    this.archived_order.emit(order);
  }

  deletedOrder(order) {
    this.deleted_order.emit(order);
  }

  transferOrder(order) {
    this.transfer_order.emit(order);
  }

  openGallery(order, id) {
    this.open_gallery.emit({ order: order, id: id });
  }

  edit(edit_id: string) {
    const dialogRef = this.dialog
      .open(ProgrammingAddComponent, {
        data: {
          id: edit_id,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp == 'aceptar') {
          this.programingservice.publishSomeData({
            data: 'actualizar',
          });
        }
      });
  }

  deleteEvent(cust: any, scheduled_periodicity: string, serie: string) {
    console.log(cust);
    var date = new Date(cust.scheduled_programming_date._seconds * 1000);

    let fecha = this.fechainarray(date, 2);
    console.log(fecha);
    this.modalGeneric
      .onModelconfirmacionEvent(
        scheduled_periodicity,
        false, //disableClose
        '¿Está seguro de eliminar esta actividad?', //message
        true, // cancelButton
        this.translateService.instant(
          'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
        ), //confimButtonText
        true, // info
        false //imagen info
        //title
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp.button === 'aceptar') {
          this.spinner.show();
          let ide = cust.firebase_id;
          let tipo = 2;
          if (resp.radiodelete == 'all') {
            ide = serie;
            tipo = 1;
          }
          let params = { type: tipo, id: ide, fecha: fecha };
          console.log(params);
          this.programingservice.deleteProgrammingEvent(params).subscribe(
            (response) => {
              console.log('response', response);
              if (response.status == 'ok') {
                this.spinner.hide();
                this.sendSuccess('La actividad se ha eliminado correctamente');
              }
            },
            (error) => {
              console.log('error', error);
            }
          );
        }
      });
  }

  storeEvent(cust: any, scheduled_periodicity: string, serie: string) {
    console.log(cust);
    var date = new Date(cust.scheduled_programming_date._seconds * 1000);

    let fecha = this.fechainarray(date, 2);
    console.log(fecha);
    this.modalGeneric
      .onModelconfirmacionEvent(
        scheduled_periodicity,
        false, //disableClose
        '¿Está seguro de archivar esta actividad?', //message
        true, // cancelButton
        this.translateService.instant(
          'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
        ), //confimButtonText
        true, // info
        false, //imagen info
        '', //title
        'Si, archivar esta actividad.', //radio1
        'Si, archivar todas las actividades de aquí en adelante.' //radio2
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp.button === 'aceptar') {
          this.spinner.show();
          let ide = cust.firebase_id;
          let tipo = 2;
          if (resp.radiodelete == 'all') {
            ide = serie;
            tipo = 1;
          }
          let params = { type: tipo, id: ide, status: '4', fecha: fecha };
          console.log(params);
          this.programingservice.updateStatusOrder(params).subscribe(
            (response) => {
              console.log('response', response);
              if (response.status == 'ok') {
                this.spinner.hide();
                this.sendSuccess(
                  'La actividad se ha archivado correctamente',
                  false
                );
              }
            },
            (error) => {
              console.log('error', error);
            }
          );
        }
      });
  }

  sendSuccess(text: string, isDelete: boolean = true) {
    this.modalGeneric
      .onModelexito(
        false, //disableClose
        text, //message
        false, // cancelButton
        this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
        true, // info
        false //imagen info
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        //this.btn_save = true;
        this.spinner.hide();
        if (isDelete) {
          //this.getSchedulesEvents();
        } else {
          this.programingservice.publishSomeData({
            data: 'actualizar',
          });
        }
      });
  }

  private fechainarray(date, tipo) {
    let spli = null;
    if (tipo == 1) {
      let formatted_date_fin =
        date.getDate().toString().length == 1
          ? '0' + date.getDate() + '/'
          : date.getDate() + '/';
      if ((date.getMonth() + 1).toString().length == 1) {
        formatted_date_fin += '0' + (date.getMonth() + 1);
      } else {
        formatted_date_fin += date.getMonth() + 1;
      }
      formatted_date_fin += '/' + date.getFullYear();
      spli = formatted_date_fin.split('/');
    } else {
      spli = moment(date).format('Y-MM-DD');
    }
    return spli;
  }
}
