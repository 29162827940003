import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../core/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalGenericService } from "../../services/modal-generic.service";
import { StorageService } from "../../core/storage.service";
import { UserService } from "@app/core/user.service";
import { TranslateService } from "@ngx-translate/core";
import { EquipmentService } from "../../core/equipment.service";
import { Observable, Subject } from "rxjs";
import * as moment from "moment";

@Component({
  selector: "app-equipment-confirm-create-template",
  templateUrl: "./equipment-confirm-create-template.component.html",
  styleUrls: ["./equipment-confirm-create-template.component.scss"],
})
export class EquipmentConfirmCreateTemplateComponent implements OnInit {
  template = [];
  templateAux = [];
  equipmentItems = [];
  nameTemplate = "";
  confirmProccess = true;
  continueWithTemplate = false;
  userEmail = "";
  userUid = "";
  typeAction = "";
  uidEquipment = "";
  uidTemplate = "";
  equipment = {};
  previewUrl: string = "";
  base64: string = "";

  constructor(
    public authService: AuthService,
    private dialogRef: MatDialogRef<EquipmentConfirmCreateTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private modalGeneric: ModalGenericService,
    public storageService: StorageService,
    private router: Router,
    public translateService: TranslateService,
    private route: ActivatedRoute,
    public modelGeneric: ModalGenericService,
    public userService: UserService,
    private cd: ChangeDetectorRef,
    public equipmentService: EquipmentService
  ) {
    this.previewUrl = data.previewUrl;
    this.base64 = data.base64;

    this.uidTemplate = data.uidTemplate;
    this.equipment = data.equipment;
    this.template = data.template;
    this.templateAux = data.items;
    this.equipmentItems = data.items;
    this.typeAction = data.typeAction;
    if (authService.customerDB != null) {
      equipmentService.customerDB = authService.customerDB;
      equipmentService.customerSelected = authService.customerSelected;
      userService.customerDB = authService.customerDB;
      userService.customerSelected = authService.customerSelected;
    }
  }

  ngOnInit() {
    this.userService.getCurrentUser().then((resp) => {
      console.log("RESP", resp);
      this.userEmail = resp.email;
      this.userUid = resp.uid;
    });
    console.log(this.equipmentItems);
    console.log("Type in modal confirm", this.typeAction);
  }

  onChange(value) {
    this.nameTemplate = value;
  }

  async saveTemplate() {
    /*this.template.splice(0, 1);
    this.template.splice(0, 1);
    this.template.splice(0, 1);*/

    await this.uploadItems();

    if (this.nameTemplate !== "") {
      this.spinner.show();

      if (this.typeAction === "add") {
        const dataTemplate = {
          active: true,
          items: this.template,
          name: this.nameTemplate,
        };
        const dataEquipmentAnswer = {
          items: this.equipmentItems,
          equipment_id: "",
        };

        this.equipmentService
          .createTemplateEquipment(dataTemplate)
          .subscribe((resTemplate) => {
            this.equipmentService
              .createAnswerEquipment(dataEquipmentAnswer)
              .subscribe(async (resAnswer) => {
                let dataEquipment = {
                  active: true,
                  date_created: moment().format("X"),
                  date_modified: moment().format("X"),
                  qr: this.equipmentItems[0]["answer"],
                  name: this.equipmentItems[1]["answer"],
                  customer_id: this.equipmentItems[2]["answer"],
                  user_email_created: this.userEmail,
                  user_id_created: this.userUid,
                  equipment_answer_id: resAnswer.last_id,
                  equipment_template_id: resTemplate.last_id,
                  deleted: false,
                };

                if (this.base64 !== undefined) {
                  const downloadUrl =
                    await this.userService.uploadPhotoReference(
                      moment().format("DD-MM-YYYY_hh:mm:ss"),
                      this.base64,
                      "equipments"
                    );
                  dataEquipment["photo"] = downloadUrl;
                } else {
                  dataEquipment["photo"] = this.previewUrl;
                }

                this.equipmentService
                  .createEquipment(dataEquipment)
                  .subscribe((equipment) => {
                    this.equipmentService
                      .editAnswerEquipment({
                        firebase_id: resAnswer.last_id,
                        equipment_id: equipment.last_id,
                      })
                      .subscribe((updateAnswer) => {
                        this.spinner.hide();
                        this.dialogRef.close("close");
                        const ruta =
                          "/" +
                          this.storageService.getCurrentDB() +
                          "/equipments";
                        this.router.navigate([ruta]);
                      });
                  });
              });
          });
      } else if (this.typeAction === "edit") {
        if (this.equipment["equipment_template_id"] !== "") {
          console.log("GG");
          let dataEquipment = {
            firebase_id: this.equipment["firebase_id"],
            active: true,
            date_modified: moment().format("X"),
            qr: this.equipmentItems[0]["answer"],
            name: this.equipmentItems[1]["answer"],
            customer_id: this.equipmentItems[2]["answer"],
            user_email_created: this.userEmail,
            user_id_created: this.userUid,
            deleted: false,
          };
          console.log(this.equipmentItems);
          console.log("DATA EQUIPMENT", dataEquipment);

          const dataTemplate = {
            firebase_id: this.equipment["equipment_template_id"],
            active: true,
            items: this.template,
            name: this.nameTemplate,
          };

          this.equipmentService
            .updateTemplateEquipment(dataTemplate)
            .subscribe((templateUpdate) => {
              const dataEquipmentAnswer = {
                firebase_id: this.equipment["equipment_answer_id"],
                items: this.equipmentItems,
              };

              this.equipmentService
                .editAnswerEquipment(dataEquipmentAnswer)
                .subscribe(async (answersUpdate) => {
                  let dataEquipment = {
                    firebase_id: this.equipment["firebase_id"],
                    active: true,
                    date_modified: moment().format("X"),
                    qr: this.equipmentItems[0]["answer"],
                    name: this.equipmentItems[1]["answer"],
                    customer_id: this.equipmentItems[2]["answer"],
                    user_email_created: this.userEmail,
                    user_id_created: this.userUid,
                    deleted: false,
                  };

                  if (this.base64 !== undefined) {
                    const downloadUrl =
                      await this.userService.uploadPhotoReference(
                        moment().format("DD-MM-YYYY_hh:mm:ss"),
                        this.base64,
                        "equipments"
                      );
                    dataEquipment["photo"] = downloadUrl;
                  } else {
                    dataEquipment["photo"] = this.previewUrl;
                  }

                  this.equipmentService
                    .editEquipment(dataEquipment)
                    .subscribe((equipmentUpdate) => {
                      this.spinner.hide();
                      this.dialogRef.close("close");
                      const ruta =
                        "/" +
                        this.storageService.getCurrentDB() +
                        "/equipments";
                      this.router.navigate([ruta]);
                    });
                });
            });
        } else {
          const dataTemplate = {
            //firebase_id: this.equipment["equipment_template_id"],
            active: true,
            items: this.template,
            name: this.nameTemplate,
          };

          this.equipmentService
            .createTemplateEquipment(dataTemplate)
            .subscribe((resTemplate) => {
              const dataEquipmentAnswer = {
                firebase_id: this.equipment["equipment_answer_id"],
                items: this.equipmentItems,
              };

              this.equipmentService
                .editAnswerEquipment(dataEquipmentAnswer)
                .subscribe(async (answersUpdate) => {
                  let dataEquipment = {
                    firebase_id: this.equipment["firebase_id"],
                    active: true,
                    date_modified: moment().format("X"),
                    qr: this.equipmentItems[0]["answer"],
                    name: this.equipmentItems[1]["answer"],
                    customer_id: this.equipmentItems[2]["answer"],
                    equipment_template_id: resTemplate.last_id,
                    user_email_created: this.userEmail,
                    user_id_created: this.userUid,
                    deleted: false,
                  };

                  if (this.base64 !== undefined) {
                    const downloadUrl =
                      await this.userService.uploadPhotoReference(
                        moment().format("DD-MM-YYYY_hh:mm:ss"),
                        this.base64,
                        "equipments"
                      );
                    dataEquipment["photo"] = downloadUrl;
                  } else {
                    dataEquipment["photo"] = this.previewUrl;
                  }

                  this.equipmentService
                    .editEquipment(dataEquipment)
                    .subscribe((equipmentUpdate) => {
                      this.spinner.hide();
                      this.dialogRef.close("close");
                      const ruta =
                        "/" +
                        this.storageService.getCurrentDB() +
                        "/equipments";
                      this.router.navigate([ruta]);
                    });
                });
            });
        }
      }
    }
  }

  saveWithTemplate() {
    this.confirmProccess = false;
    this.continueWithTemplate = true;
  }

  async uploadItems() {
    console.log(this.equipmentItems);
    this.equipmentItems.forEach((it, index, arr) => {
      it.dropdown_answers.forEach(
        async (element: any, indexElement: any, arrDropdown: any) => {
          if (element.includes("data:image")) {
            console.log("Image item new");
            const downloadUrlItem = await this.userService.uploadPhotoReference(
              moment().format("DD-MM-YYYY_hh:mm:ss") +
                indexElement +
                "_" +
                index,
              element,
              "equipments_answers"
            );
            this.equipmentItems[index]["dropdown_answers"][indexElement] =
              downloadUrlItem;
            this.template[index - 3]["dropdown_answers"][indexElement] =
              downloadUrlItem;
          }
        }
      );
    });
    console.log(this.equipmentItems);
    console.log(this.template);
  }
  async saveWithoutTemplate() {
    this.spinner.show();
    console.log("this.template", this.template);
    await this.uploadItems();

    if (this.typeAction === "add") {
      const dataEquipmentAnswer = {
        items: this.equipmentItems,
        equipment_id: "",
      };
      this.equipmentService
        .createAnswerEquipment(dataEquipmentAnswer)
        .subscribe(async (answer) => {
          let data = {
            active: true,
            date_created: moment().format("X"),
            date_modified: moment().format("X"),
            qr: this.equipmentItems[0]["answer"],
            name: this.equipmentItems[1]["answer"],
            customer_id: this.equipmentItems[2]["answer"],
            user_email_created: this.userEmail,
            user_id_created: this.userUid,
            equipment_answer_id: answer.last_id,
            equipment_template_id: this.uidTemplate,
            deleted: false,
          };

          if (this.base64 !== undefined) {
            const downloadUrl = await this.userService.uploadPhotoReference(
              moment().format("DD-MM-YYYY_hh:mm:ss"),
              this.base64,
              "equipments"
            );
            data["photo"] = downloadUrl;
          } else {
            data["photo"] = this.previewUrl;
          }

          this.equipmentService.createEquipment(data).subscribe((equipment) => {
            this.equipmentService
              .editAnswerEquipment({
                firebase_id: answer.last_id,
                equipment_id: equipment.last_id,
              })
              .subscribe((updateAnswer) => {
                this.spinner.hide();
                this.dialogRef.close("close");
                const ruta =
                  "/" + this.storageService.getCurrentDB() + "/equipments";
                this.router.navigate([ruta]);
              });
          });
        });
    } else if (this.typeAction === "edit") {
      const dataEquipmentAnswer = {
        items: this.equipmentItems,
        firebase_id: this.equipment["equipment_answer_id"],
      };
      this.equipmentService
        .editAnswerEquipment(dataEquipmentAnswer)
        .subscribe(async (answerUpdate) => {
          let data = {
            active: true,
            firebase_id: this.equipment["firebase_id"],
            date_modified: moment().format("X"),
            qr: this.equipmentItems[0]["answer"],
            name: this.equipmentItems[1]["answer"],
            customer_id: this.equipmentItems[2]["answer"],
            user_email_created: this.userEmail,
            equipment_template_id: this.equipment["equipment_template_id"],
            user_id_created: this.userUid,
            deleted: false,
          };

          if (this.base64 !== undefined) {
            const downloadUrl = await this.userService.uploadPhotoReference(
              moment().format("DD-MM-YYYY_hh:mm:ss"),
              this.base64,
              "equipments"
            );
            data["photo"] = downloadUrl;
          } else {
            data["photo"] = this.previewUrl;
          }

          this.equipmentService
            .editEquipment(data)
            .subscribe((equipmentUpdate) => {
              this.spinner.hide();
              this.dialogRef.close("close");
              const ruta =
                "/" + this.storageService.getCurrentDB() + "/equipments";
              this.router.navigate([ruta]);
            });
        });
    }
  }

  cancelAllProccess() {
    this.template = this.templateAux;
    this.dialogRef.close("close");
    //const ruta = "/" + this.storageService.getCurrentDB() + "/equipments";
    //this.router.navigate([ruta]);
  }
}
