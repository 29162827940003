import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { rootRouterConfig } from "./app.routes";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import {
  AngularFireStorageModule,
  AngularFireStorage,
} from "@angular/fire/storage";
import { environment } from "../environments/environment";
import { LoginComponent } from "./login/login.component";
import { UserComponent } from "./user/user.component";
import { RegisterComponent } from "./register/register.component";
import { UserResolver } from "./user/user.resolver";
import { AuthGuard } from "./core/auth.guard";
import { AuthService } from "./core/auth.service";
import { UserService } from "./core/user.service";
import { FormsService } from "./core/forms.service";
import { EventsService } from "./core/events.service";
import { EquipmentService } from "./core/equipment.service";
import { ModulosService } from "./core/modulos.service";
import { StorageService } from "./core/storage.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { CustomerConfigComponent } from "./customer-config/customer-config.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardComponentAdmin } from "./dashboard-admin/dashboardadmin.component";

import { AgmCoreModule } from "@agm/core";
import { AgmMarkerClustererModule } from "@agm/markerclusterer";

import { NgxDateRangeModule } from "ngx-daterange";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { DateFormatPipe } from "./core/pipes/date-format.pipe";
import { JoinPipe } from "./core/pipes/join.pipe";
import { PrincipalComponent } from "./principal/principal.component";
//import { CompileDirective } from './compile.directive';
import { NgxPaginationModule } from "ngx-pagination";
import { QRCodeModule } from "angular2-qrcode";
import { GeneratePdfComponent } from "./generate-pdf/generate-pdf.component";
import { SearchLoadingComponent } from "./components/search-loading/search-loading.component";
import { SelectModule } from "ng2-select";
import { HeaderComponent } from "./components/header/header.component";
import { MenuComponent } from "./components/menu/menu.component";
import { UserListComponent } from "./user-list/user-list.component";
import { ListComponent } from "./user-list/list/list.component";
import { UserAddComponent } from "./user-list/user-add/user-add.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { FormulariosComponent } from "./formularios/formularios.component";
import { FormularioListComponent } from "./formularios/formulario-list/formulario-list.component";
import { FormularioAddComponent } from "./formularios/formulario-add/formulario-add.component";
import { PhoneMaskDirective } from "./core/directives/phone-mask.directive";
import { SlideToggleModule } from "ngx-slide-toggle";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { EventsComponent } from "./events/events.component";
import { EventsListComponent } from "./events/events-list/events-list.component";
import { EventsAddComponent } from "./events/events-add/events-add.component";
import { AngularDualListBoxModule } from "angular-dual-listbox";

import { MatMenuModule } from "@angular/material/menu";
import {
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatCardModule,
  MatTooltipModule,
  MatSelectModule,
  MatAutocompleteModule,
} from "@angular/material";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatListModule } from "@angular/material/list";
import { EditCustomerComponent } from "./dashboard-admin/edit-customer/edit-customer.component";
import { ModalGenericComponent } from "./components/modal-generic/modal-generic.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RecoverPasswordComponent } from "./recover-password/recover-password.component";
import { SearchMapComponent } from "./components/search-map/search-map.component";
import { DashboardItemComponent } from "./components/dashboard-item/dashboard-item.component";
import { CustomerListComponent } from "@app/customer-list/customer-list.component";
import { CustomerTableComponent } from "@app/customer-list/customer-table/customer-table.component";
import { CustomerAddComponent } from "./customer-list/customer-add/customer-add.component";
import { AddItemDragComponent } from "./events/add-item-drag/add-item-drag.component";

import "hammerjs"; // Mandatory for angular-modal-gallery 3.x.x or greater (`npm i --save hammerjs`)
import "mousetrap"; // Mandatory for angular-modal-gallery 3.x.x or greater (`npm i --save mousetrap`)
import { GalleryModule } from "@ks89/angular-modal-gallery";
import { PipesModule } from "./pipes/pipes.module";

import { LOCALE_ID } from "@angular/core";
import { registerLocaleData } from "@angular/common";

import localeEsMX from "@angular/common/locales/es-MX";
import { ShareDialogComponent } from "@app/detail-order/components/share-dialog/share-dialog.component";
import { DetailOrderComponent } from "@app/detail-order/detail-order.component";
import { ProgrammingListComponent } from "./programming-list/programming-list.component";
import { BulkLoadComponent } from "./programming-list/bulk-load/bulk-load.component";
import { ProgrammingTableComponent } from "./programming-list/programming-table/programming-table.component";
import { ProgrammingAddComponent } from "./programming-list/programming-add/programming-add.component";
import { ProgrammingSearchMapComponent } from "./programming-list/programming-search-map/programming-search-map.component";
import { ProgrammingViewComponent } from "./programming-list/programming-view/programming-view.component";
import { ProgrammingDetailModalComponent } from "@app/programming-list/programming-calendar/programming-detail-modal/programming-detail-modal.component";
import { ProgrammingAddressComponent } from "./programming-list/programming-address/programming-address.component";

import { ModalImageComponent } from "./user-list/modal-image/modal-image.component";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { ConfigurationListComponent } from "./configuration/configuration-list/configuration-list.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ClassificationSectionComponent } from "./classification-section/classification-section.component";
import { ClassificationAddeditModalComponent } from "./classification-section/classification-list/classification-addedit-modal/classification-addedit-modal.component";
import { MatTabsModule } from "@angular/material/tabs";
import { ClassificationEditnameModalComponent } from "@app/classification-section/classification-list/classification-editname-modal/classification-editname-modal.component";
import { DashboardreportlayoutComponent } from "./dashboardreport/components/dashboardreportlayout/dashboardreportlayout.component";
import { ConfigurationWebhookComponent } from "./configuration/configuration-webhook/configuration-webhook.component";
import { MatRadioModule } from "@angular/material/radio";
import { CheckMobileService } from "@app/core/check-mobile.service";
import { ModalCheckMobileComponent } from "./modal-check-mobile/modal-check-mobile.component";
import { ConfigurationTimezoneComponent } from "./configuration/configuration-timezone/configuration-timezone.component";
import { HelpComponent } from "./help/help.component";
import { RecentActivitiesComponent } from "./help/pages/recent-activities/recent-activities.component";
import { CustomersComponent } from "./help/pages/customers/customers.component";
import { TypesOfEventsComponent } from "./help/pages/types-of-events/types-of-events.component";
import { ProgramationComponent } from "./help/pages/programation/programation.component";
import { DataSheetComponent } from "./help/pages/data-sheet/data-sheet.component";
import { UsersComponent } from "./help/pages/users/users.component";
import { DashboardComponent as DashboardHelpComponent } from "./help/pages/dashboard/dashboard.component";
import { ConfigurationComponent as ConfigurationHelpComponent } from "./help/pages/configuration/configuration.component";
import { DownloadXlsxComponent } from "./events/download-xlsx/download-xlsx.component";
import { ChangePasswordComponent } from "./user-list/change-password/change-password.component";
import { TermsConditionsComponent } from "./terms-conditions/terms-conditions.component";
import { TransferActivityComponent } from "./transfer-activity/transfer-activity.component";
import { EquipmentComponent } from "./equipment/equipment.component";
import { EquipmentAddComponent } from "./equipment/equipment-add/equipment-add.component";
import { EquipmentTableComponent } from "./equipment/equipment-table/equipment-table.component";
import { EquipmentTemplateComponent } from "./equipment/equipment-template/equipment-template.component";
import { EquipmentTemplateFormAddComponent } from "./equipment/equipment-template-form-add/equipment-template-form-add.component";
import { EquipmentConfirmCreateTemplateComponent } from "./equipment/equipment-confirm-create-template/equipment-confirm-create-template.component";
import { EquipmentDetailComponent } from "./equipment/equipment-detail/equipment-detail.component";
import { ConfigurationHourlaborComponent } from "./configuration/configuration-hourlabor/configuration-hourlabor.component";
import { ProgrammingBulkloadComponent } from "./programming-list/programming-bulkload/programming-bulkload.component";
import { DownloadXlsxNewComponent } from "./events/download-xlsx-new/download-xlsx-new.component";
import { ConfigurationCatalogsComponent } from './configuration/configuration-catalogs/configuration-catalogs.component';

registerLocaleData(localeEsMX, "es-MX");
//import { AgmMarkerWithLabelModule } from '@ajqua/marker-with-label';

@NgModule({
  entryComponents: [
    ShareDialogComponent,
    ModalGenericComponent,
    AddItemDragComponent,
    ProgrammingDetailModalComponent,
    ModalImageComponent,
    ClassificationAddeditModalComponent,
    ClassificationEditnameModalComponent,
    ModalCheckMobileComponent,
    DownloadXlsxComponent,
    DownloadXlsxNewComponent,
    TermsConditionsComponent,
    TransferActivityComponent,
    BulkLoadComponent,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    RegisterComponent,
    CustomerConfigComponent,
    DashboardComponent,
    CustomerListComponent,
    CustomerTableComponent,
    SafeHtmlPipe,
    DateFormatPipe,
    JoinPipe,
    PrincipalComponent,
    GeneratePdfComponent,
    SearchLoadingComponent,
    HeaderComponent,
    MenuComponent,
    UserListComponent,
    ListComponent,
    UserAddComponent,
    FormulariosComponent,
    FormularioListComponent,
    FormularioAddComponent,
    PhoneMaskDirective,
    EventsComponent,
    EventsListComponent,
    EventsAddComponent,
    DashboardComponentAdmin,
    EditCustomerComponent,
    ModalGenericComponent,
    ResetPasswordComponent,
    RecoverPasswordComponent,
    SearchMapComponent,
    CustomerAddComponent,
    AddItemDragComponent,
    DownloadXlsxComponent,
    DownloadXlsxNewComponent,
    ShareDialogComponent,
    DetailOrderComponent,
    ProgrammingListComponent,
    BulkLoadComponent,
    ProgrammingTableComponent,
    ProgrammingAddComponent,
    ProgrammingSearchMapComponent,
    ProgrammingViewComponent,
    ProgrammingDetailModalComponent,
    ProgrammingAddressComponent,
    DashboardItemComponent,
    ModalImageComponent,
    ConfigurationComponent,
    ConfigurationListComponent,
    ClassificationSectionComponent,
    ClassificationAddeditModalComponent,
    ClassificationEditnameModalComponent,
    DashboardreportlayoutComponent,
    ConfigurationWebhookComponent,
    ModalCheckMobileComponent,
    ConfigurationTimezoneComponent,
    HelpComponent,
    RecentActivitiesComponent,
    CustomersComponent,
    TypesOfEventsComponent,
    ProgramationComponent,
    DataSheetComponent,
    UsersComponent,
    DashboardHelpComponent,
    ConfigurationHelpComponent,
    DownloadXlsxComponent,
    DownloadXlsxNewComponent,
    ChangePasswordComponent,
    TermsConditionsComponent,
    TransferActivityComponent,
    EquipmentComponent,
    EquipmentAddComponent,
    EquipmentTableComponent,
    EquipmentTemplateComponent,
    EquipmentTemplateFormAddComponent,
    EquipmentConfirmCreateTemplateComponent,
    EquipmentDetailComponent,
    ConfigurationHourlaborComponent,
    ProgrammingBulkloadComponent,
    ConfigurationCatalogsComponent,
  ],
  imports: [
    BrowserModule,
    NgxDateRangeModule,
    FormsModule,
    PipesModule,
    TranslateModule.forRoot(),
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDPI_jYrXxcTGMmUKmSQQ0I021pBpzAZ4Q",
      libraries: ["places"],
    }),
    AgmMarkerClustererModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgxPaginationModule,
    QRCodeModule,
    SelectModule,
    HttpClientModule,
    ImageCropperModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SlideToggleModule,
    NgxSpinnerModule,
    AngularDualListBoxModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    DragDropModule,
    MatListModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTooltipModule,
    GalleryModule.forRoot(),
    MatChipsModule,
    MatFormFieldModule,
    MatTabsModule,
    MatRadioModule,
  ],
  providers: [
    AuthService,
    UserService,
    UserResolver,
    AuthGuard,
    StorageService,
    ModulosService,
    FormsService,
    EventsService,
    CheckMobileService,
    EquipmentService,
    { provide: LOCALE_ID, useValue: "es-MX" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
