import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { ConfigCustomer } from '../models/customer';
import { environment } from '../../environments/environment';

@Injectable()
export class EquipmentService {
  public customerDB: firebase.database.Database = null;
  public customerSelected: ConfigCustomer = null;

  customer_route = '';
  api_customer_key = '';
  constructor(public http: HttpClient, private router: Router) {
    this.api_customer_key = sessionStorage.getItem('api_customer_key');
  }

  getEquipment(uid: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/getEquipment/${uid}`;
    return this.http.get(url, { headers });
  }

  getEquipments(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/getEquipments`;
    return this.http.post(url, body, { headers });
  }

  createEquipment(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/createEquipment`;
    return this.http.post(url, body, { headers });
  }

  getAnswerEquipment(uid: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/getAnswerEquipment/${uid}`;
    return this.http.get(url, { headers });
  }

  createAnswerEquipment(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/createAnswerEquipment`;
    return this.http.post(url, body, { headers });
  }

  editEquipment(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/editEquipment`;
    return this.http.post(url, body, { headers });
  }

  editAnswerEquipment(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/editAnswerEquipment`;
    return this.http.post(url, body, { headers });
  }

  deleteEquipment(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/deleteEquipment`;
    return this.http.post(url, body, { headers });
  }

  getTemplateEquipment(uid: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/getTemplateEquipment/${uid}`;
    return this.http.get(url, { headers });
  }

  getTemplatesEquipment(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/getTemplatesEquipment`;
    return this.http.post(url, body, { headers });
  }

  createTemplateEquipment(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/createTemplateEquipment`;
    return this.http.post(url, body, { headers });
  }

  updateTemplateEquipment(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + `/updateTemplateEquipment`;
    return this.http.post(url, body, { headers });
  }
}
