import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, from, throwError, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { Http } from '@angular/http';

import 'rxjs/add/operator/toPromise';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { ordenesObj } from '../models/order';
import { ItemEvent } from '../models/evidence';
import { Router } from '@angular/router';
import { ConfigCustomer } from '../models/customer';
import {environment} from "../../environments/environment";


@Injectable()
export class EventsService {

  public customerDB: firebase.database.Database = null;
  public customerSelected: ConfigCustomer = null;

  customer_route = '';
  api_customer_key = '';
  constructor (
    public http: HttpClient,
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.api_customer_key = sessionStorage.getItem('api_customer_key');
  }

  getEvent(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/getEvent';
    return this.http.post(url, body, { headers });
    
  }

  getEventsList(): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/getAllEvents';
    return this.http.get(url, { headers });
  }
  
  getEventsListBigquery(): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/getAllEventsBigquery';
    return this.http.get(url, { headers });
  }

  updateEventDisabled(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/changeStatusEvent';
    return this.http.put(url, body, { headers });
  }

  saveEvent(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    
    delete body.configuration;
    delete body.configuration_doc;

    const url = environment.urlEndpointMaster + (!body.uid ? '/createEvent' : '/updateEvent');
    return this.http.post(url, body, { headers });
  }

  deleteEvent(body: any): Observable<any> {     
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/deleteEvent';
    return this.http.post(url, body, { headers }); 
  }

  getEventConfiguration(body: any): Promise<any> {
    return this.customerDB.app.firestore().collection('items_config').where('category', '==', body.uid).get()
  }


  saveAddUpdateNotify(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    
    const url = environment.urlEndpointMaster + "/add_update_notify";
    return this.http.post(url, body, { headers });
  }

  saveEventConfiguration(body: any, uid: any): Observable<any> {



    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment.headerAuthorization,
       'api-customer-key': this.api_customer_key
   });
   
   const url = environment.urlEndpointMaster + "/saveEventConfiguration";


   return this.http.post(url, {body:body, uid:uid}, { headers });
  }
}
