import {
  Component,
  OnInit,
  Inject,
  NgZone,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "../../core/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalGenericService } from "../../services/modal-generic.service";
import { StorageService } from "../../core/storage.service";
import { EquipmentService } from "../../core/equipment.service";

@Component({
  selector: "app-equipment-template",
  templateUrl: "./equipment-template.component.html",
  styleUrls: ["./equipment-template.component.scss"],
})
export class EquipmentTemplateComponent implements OnInit {
  public current_component: any;
  public template_search: string = "";

  templates = [];
  selectedTemplate = {};

  get currentTemplate() {
    return this.storageService.getCurrentDB();
  }

  constructor(
    public authService: AuthService,
    private dialogRef: MatDialogRef<EquipmentTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private modalGeneric: ModalGenericService,
    public storageService: StorageService,
    private router: Router,
    public equipmentService: EquipmentService,
    public cd: ChangeDetectorRef
  ) {
    if (authService.customerDB != null) {
      equipmentService.customerDB = authService.customerDB;
      equipmentService.customerSelected = authService.customerSelected;
    }
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.spinner.show();
    this.templates = [];
    this.equipmentService
      .getTemplatesEquipment({ nameEquipment: this.template_search })
      .subscribe((templates) => {
        templates.data.forEach((item) => {
          item["check"] = false;
          this.templates.push(item);
        });

        this.spinner.hide();
        console.log("Templates with check", this.templates);
      });

    this.cd.detectChanges();
  }

  changeCheck(template, index) {
    this.templates.forEach((item, i, arr) => {
      this.templates[i]["check"] = false;
    });

    this.selectedTemplate = this.templates[index];
    this.templates[index]["check"] = true;
    console.log("Templates change check", this.templates);
    this.cd.detectChanges();
  }

  clearSearch() {
    if (this.template_search.trim() !== "") {
      this.template_search = "";
      this.getData();
    }
  }

  searchTemplates() {
    if (this.template_search.trim() !== "") {
      this.getData();
    }
  }

  onUseTemplate() {
    const validate = this.templates.filter((item) => {
      return item["check"];
    });

    if (validate.length > 0) {
      this.dialogRef.close({
        type: "template",
        selected: this.selectedTemplate["firebase_id"],
      });
    }
  }

  onCreateFull() {
    this.dialogRef.close({ type: "create", selected: null });
  }
}
