import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "@app/core/storage.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalGenericService} from "@app/services/modal-generic.service";

@Component({
  selector: 'app-classification-addedit-modal',
  templateUrl: './classification-addedit-modal.component.html',
  styleUrls: ['./classification-addedit-modal.component.scss']
})
export class ClassificationAddeditModalComponent implements OnInit {
  formGeneral = new FormGroup({
      firebase_id: new FormControl(),
      selected_category: new FormControl(),
      name_category: new FormControl('', [Validators.required]),
      name_subcategory: new FormControl('', []),
      categories: new FormArray([]),
  });
  tabActive: number = 0;
  constructor(public translateService: TranslateService,
              public storageService: StorageService,
              private modalGeneric: ModalGenericService,
              public  dialogRef:  MatDialogRef<ClassificationAddeditModalComponent>,
              @Inject(MAT_DIALOG_DATA) public  data:  any) {
      if (data.length > 0) {
          this.formGeneral.get('firebase_id').setValue(data[0]['firebase_id']);
          this.formGeneral.get('name_category').setValue(data[0]['name_category']);
          this.formGeneral.get('name_subcategory').setValue(data[0]['name_subcategory']);
          if (data[0]['categories'] && data[0]['categories'].length > 0) {
              data[0]['categories'].forEach((category, index) => {
                 this.onAddCategory(category, index);
              });
          }
      }
  }

  ngOnInit() {
      /*this.formGeneral.get('categories').valueChanges.subscribe((data) => {
         console.log('Esta reconociendo el cambio', data);
      });*/
  }
  getLastIndexCategories() {
      return this.formGeneral.get('categories')['controls'].length;
  }
  getLastIndexSubcategories() {
      return this.formGeneral.get('categories')['controls'][this.formGeneral.get('selected_category').value].get('subcategories')['controls'].length;
  }
  getFilteredCategories() {
      return this.formGeneral.get('categories')['controls'] ? this.formGeneral.get('categories')['controls'].filter(item => item.get('deleted').value == false).map(item => {
          return {id: item.get('index').value, name: item.get('name').value};
      }): [];
  }
  getFormCategories() {
      return this.formGeneral.get('categories')['controls'] ? this.formGeneral.get('categories')['controls'].filter(item => item.get('deleted').value == false) : [];
  }
  getFormSubcategories() {
      return this.formGeneral.get('selected_category').value ? this.formGeneral.get('categories')['controls'][this.formGeneral.get('selected_category').value].get('subcategories')['controls'].filter(item => item.get('deleted').value == false) : [];
  }
  onAddCategory(data, index) {
      console.log('Agregando categorias');
      const category = new FormGroup({
          index: new FormControl(index),
          firebase_id: new FormControl(data['firebase_id'] ? data['firebase_id'] : ''),
          edited: new FormControl(false),
          name: new FormControl(data['name'] ? data['name'] : ''),
          deleted: new FormControl(data['deleted'] ? data['deleted'] : false),
          subcategories: new FormArray([])
      });
        category.valueChanges.subscribe((dataChange) => {
            console.log('Datos de cuadno se agrega', dataChange);
            this.formGeneral.get('categories')['controls'][dataChange['index']].get('edited').setValue(true, {emitEvent: false });
            //this.formGeneral.get('selected_category').setValue('');
        });
      (this.formGeneral.get('categories') as FormArray).push(category);
      this.formGeneral.get('selected_category').setValue('');
      if (data && data['subcategories'] && data['subcategories'].length > 0) {
          data['subcategories'].forEach((sub, i) => {
              this.onAddSubcategory(sub, i, index);
          });
      }
  }

  onAddSubcategory(data, index, parent) {
      console.log('Agregando subcategorias');
      const subcategory = new FormGroup({
          index: new FormControl(+index),
          parent: new FormControl(+parent),
          firebase_id: new FormControl(data['firebase_id'] ? data['firebase_id'] : ''),
          edited: new FormControl(false),
          name: new FormControl(data['name'] ? data['name'] : ''),
          deleted: new FormControl(data['deleted'] ? data['deleted'] : false),
      });
      subcategory.valueChanges.subscribe((dataChange) => {
          console.log('Datos que cambian sub', dataChange);
          this.formGeneral.get('categories')['controls'][dataChange['parent']].get('subcategories')['controls'][dataChange['index']].get('edited').setValue(true, {emitEvent: false});
      });

      //this.formGeneral.get('categories')['controls'][parent].get('subcategories')['controls'].push(subcategory);
      (this.formGeneral.get('categories')['controls'][parent].get('subcategories') as FormArray).push(subcategory);
      //this.formGeneral.get('categories')['controls'][parent].get('subcategories')['_registerControl'](subcategory);
  }

  onDeleteCategory(item: FormGroup) {
      item.get('deleted').setValue(true);
      this.formGeneral.get('selected_category').setValue('');
  }

  onDeleteSubcategory(item: FormGroup) {
      item.get('deleted').setValue(true);
  }

  onSaveCategory() {
      console.log('Datos form', this.formGeneral.value);
      let dataFinal = JSON.parse(JSON.stringify(this.formGeneral.value));
      //console.log('Datos cast', dataFinal);
      let inputEmpty = false;

      if(!dataFinal['name_subcategory']){

        console.log('Entramos....');
        dataFinal['categories'].forEach((cat) => {
            let sub = cat['subcategories'].filter(item => item['deleted'] === false);
           if(  sub.length > 0 ){
            inputEmpty = true;
           }
           
        });
        
      }

      if (!dataFinal['name_category'])
        inputEmpty = true;
      dataFinal['categories'].forEach((cat) => {
          cat['subcategories'] = cat['subcategories'].filter(item => item['edited'] === true);
          if (cat['subcategories'].length > 0) {
              cat['edited'] = true;
          }
          if (!inputEmpty) {
              
              inputEmpty = cat['subcategories'].some(item => !item['name'] && item['deleted'] == false);
             

          }
      });

     

    //   dataFinal['categories'] = dataFinal['categories'].filter(item => item['edited'] === true);
      if (!inputEmpty) {
        
          inputEmpty = dataFinal['categories'].some(item => !item['name'] && item['deleted'] == false);
        

      }

      if (inputEmpty) {
          this.sendModelError('Verifique el llenado completo de datos..');
      } else {
          console.log('Datos a enviar', dataFinal);
          this.dialogRef.close({save: true, data: dataFinal});
      }
  }

    sendModelError(msg: string) {
        this.modalGeneric.onModelerror(
            false, //disableClose
            msg, //message
            false, // cancelButton
            this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
            true, // info
            false //imagen info
        );
    }

}
