import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss'],
})
export class ModalImageComponent implements OnInit {
  showImageAdd: boolean = false;
  cutout_shape: string = 'circle';
  isThumbnail: boolean = false;
  resize: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<ModalImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public modalGeneric: ModalGenericService
  ) {
    if (this.data.cutout_shape) {
      this.cutout_shape = this.data.cutout_shape;
    }

    if (this.data.isThumbnailParam) {
      this.isThumbnail = this.data.isThumbnailParam;
      if (this.data.resize) {
        this.resize = this.data.resize;
      }
    }

    console.log('DAAAAATAAA', this.cutout_shape);
  }

  ngOnInit() {}

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (this.isThumbnail) {
      let fr = new FileReader();
      fr.onload = () => {
        // when file has loaded

        this.croppedImage = fr.result;
        var img = new Image();
        img.onload = () => {
          console.log('Width', img.width);
          console.log('Height', img.height);
          if (img.width < 80 && img.height < 80) {
            this.modalGeneric
              .onModelerror(
                true, //disableClose
                'Favor de subir una imagen mayor de 80x80 px', //message
                false, // cancelButton
                'Aceptar', //confimButtonText
                true, // info
                false //imagen info
              )
              .afterClosed()
              .pipe(take(1))
              .subscribe(() => {
                this.showImageAdd = false;
                this.imageChangedEvent = null;
                this.croppedImage = '';
                this.showCropper = false;
              });
          } else {
            this.showImageAdd = true;
          }
        };
        if (typeof fr.result === 'string') {
          img.src = fr.result;
        }
      };
      fr.readAsDataURL(event.target.files[0]);
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.showImageAdd = true;
    this.croppedImage = event.base64;
    console.log(' croppedImage croppedImage croppedImage ', this.croppedImage);
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  cancelSave() {
    this.dialogRef.close();
  }

  saveIamgeUser() {
    let parameters = { button: 'aceptar', imageB64: this.croppedImage };
    this.dialogRef.close(parameters);
  }
}
