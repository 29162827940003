import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage.service';
import { AuthService } from '../core/auth.service';
import { UserService } from '../core/user.service';
import { ConfigCustomer } from '../models/customer';
import { NgxSpinnerService } from 'ngx-spinner';

import { TranslateService } from '@ngx-translate/core';
import { ModalGenericService } from '@app/services/modal-generic.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  recoverForm: FormGroup;
  validemail = true;
  submitted = false;
  btnrecuperar = false;
  csstype = 'danger';
  txtError = "Por favor, ingresa un correo electrónico válido.";
  private onDestroy = new Subject<void>();
  constructor( private router: Router,
    private fb: FormBuilder,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    public userService: UserService,
    private translateService: TranslateService,
    private modalGeneric: ModalGenericService,
    public storageService: StorageService) {this.createForm();
    
      
    }

  ngOnInit() {
    this.authService.customerSelected$.pipe(takeUntil(this.onDestroy)).subscribe((customerSelected: ConfigCustomer) => {
        if (customerSelected) {
            this.userService.customerSelected = customerSelected;
        }
    });

    
  }

  createForm() {
    this.recoverForm = this.fb.group({
      email: [null, Validators.required ]
    });
  }

  tryrecover(form){
    this.submitted = true;
    this.validemail = true;
    
    if(form.valid){
      this.spinner.show();
      this.userService.forgotpassword(form.value).subscribe((response) => {
        if(response.status == 'ok')
        {
          this.modalGeneric.onModelexito(
            false, //disableClose
            'Te hemos enviado un correo electrónico, por favor sigue las instrucciones para recuperar tu contraseña.', //message
            false,  // cancelButton
            this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
            true, // info
            false, //imagen info
          )
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => {
            const ruta = '/' + this.authService.customer_route + '/login';
            this.router.navigate([ruta]);
          })
        }else{
          this.modalGeneric.onModelerror(
            false, //disableClose
            response.error, //message
            false,  // cancelButton
            this.translateService.instant('MESSAGE.ACEPTAR'),  //confimButtonText
            true, // info
            false, //imagen info
          )
        }
        this.spinner.hide();
      },
      (err) => {
        console.log('forgotpassword wrror',err);
        this.spinner.hide();
      });
    }
  }

  

  checkButton(value) {
    let returns = false;
    if ( value.email !== '') {
      this.btnrecuperar = true;
    }
    // tslint:disable-next-line:one-line


    var patron = /^[a-z0-9_-]+(?:.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    if(patron.test(value.email)){
      console.log('entro');
      this.validemail = true;
      this.btnrecuperar = true;
      this.authService.doEmailExists(value).then(res => {
        if(res.length == 0){
          this.txtError = "Por favor, ingresa un correo electrónico registrado..";
          this.validemail = false;
          this.btnrecuperar = false;
          returns = true;
        }
      }, err => {     
        this.txtError = "Por favor, ingresa un correo electrónico válido.";
        this.validemail = false;
        this.btnrecuperar = false;   
        this.txtError = err;
        console.log(err);
      })
    }
    else{
      console.log('entro2');
      this.validemail = false;
      this.btnrecuperar = false;
    }
    console.log(value.email);
    return returns;
  }

  validaEmail(correo){
    let returns = false;
    return returns;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
