import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'join' })
@Injectable()
export class JoinPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }  

  transform(array: any, options: any = {}): any {
    if (array) {
      options.property = options.property || 'name';
      options.separator = options.separator || ', ';
      return this._sanitizer.bypassSecurityTrustHtml(array.map(a => a[options.property] || a).join(options.separator));
    }
    return '';
  }

}
