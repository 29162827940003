import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { UserService } from "../core/user.service";
import { AuthService } from "../core/auth.service";
import { StorageService } from "../core/storage.service";
import { UtilsService } from "../core/helper/utils.helper";
import { Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";

import * as mockUsers from "../mocks/user-list.json";
import { PermissionService } from "../core/permission.service";
import { MatDialog } from "@angular/material";
import { DownloadXlsxNewComponent } from "./download-xlsx-new/download-xlsx-new.component";

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"],
})
export class EventsComponent implements OnInit {
  public user_email = "-----------------------------";
  public user_list: any;
  public users_by_page: number = 10;
  public user_current_page: number = 1;

  public user_filter: string = "";

  public current_component: any;

  @ViewChild("current_page") current_page;

  get getUserList() {
    return (
      this.user_list &&
      this.user_list.default &&
      this.user_list.default.filter((f) => {
        return (
          this.utilsService.search(f, "displayName", this.user_filter) ||
          this.utilsService.search(f, "role", this.user_filter)
        );
      })
    );
  }

  get currentCustomer() {
    return this.storageService.getCurrentDB();
  }

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public storageService: StorageService,
    private router: Router,
    private dialog: MatDialog,
    public utilsService: UtilsService,
    public translateService: TranslateService,
    public permisionService: PermissionService
  ) {
    if (authService.customerDB != null) {
      userService.customerDB = authService.customerDB;
      userService.getCurrentUser().then((user) => {
        this.user_email = user.email;
      });
    }
  }

  ngOnInit() {}

  pageChanged($event) {
    this.user_current_page = $event;
  }

  salir() {
    this.authService
      .doLogout()
      .then(() => {
        if (this.storageService.getCurrentDB() != "") {
          const ruta = "/" + this.storageService.getCurrentDB() + "/login";
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      })
      .catch((err) => {
        // console.error('Error LogOut -> ' + err);
        if (this.storageService.getCurrentDB() != "") {
          const ruta = "/" + this.storageService.getCurrentDB() + "/login";
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      });
  }

  button_delete_action() {}

  onActivate(componentRef) {
    this.current_component = null;
  }


}
