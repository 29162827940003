import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { PermissionService } from "../../core/permission.service";
import { AuthService } from "../../core/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { EquipmentService } from "../../core/equipment.service";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { ModalGenericService } from "../../services/modal-generic.service";
import QRCode from "qrcode";
import * as moment from "moment";

@Component({
  selector: "app-equipment-table",
  templateUrl: "./equipment-table.component.html",
  styleUrls: ["./equipment-table.component.scss"],
})
export class EquipmentTableComponent implements OnInit {
  public equipments_by_page: number = 10;
  public equipments_current_page: number = 1;
  public equipment_search: string = "";
  public equipment_search_id: string = "";
  public type_equipment: string = "";
  totalitems: number = 0;
  equipments = [];
  listTypeEquipments = [];

  constructor(
    private dialog: MatDialog,
    private modalGeneric: ModalGenericService,
    private spinner: NgxSpinnerService,
    public equipmentService: EquipmentService,
    public authService: AuthService,
    public translateService: TranslateService,
    public permisionService: PermissionService,
    public modelGeneric: ModalGenericService,
    public cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getTypeEquipments();
    this.getData();
  }

  convertDate(date: string): string {
    return moment(date).format("DD/MM/YYYY HH:mm A");
  }

  getTypeEquipments() {
    this.equipmentService
      .getTemplatesEquipment({ nameEquipment: "" })
      .subscribe((templates) => {
        templates.data.forEach((item) => {
          item["check"] = false;
          this.listTypeEquipments.push(item);
        });

        console.log("Templates with check", this.listTypeEquipments);
      });
  }

  async generateQR(equipment_info) {
    const qr = await QRCode.toDataURL(equipment_info);

    fetch(qr)
      .then((res) => res.blob())
      .then((blob) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = equipment_info;
        document.body.appendChild(a);
        a.click();
      });
  }

  getData() {
    this.spinner.show();
    this.equipmentService
      .getEquipments({
        pageActual: this.equipments_current_page,
        orders_by_block: this.equipments_by_page,
        nameEquipment: this.equipment_search,
        templateId: this.type_equipment,
        id: this.equipment_search_id,
      })
      .subscribe((equipments) => {
        console.log(equipments);
        this.equipments = equipments.data;
        this.totalitems = equipments.totalitems;
        this.spinner.hide();
      });
    this.cd.detectChanges();
  }

  pageChanged($event) {
    this.equipments_current_page = $event;
    this.getData();
  }

  equipments_by_blockChanged() {
    if (this.round(this.equipments.length / this.equipments_by_page) == 1) {
      this.equipments_current_page = 1;
    }

    this.getData();
  }

  resetText() {
    if (this.equipment_search.trim() !== "") {
      this.equipment_search = "";
      this.getData();
    }
  }

  resetTextID() {
    if (this.equipment_search_id.trim() !== "") {
      this.equipment_search_id = "";
      this.getData();
    }
  }

  searchEquipmentsById() {
    if (this.equipment_search_id.trim() !== "") {
      this.getData();
    }
  }

  searchEquipments() {
    if (this.equipment_search.trim() !== "") {
      this.getData();
    }
  }

  round(value) {
    return Math.ceil(value);
  }
  updateCurrentPage(value) {
    try {
      if (this.equipments_by_page !== 1) {
        if (value == 1) {
          this.equipments_by_page = 1;
        }
      }
    } catch (err) {}
  }

  onChangeTypeEquipment(typeEq) {
    this.type_equipment = typeEq;
    this.getData();
  }

  updateEquipmentDisabled(equipment) {
    //this.spinner.show();
    equipment.active = !equipment.active;

    this.equipmentService
      .editEquipment({
        firebase_id: equipment.firebase_id,
        active: equipment.active,
      })
      .subscribe((response) => {
        // this.spinner.hide();
        this.getData();
        console.log("RESPONSE DE " + JSON.stringify(response));
      });
  }

  deleteEquipment(equipment) {
    const dialogRef = this.modelGeneric.onModelconfirmacion(
      true,
      "¿Estás seguro de eliminar este equipo?",
      true
    );

    dialogRef.afterClosed().subscribe((data) => {
      if (data == "aceptar") {
        this.spinner.show();
        // this.modelGeneric.onModelexito(true, 'El tipo de actividad ha sido eliminado exitosamente.', false);

        this.equipmentService
          .deleteEquipment({ id: equipment.firebase_id })
          .subscribe((response) => {
            this.spinner.hide();
            if (response.status === "ok") {
              const dialogRef = this.modelGeneric.onModelexito(
                true,
                "El equipo ha sido eliminado exitosamente.",
                false
              );
              dialogRef.afterClosed().subscribe((response) => {
                this.getData();
              });
            } else {
              this.modelGeneric.onModelerror(
                true, //disableClose
                this.translateService.instant(
                  "PAGES.EVENTS_DELETE.ERRORS.RESPONSE"
                ), //message
                false, // cancelButton
                this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
                true, // info
                false //imagen info
              );
            }
          });
      }
    });
  }
}
