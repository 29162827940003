import { Pipe } from '@angular/core';

@Pipe({
    name: 'durationDashboard',
})

export class DurationDashboard {
    constructor() {}

    transform(sec: number) {
        const hours = Math.floor(sec / (60 * 60));

        const divisor_for_minutes = sec % (60 * 60);
        const minutes = Math.floor(divisor_for_minutes / 60);
    
        const divisor_for_seconds = divisor_for_minutes % 60;
        const seconds = Math.ceil(divisor_for_seconds);
    
        const obj = {
            "h": hours || 0,
            "m": minutes || 0,
            "s": seconds || 0
        };

        console.log('ESTE ES EL obj obj ', obj);

        if (obj.h > 24) {
            console.log('SI ES MAYOR A 24 ', obj.h);

            let dias: any = Math.floor(obj.h / 24)
            if (dias < 10) {
                dias = '0' + dias.toString()
            }
            let hrs: any = Math.floor(obj.h % 24)
            if (hrs < 10) {
                hrs = '0' + dias.toString()
            }
            return `${dias} días ${hrs} hrs`;
        }
        
        else if (obj.h > 0){
            return `${obj.h} hrs ${obj.m} min`;
        }
        else if (obj.m > 0) {
            return `${obj.m} min ${obj.s} seg`;

        }
        else{
            return `${obj.s} seg`;
        }
    }
}