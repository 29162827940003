import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@app/core/auth.service";
import { UtilsService } from "@app/core/helper/utils.helper";
import { StorageService } from "@app/core/storage.service";
import { UserService } from "@app/core/user.service";
import { CustomersService } from "@app/customers.service";
import { ModalGenericService } from "@app/services/modal-generic.service";
import { ProgramingService } from "@app/services/programing.service";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import "moment/locale/es";

import { NgxSpinnerService } from "ngx-spinner";
import * as XLSX from "xlsx";
const { read, write, utils } = XLSX;
type AOA = any[][];
@Component({
  selector: "app-configuration-catalogs",
  templateUrl: "./configuration-catalogs.component.html",
  styleUrls: ["./configuration-catalogs.component.scss"],
})
export class ConfigurationCatalogsComponent implements OnInit {
  dataxls: AOA = [];
  dataxlsMat: AOA = [];

  lastUpdatedCity: any;
  lastUpdatedRegistration: any;

  constructor(
    public storageService: StorageService,
    private spinner: NgxSpinnerService,
    public utilsService: UtilsService,
    public userService: UserService,
    public translateService: TranslateService,
    public customerService: CustomersService,
    private modalGeneric: ModalGenericService,
    public authService: AuthService
  ) {
    userService.customerDB = authService.customerDB;
    userService.customerSelected = authService.customerSelected;
    moment.locale("es");
  }

  ngOnInit() {
    this.getUpdatedInfo();
  }

  async getUpdatedInfo() {
    this.userService.getCatalogInfo("cities").then((res) => {
      this.lastUpdatedCity = moment(res.updated_at.toDate());
    });

    this.userService.getCatalogInfo("registrations").then((res) => {
      this.lastUpdatedRegistration = moment(res.updated_at.toDate());
    });
  }

  onFileChangeCity(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.dataxls = <AOA>XLSX.utils.sheet_to_json(ws, { header: 2 });
      console.log("this.dataxls", this.dataxls);
      if (this.dataxls.length > 0) {
        this.spinner.show();
        this.dataxls.map((a) => {
          this.renameKey(a, "Code", "code");
          this.renameKey(a, "Name", "name");
        });

        let send = {
          data: this.dataxls,
          updated_at: new Date(),
        };

        this.userService
          .saveCatalogs("cities", send)
          .then((res) => {
            this.spinner.hide();
            this.sendModelSuccess("Carga exitosa");
          })
          .catch((err) => {
            console.log(err);
            this.spinner.hide();
            this.sendModelError("Ocurrio un error al cargar el archivo");
          });
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  onFileChangeMat(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.dataxlsMat = <AOA>XLSX.utils.sheet_to_json(ws, { header: 2 });
      console.log(this.dataxlsMat);

      if (this.dataxlsMat.length > 0) {
        this.spinner.show();

        this.dataxlsMat.map((a) => {
          this.renameKey(a, "Matrícula", "registration");
          this.renameKey(a, "Código Cliente", "code");
          this.renameKey(a, "Nombre Cliente", "client");
          this.renameKey(a, "Equipo", "equipment");
        });

        let send = {
          data: this.dataxlsMat,
          updated_at: new Date(),
        };

        this.userService
          .saveCatalogs("registrations", send)
          .then((res) => {
            this.spinner.hide();
            this.sendModelSuccess("Carga exitosa");
          })
          .catch((err) => {
            console.log(err);
            this.spinner.hide();
            this.sendModelError("Ocurrio un error al cargar el archivo");
          });
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  sendModelError(msg: string) {
    this.spinner.hide();
    this.modalGeneric.onModelerror(
      false, //disableClose
      msg, //message
      false, // cancelButton
      this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
      true, // info
      false //imagen info
    );
  }

  sendModelSuccess(msg: string) {
    this.spinner.hide();
    this.modalGeneric.onModelexito(
      false, //disableClose
      msg, //message
      false, // cancelButton
      this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
      true, // info
      false //imagen info
    );
  }

  renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
}
