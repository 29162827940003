import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AuthService } from '@app/core/auth.service';
import { StorageService } from '@app/core/storage.service';
import * as moment from 'moment';

export interface DialogData {
  paramName: string;
}

@Component({
  selector: 'app-download-xlsx',
  templateUrl: './download-xlsx.component.html',
  styleUrls: ['./download-xlsx.component.scss']
})
export class DownloadXlsxComponent implements OnInit {
  
  name_event: string;
  date_ini: string = '';
  date_end: string = '';
  showError = false;
  min: string;
  max: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public storageService: StorageService,
    public authService: AuthService
  ) {

    console.log('data passed in is:', this.data['event']);
    this.name_event = this.data['event'].name;

   }

  ngOnInit() {
  }

  closeDialog(){
    this.dialog.closeAll();
  }

  changeInit(){
    this.date_end = this.date_ini;
    this.min = moment(this.date_ini).format('YYYY-MM-DD');
    this.max = moment(this.min).add(2, 'M').format('YYYY-MM-DD');
    this.showError = false;
  }

  donwloadXlsx(){
    if(this.date_ini == '' || this.date_end == ''){
      this.showError = true;
    }else{
      this.showError = false;

      let ini = moment(this.date_ini).format('YYYY-MM-DD');
      let end = moment(this.date_end).format('YYYY-MM-DD');

      if (this.storageService.getCurrentDB() != '') {
        const urlActividades = this.authService.customerSelected.url_excel_event + '&event=' + this.data['event'].uid + '&start_date=' + ini +'&end_date=' + end;
        window.open(urlActividades, 'blank');
      }

    }
  }

}
