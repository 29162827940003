import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { formatDate } from "@angular/common";
import { StorageService } from "../../core/storage.service";
import { Router } from "@angular/router";
import {
  DateRangePickerComponent,
  IDateRangePickerOptions,
} from "ngx-daterange";
import * as moment from "moment";
import "moment/locale/es";

import * as momentNs from "moment";
import { PermissionService } from "../../core/permission.service";
momentNs.locale("es");

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements AfterViewInit {
  @Input() type: string = "";
  @Input() btn_active = "TODAY";
  @Input() totalUserslits = 0;

  @Output() setFilterBy = new EventEmitter<string>();
  @Output() type1_select = new EventEmitter<string>();
  @Output() goDateRange = new EventEmitter();
  @Output() delete_action = new EventEmitter();
  @Output() set_max_action = new EventEmitter();
  @Output() period = new EventEmitter<string>();

  //@ViewChild('myDateRange') myDateRange: ElementRef;
  @ViewChild("myDateRange") myDateRange: DateRangePickerComponent;

  today = formatDate(new Date(), "yyyy-MM-dd", "en");

  rangeOptions: IDateRangePickerOptions = {
    singleCalendar: false,
    format: "yyyy-MM-DD",
    displayFormat: "DD-MM-yyyy",
    position: "right",
    autoApply: true,
  };

  isShown: boolean = false;

  get currentCustomer() {
    return this.storageService.getCurrentDB();
  }
  get showOptionForSuperAdmin() {
    let dataConfig = JSON.parse(this.storageService.getDetailDataCurrentUser());
    return dataConfig !== "" && dataConfig !== undefined
      ? dataConfig.profile === "SUPERADMIN"
      : false;
  }
  get addMoreUsers() {
    let dataConfig = JSON.parse(
      this.storageService.getCurrentSettingsAdminWeb()
    );
    return dataConfig !== "" && dataConfig !== undefined
      ? this.totalUserslits < +dataConfig.data.max_users
      : true;
  }
  constructor(
    private router: Router,
    public storageService: StorageService,
    public permisionService: PermissionService
  ) {}

  ngOnInit() {
    let btnFilterMap = this.storageService.getBtnFilterMap();
    if (btnFilterMap) {
      this.btn_active = btnFilterMap;
    }
    switch (this.btn_active) {
      case "TODAY":
        this.Today();
        break;
      case "WEEK":
        this.Week();
        break;
      case "MONTH":
        this.Month();
        break;
      case "DATES":
        this.Range();
        break;
    }
  }

  ngAfterViewInit() {
    let filtersDashboard = JSON.parse(
      this.storageService.getFiltersDashboard()
    );
    if (filtersDashboard && filtersDashboard.isSearching) {
      if (
        this.btn_active == "DATES" &&
        filtersDashboard.hasOwnProperty("startRange") &&
        filtersDashboard.hasOwnProperty("endRange")
      ) {
        this.isShown = true;
        this.myDateRange.fromDate = momentNs(filtersDashboard.startRange);
        this.myDateRange.toDate = momentNs(filtersDashboard.endRange);
        this.myDateRange.setRange();
      }
    }
  }

  Today() {
    this.btn_active = "TODAY";
    this.isShown = false;
    //this.showInfoPoint = false;
    this.type1_select.emit(this.today);
    this.period.emit("MENU.TYPE_1.TODAY");
    this.storageService.setBtnFilterMap("TODAY");
  }

  Week() {
    this.btn_active = "WEEK";
    this.isShown = false;
    this.type1_select.emit(this.LastWeek());
    this.period.emit("MENU.TYPE_1.LAST_WEEK");
    this.storageService.setBtnFilterMap("WEEK");
  }

  Month() {
    this.btn_active = "MONTH";
    this.isShown = false;
    this.type1_select.emit(this.LastMonth());
    this.period.emit("MENU.TYPE_1.LAST_MONTH");
    this.storageService.setBtnFilterMap("MONTH");
  }

  Range() {
    this.btn_active = "DATES";
    this.isShown = !this.isShown;
    console.log("~ this.isShown", this.isShown);
    if (this.isShown) {
      setTimeout(() => {
        this.getInputRange();
        const range = document.getElementById("myDateRange");
        const start: any = document.getElementsByName(
          "daterangepicker_start"
        )[0];
        const end: any = document.getElementsByName("daterangepicker_end")[0];
        console.log("~ start", start);
        console.log("~ range", range);
        start.readOnly = true;
        end.readOnly = true;
        start.placeholder = "Desde:";
        end.placeholder = "Hasta:";
      }, 250);
    }
    this.storageService.setBtnFilterMap("DATES");
  }

  getInputRange() {
    let tries = 0;
    let founded = false;
    let inputRange: any;
    do {
      inputRange = document.getElementById("dateRangePicker-" + tries);
      if (!inputRange) {
        tries++;
      } else {
        inputRange.click();
        inputRange.readOnly = true;
        founded = true;
      }
    } while (!founded);
  }

  LastWeek() {
    var today = new Date();
    var lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );
    var last = formatDate(lastWeek, "yyyy-MM-dd", "en");
    return last;
  }

  LastMonth() {
    var today = new Date();
    var lastWeek = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate()
    );
    var last = formatDate(lastWeek, "yyyy-MM-dd", "en");
    return last;
  }

  setFilterByLocal($event) {
    this.setFilterBy.emit($event.target.value);
  }

  _goDateRange($event) {
    console.log("~ myDateRange", this.myDateRange.range);

    this.goDateRange.emit($event);
  }

  btn_delete_clicked($event) {
    this.delete_action.emit(this);
  }

  btn_set_max_users() {
    this.set_max_action.emit(this);
  }
}
