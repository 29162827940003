import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserService } from "../../core/user.service";
import { AuthService } from "../../core/auth.service";
import { StorageService } from "../../core/storage.service";
import { ActivatedRoute } from "@angular/router";
import { UtilsService } from "../../core/helper/utils.helper";
import { TranslateService } from "@ngx-translate/core";
import { SettingsAdminwebService } from "../../core/settings-adminweb.service";
import { PermissionService } from "../../core/permission.service";
import { CustomersService } from "../../customers.service";
import { NgxSpinnerService } from "ngx-spinner";
import { EquipmentService } from "../../core/equipment.service";

@Component({
  selector: "app-equipment-detail",
  templateUrl: "./equipment-detail.component.html",
  styleUrls: ["./equipment-detail.component.scss"],
})
export class EquipmentDetailComponent implements OnInit {
  get currentProgramming() {
    return this.storageService.getCurrentDB();
  }
  public uidEquipment: string = "";
  equipment = {};

  equipmentItems = [];

  @ViewChild("current_page") current_page;
  constructor(
    public userService: UserService,
    public authService: AuthService,
    public storageService: StorageService,
    private route: ActivatedRoute,
    public utilsService: UtilsService,
    public translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public settingsService: SettingsAdminwebService,
    public permisionService: PermissionService,
    private cd: ChangeDetectorRef,
    public equipmentService: EquipmentService,
    public customerService: CustomersService
  ) {
    customerService.customerDB = authService.customerDB;
    customerService.customerSelected = authService.customerSelected;
    this.uidEquipment = this.route.snapshot.paramMap.get("uid");
  }

  ngOnInit() {
    this.spinner.show();
    this.equipmentService
      .getEquipment(this.uidEquipment)
      .subscribe((equipment) => {
        this.equipment = equipment.data;
        console.log("Equipment", equipment);

        this.equipmentService
          .getAnswerEquipment(equipment.data.equipment_answer_id)
          .subscribe((answers) => {
            console.log("Answers", answers);
            if (answers.data.items.length > 0) {
              answers.data.items.forEach((it) => {
                this.equipmentItems.push(it);
              });
              console.log("LENGTH EQUIP", this.equipmentItems.length);
              if (this.equipmentItems.length > 2) {
                if (this.equipmentItems[2].answer !== "") {
                  this.customerService
                    .getCustomer({ id: this.equipmentItems[2].answer })
                    .subscribe((customer) => {
                      if (this.equipmentItems[2].text === "Cliente") {
                        this.equipmentItems[2].answer = customer.data.name;
                      }
                    });
                } else {
                  if (this.equipmentItems[2].text === "Cliente") {
                    this.equipmentItems[2].answer = "Cliente no asignado";
                  }
                }
              }

              this.spinner.hide();
            }
          });
      });

    this.cd.detectChanges();
  }
}
