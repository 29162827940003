import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, from, throwError, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { Http } from '@angular/http';

import 'rxjs/add/operator/toPromise';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { ordenesObj } from '../models/order';
import { ItemEvent } from '../models/evidence';
import { Router } from '@angular/router';
import { ConfigCustomer } from '../models/customer';
import {environment} from "../../environments/environment";


@Injectable()
export class FormsService {

  public customerDB: firebase.database.Database = null;
  public customerSelected: ConfigCustomer = null;

  customer_route = '';
  api_customer_key = '';
  constructor (
    public http: HttpClient,
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private router: Router
  ) {
      this.api_customer_key = sessionStorage.getItem('api_customer_key');
  }

  getForm(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/getForm';
    return this.http.post(url, body, { headers });
    
  }

  getConfigITems(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/getConfigITems';
    console.log('url ITEMS CONFIG ' + url);
    return this.http.post(url, body, { headers });
    
  }


  getFormsList(): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/getAllForms';
    return this.http.get(url, { headers });
  }

  cloneQuestionary(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/cloneQuestionaryById';
    return this.http.post(url, body, { headers });
  }

  getSettings(): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/getSettings';
    return this.http.get(url, { headers });
  }

  getQuestionaryById(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/getQuestionaryById';
    return this.http.post(url, body, { headers });
    
  }

  updateFormDisabled(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + '/changeStatusForm';
    return this.http.put(url, body, { headers });
  }

  saveForm(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
    });
    const url = environment.urlEndpointMaster + (!body.id ? '/createForm' : '/updateForm');
    return this.http.post(url, body, { headers });
  }

  validateApiElement(body: any, header:any, url: string){
    const headers = new HttpHeaders(header);
    return this.http.post(url, body, { headers }); 
  }

  deleteForm(body: any): Observable<any> {     
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': environment.headerAuthorization,
         'api-customer-key': this.api_customer_key
     });
    const url = environment.urlEndpointMaster + '/deleteForm';
    return this.http.post(url, body, { headers }); 
  }

    getExcelFile(body: any): Observable<any> {
        const headers = new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key
      });
      const url = environment.urlEndpointMaster + '/getExcelFile';
      return this.http.post(url, body, { headers });
    }
  
}
