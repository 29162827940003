import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { StorageService } from '../core/storage.service';
import { Router } from '@angular/router';
import { UtilsService } from '../core/helper/utils.helper';
import { TranslateService } from '@ngx-translate/core';
import { SettingsAdminwebService } from '../core/settings-adminweb.service';
import { PermissionService } from '../core/permission.service';
import { EquipmentTableComponent } from './equipment-table/equipment-table.component';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentTemplateComponent } from './equipment-template/equipment-template.component';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgramingService } from '../services/programing.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
})
export class EquipmentComponent implements OnInit {
  public current_component: any;

  get currentProgramming() {
    return this.storageService.getCurrentDB();
  }

  @ViewChild('current_page') current_page;
  constructor(
    public userService: UserService,
    public authService: AuthService,
    public storageService: StorageService,
    private router: Router,
    private dialog: MatDialog,
    public utilsService: UtilsService,
    public translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public settingsService: SettingsAdminwebService,
    public permisionService: PermissionService,
    private programingservice: ProgramingService
  ) {}

  ngOnInit() {}

  salir() {
    this.authService
      .doLogout()
      .then(() => {
        if (this.storageService.getCurrentDB() != '') {
          const ruta = '/' + this.storageService.getCurrentDB() + '/login';
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      })
      .catch((err) => {
        // console.error('Error LogOut -> ' + err);
        if (this.storageService.getCurrentDB() != '') {
          const ruta = '/' + this.storageService.getCurrentDB() + '/login';
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      });
  }
  onActivate(componentRef) {
    this.current_component = null;
    console.log(
      'componentRef',
      componentRef instanceof EquipmentTableComponent
    );
    if (componentRef instanceof EquipmentTableComponent) {
      this.current_component = componentRef;
    }
  }

  add() {
    const dialogRef = this.dialog
      .open(EquipmentTemplateComponent, {
        data: {
          id: 0,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp !== undefined) {
          if (resp['type'] === 'create') {
            const ruta =
              '/' + this.storageService.getCurrentDB() + '/equipments/add';
            this.router.navigate([ruta]);
          } else if (resp['type'] === 'template') {
            //selected
            const ruta =
              '/' +
              this.storageService.getCurrentDB() +
              `/equipments/add/${resp['selected']}`;
            this.router.navigate([ruta]);
          }
        }
      });
  }
}
