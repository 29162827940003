import { Pipe } from '@angular/core';
import * as moment from 'moment';


@Pipe({
    name: 'totalDurationDashboard',
})

export class TotalDurationDashboard {
    constructor() {}

    transform(start_date: string, end_date: string) {
        const start_moment = moment(start_date), end_moment = moment(end_date);
        if (!end_moment.isValid() || !start_moment.isValid()) return "0 min";

        let total_minutes = 78; // end_moment.diff(start_moment, 'minutes');
        
        const secs = end_moment.diff(start_moment, 'seconds');
        const days = Math.floor(secs / 86400);

        const divisor_for_hours = secs % 86400;
        const hours = Math.floor(divisor_for_hours / (60 * 60));

        const divisor_for_minutes = secs % (60 * 60);
        const minutes = Math.floor(divisor_for_minutes / 60);

        const divisor_for_seconds = divisor_for_minutes % 60;
        const seconds = Math.ceil(divisor_for_seconds);

        const obj = {
            "d": days || 0,
            "h": hours || 0,
            "m": minutes || 0,
            "s": seconds || 0
        };

        return (obj.d > 0 ? `${obj.d} d `:'') + (obj.h > 0 ? `${obj.h} h `:'') + (obj.m > 0 ? `${obj.m} m ` : '') + (obj.s > 0 ? `${obj.s} s` : '');
    }
}

interface ordenesObj {
    id: string;
    order_number: string;
    customer_name: string;
    order_name: string;  
    address: string;
    users_all: boolean;
    start_date: string,
    end_date: string,
    latitude: string,
    longitude: string,
    users: any[];
    status: string; //1 -> pendiente, 2 -> proceso, 3 -> completado 
    user_uid: string;
    departamento?: string;
    sucursal?: string;
    tipo?: string;
    user_name: string;
    geolocation: any;
    address_checkIn?: string;
    evidence_first_id: string;
    description_photo_evidence: string;
}