import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../core/events.service';
import { AuthService } from '../../core/auth.service';
import { UtilsService } from '../../core/helper/utils.helper';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../core/storage.service';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { Router } from '@angular/router';
import { PermissionService } from '../../core/permission.service';
import { MatDialog, MatOption, MatSlideToggleChange } from '@angular/material';
import { DownloadXlsxComponent } from '../download-xlsx/download-xlsx.component';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit {
  public events_list: any = [];
  public events_by_page: number = 10;
  public events_current_page: number = 1;

  public events_filter: string = '';

  get getEventsList() {
    return (
      this.events_list &&
      this.events_list.filter((f) => {
        return this.utilsService.search(f, 'name', this.events_filter);
      })
    );
  }

  round(value) {
    return Math.ceil(value);
  }

  updateCurrentPage(value) {
    try {
      if (this.events_by_page !== 1) {
        if (value == 1) {
          this.events_current_page = 1;
        }
      }
    } catch (err) {}
  }

  get currentCustomer() {
    return this.storageService.getCurrentDB();
  }

  constructor(
    public eventsService: EventsService,
    public authService: AuthService,
    public utilsService: UtilsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public translateService: TranslateService,
    public storageService: StorageService,
    public modelGeneric: ModalGenericService,
    private router: Router,
    public permisionService: PermissionService,
    public dialog: MatDialog
  ) {
    if (authService.customerDB != null) {
      eventsService.customerDB = authService.customerDB;
      eventsService.customerSelected = authService.customerSelected;
      this.spinner.show();
      eventsService.getEventsList().subscribe((response) => {
        this.events_list = response.data.map((r) => {
          return { ...r, ...{ selected: false } };
        });
        this.spinner.hide();
      });
    }
  }

  ngOnInit() {}

  pageChanged($event) {
    this.events_current_page = $event;
  }

  updateEventDisabled(event) {
    this.spinner.show();
    console.log(event.active);
    event.active = !event.active;
    console.log(event);
    console.log(event.uid);
    console.log(event.active);

    this.eventsService
      .updateEventDisabled({
        uid: event.uid,
        active: event.active,
      })
      .subscribe((response) => {
        this.spinner.hide();
        console.log('RESPONSE DE ' + JSON.stringify(response));
      });
  }

  deleteEvent(event) {
    const dialogRef = this.modelGeneric.onModelconfirmacion(
      true,
      '¿Estás seguro de eliminar este tipo de actividad?',
      true
    );

    dialogRef.afterClosed().subscribe((data) => {
      if (data == 'aceptar') {
        this.spinner.show();
        // this.modelGeneric.onModelexito(true, 'El tipo de actividad ha sido eliminado exitosamente.', false);
        this.eventsService
          .deleteEvent({ id: event.uid })
          .subscribe((response) => {
            this.spinner.hide();
            if (response.status === 'ok') {
              const dialogRef = this.modelGeneric.onModelexito(
                true,
                'El tipo de actividad ha sido eliminado exitosamente.',
                false
              );
              dialogRef.afterClosed().subscribe((response) => {
                this.spinner.show();
                this.eventsService.getEventsList().subscribe((response) => {
                  this.events_list = response.data.map((r) => {
                    return { ...r, ...{ selected: false } };
                  });
                  this.spinner.hide();
                });
              });
            } else {
              this.modelGeneric.onModelerror(
                true, //disableClose
                this.translateService.instant(
                  'PAGES.EVENTS_DELETE.ERRORS.RESPONSE'
                ), //message
                false, // cancelButton
                this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                true, // info
                false //imagen info
              );
            }
          });
      }
    });
  }

  editEventLList(item) {
    console.log('PARAMETRO A ENVIAR ' + item);
    const ruta = '/' + this.authService.customer_route + '/events-list/add';
    this.router.navigate([ruta, { id: item }]);
  }

  downloadXlsx(event){
    console.log('ACTIVIDAD A XLSX ', event);

    const dialogRef = this.dialog.open(DownloadXlsxComponent, {
      height: 'auto',
      width: '50%',
      data: {
        event,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log('Dialog XLSX');
      console.log(data);

      console.log(
        'CONFIRMER '
      );
    });

  }

}
