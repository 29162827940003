import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UserService } from "../../core/user.service";
import { AuthService } from "../../core/auth.service";
import { StorageService } from "../../core/storage.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UtilsService } from "../../core/helper/utils.helper";
import { TranslateService } from "@ngx-translate/core";
import { SettingsAdminwebService } from "../../core/settings-adminweb.service";
import { PermissionService } from "../../core/permission.service";
import { MatDialog } from "@angular/material/dialog";
import { EquipmentTemplateFormAddComponent } from "./../equipment-template-form-add/equipment-template-form-add.component";
import { EquipmentConfirmCreateTemplateComponent } from "./../equipment-confirm-create-template/equipment-confirm-create-template.component";
import { take } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { EquipmentService } from "../../core/equipment.service";
import { CustomersService } from "../../customers.service";
import { ModalImageComponent } from "../../user-list/modal-image/modal-image.component";
import * as moment from "moment";
import { programmingObj } from "@app/models/programming";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-equipment-add",
  templateUrl: "./equipment-add.component.html",
  styleUrls: ["./equipment-add.component.scss"],
})
export class EquipmentAddComponent implements OnInit {
  customerSelect: any;
  get currentProgramming() {
    return this.storageService.getCurrentDB();
  }

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  reloadPreview: boolean = true;
  base64;
  isDisableds: boolean = false;
  validationIdExist: boolean = false;

  public uidTemplate: string = "";
  public uidEquipment: string = "";
  typeAction: string = "add";
  equipment = {};

  halfNumberItems = 0;
  equipmentItems = [];
  equipmentItemsAnswers = [];
  equipmentTemplateItems = [];

  public customersList: any[];
  programming: programmingObj = null;
  customSel: string[] = [];

  userEmail = "";
  userUid = "";

  scheduled_client_uuid: string = "Cliente";
  form: FormGroup;
  formulario = {
    scheduled_client_uuid: null,
  };

  @ViewChild("current_page") current_page;
  constructor(
    public userService: UserService,
    public authService: AuthService,
    public storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public utilsService: UtilsService,
    public translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public settingsService: SettingsAdminwebService,
    public permisionService: PermissionService,
    private cd: ChangeDetectorRef,
    public equipmentService: EquipmentService,
    public customerService: CustomersService
  ) {
    customerService.customerDB = authService.customerDB;
    customerService.customerSelected = authService.customerSelected;
    this.spinner.show();
    this.uidTemplate = this.route.snapshot.paramMap.get("uid");
    this.uidEquipment = this.route.snapshot.paramMap.get("uidEquipment");
    this.equipmentItemsAnswers = [];
    this.equipmentTemplateItems = [];
  }

  get getFormsList() {
    return (
      this.customersList &&
      this.customersList
        .filter((f) => {
          return this.utilsService.search(f, "name", "");
        })
        .filter((f) => {
          return f.status !== "D";
        })
        .sort(function (a, b) {
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          return 0;
        })
        .map((u) => Object({ id: u.id, text: u.name }))
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  async ngOnInit() {
    this.userService.getCurrentUser().then((resp) => {
      console.log("RESP", resp);
      this.userEmail = resp.email;
      this.userUid = resp.uid;
    });

    this.form = new FormGroup({
      scheduled_client_uuid: new FormControl(
        this.formulario.scheduled_client_uuid
      ),
    });

    await this.customerService.getCustomersList().subscribe(
      (response) => {
        if (response) {
          this.customersList = response.data.map((r) => {
            return { ...r, ...{ selected: false } };
          });

          console.log("customSel 2", moment(), this.programming);
          if (this.programming != null && this.programming != undefined) {
            let scheduled_client_uuid = this.getFormsListID(
              this.programming.scheduled_client_uuid
            );
            this.customSel = scheduled_client_uuid;

            console.log(
              "scheduled_client_uuid scheduled_client_uuid ",
              scheduled_client_uuid
            );

            this.form.controls.scheduled_client_uuid.setValue(
              scheduled_client_uuid
            );
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );

    if (this.uidEquipment === null && this.uidTemplate === null) {
      this.equipmentItems.push({
        answer: "",
        showError: false,
        dropdown_answers: [],
        input_type: "text",
        text: "ID (Código QR o barras)",
        required: true,
        show_message: false,
        message: "Tu respuesta es requerida.",
        type: "input",
      });

      this.equipmentItems.push({
        answer: "",
        showError: false,
        dropdown_answers: [],
        input_type: "text",
        text: "Nombre del equipo",
        required: true,
        show_message: false,
        message: "Tu respuesta es requerida.",
        type: "input",
      });

      this.equipmentItems.push({
        answer: "",
        showError: false,
        dropdown_answers: [],
        input_type: "text",
        text: "Cliente",
        required: false,
        show_message: false,
        message: "Tu respuesta es requerida.",
        type: "dropdown",
      });
      this.spinner.hide();

      this.typeAction = "add";
    }

    if (this.uidTemplate !== "" && this.uidTemplate !== null) {
      this.equipmentItems.push({
        answer: "",
        showError: false,
        dropdown_answers: [],
        input_type: "text",
        text: "ID (Código QR o barras)",
        required: true,
        show_message: false,
        message: "Tu respuesta es requerida.",
        type: "input",
      });

      this.equipmentItems.push({
        answer: "",
        showError: false,
        dropdown_answers: [],
        input_type: "text",
        text: "Nombre del equipo",
        required: true,
        show_message: false,
        message: "Tu respuesta es requerida.",
        type: "input",
      });

      this.equipmentItems.push({
        answer: "",
        showError: false,
        dropdown_answers: [],
        input_type: "text",
        text: "Cliente",
        required: false,
        show_message: false,
        message: "Tu respuesta es requerida.",
        type: "dropdown",
      });

      this.equipmentService
        .getTemplateEquipment(this.uidTemplate)
        .subscribe((template) => {
          console.log(template);

          if (template.data.items.length > 0) {
            console.log("GG", template.data.items);
            template.data.items.forEach((it) => {
              it["answer"] = "";
              this.equipmentItems.push(it);
            });
            this.equipmentItemsAnswers = template.data.items;
            this.equipmentTemplateItems = template.data.items;
          }
          this.spinner.hide();
        });

      this.typeAction = "add";
    }

    if (this.uidEquipment !== "" && this.uidEquipment !== null) {
      this.typeAction = "edit";
      this.equipmentService
        .getEquipment(this.uidEquipment)
        .subscribe((equipment) => {
          this.equipment = equipment.data;
          if (equipment.data.photo) {
            this.previewUrl = equipment.data.photo;
          }

          console.log("Equipment", equipment);

          this.equipmentService
            .getAnswerEquipment(equipment.data.equipment_answer_id)
            .subscribe(async (answers) => {
              console.log("Answers", answers);
              if (answers.data.items.length > 0) {
                console.log("GG", answers.data.items);
                answers.data.items.forEach((it) => {
                  this.equipmentItems.push(it);
                });

                console.log(
                  "AQUI EL VALOR ANSWER ",
                  answers.data.items[2]["answer"]
                );
                if (
                  answers.data.items[2]["answer"] != "" &&
                  answers.data.items[2]["answer"] != undefined
                ) {
                  let customer_ = await this.getFormsListID(
                    answers.data.items[2]["answer"]
                  );
                  this.form.controls.scheduled_client_uuid.setValue([
                    { id: customer_[0]["id"], text: customer_[0]["text"] },
                  ]);
                }

                this.equipmentItemsAnswers = answers.data.items;
                this.equipmentTemplateItems = answers.data.items;
              }
              this.spinner.hide();
            });
        });
    }

    this.cd.detectChanges();
  }

  onChangeCustomer(event) {
    let event_find = event[0] != undefined ? event[0] : event;

    if (event_find) {
      this.customerSelect = event_find.id;
      this.equipmentItems[2]["answer"] = this.customerSelect;
    }

    console.log("FILTER customerSelect ", this.customerSelect);
  }

  getFormsListID(id) {
    console.log("getFormsListID>>>>>>>", id);

    return (
      this.customersList &&
      this.customersList
        .filter((f) => {
          return this.utilsService.search(f, "id", id);
        })
        .filter((f) => {
          return f.status !== "D";
        })
        .map((u) => Object({ id: u.id, text: u.name }))
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  removeItem(item) {
    console.log();
    this.equipmentItems = this.equipmentItems.filter((value) => value !== item);
    console.log("Removed", this.equipmentItems);
    this.cd.detectChanges();
  }

  async onChange(value, index) {
    var validation = false;

    if (value === "" && index !== 2) {
      validation = true;
    }

    if (index === 0) {
      this.validationIdExist = false;
      await this.equipmentService
        .getEquipments({ validation: value })
        .subscribe((eq) => {
          if (eq.data.length > 0) {
            this.validationIdExist = true;
          }
        });
    }

    this.equipmentItems[index]["answer"] = value;
    this.equipmentItems[index]["showError"] = validation;
    this.cd.detectChanges();
  }

  onChangeNumeric(value, index) {
    var validation = false;

    if (value.includes(".")) {
      let ls = value.split(".");

      if (ls[0].length > this.equipmentItems[index]["dropdown_answers"][0]) {
        validation = true;
      }

      if (ls[1].length > this.equipmentItems[index]["dropdown_answers"][1]) {
        validation = true;
      }
    } else {
      if (value.length > this.equipmentItems[index]["dropdown_answers"][0]) {
        validation = true;
      }
    }

    if (value === "") {
      validation = true;
    }

    this.equipmentItems[index]["answer"] = value;
    this.equipmentItems[index]["showError"] = validation;

    console.log("Updated", this.equipmentItems);
    this.cd.detectChanges();
  }

  add() {
    const dialogRef = this.dialog
      .open(EquipmentTemplateFormAddComponent, {
        height: "80%",
        width: "80%",
        data: {
          id: "",
          type: "",
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp.type === "add") {
          console.log(resp);
          resp.items.questions.map((q) => {
            q["answer"] = "";
            q["showError"] = false;
            this.equipmentItems.push(q);
            this.equipmentItemsAnswers.push(q);
            this.equipmentTemplateItems.push(q);
          });

          this.cd.detectChanges();
        }
      });
  }

  async checkItems() {
    let veri = true;
    await this.equipmentService
      .getEquipments({ validation: this.equipmentItems[0]["answer"] })
      .subscribe((eq) => {
        if (eq.data.length > 0) {
          this.validationIdExist = true;
        }
      });

    this.equipmentItems.forEach((it, index, arr) => {
      if (it.type === "photo") {
        if (it.dropdown_answers.length === 0) {
          this.equipmentItems[index]["showError"] = true;
          veri = false;
        } else {
          this.equipmentItems[index]["showError"] = false;
          veri = true;
        }
      } else {
        if (it.answer === "" && index !== 2) {
          this.equipmentItems[index]["showError"] = true;
          veri = false;
        }
      }
    });
    this.cd.detectChanges();
    this.confirmProccess();
  }

  getImage() {
    const dialogRef = this.dialog
      .open(ModalImageComponent, {
        data: {
          cutout_shape: "square",
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp.button == "aceptar") {
          this.previewUrl = resp.imageB64;
          this.base64 = resp.imageB64;
          //this.avatar = '';
        }
      });

    // if (!this.isDisableds) {
    //   const input = document.getElementById('file');
    //   input.click();
    // }
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    console.log("this.fileData>>>>>>>>>>>>>>>>>>>", this.fileData);
    this.preview();
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.base64 = reader.result;
    };
  }

  applyReload() {
    this.reloadPreview = false;
    this.previewUrl = "";
    setTimeout(() => {
      this.reloadPreview = true;
    }, 100);
  }

  uploadFileItem(event: any, index: number) {
    console.log("index", index);
    const dialogRef = this.dialog
      .open(ModalImageComponent, {
        data: {
          cutout_shape: "square",
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp.button == "aceptar") {
          const equipmentAnswer = this.equipmentItems[index];
          const equipmentItemAnswersAux = this.equipmentItemsAnswers[index - 3];
          const equipmentTemplateItemAux =
            this.equipmentTemplateItems[index - 3];
          console.log("equipmentAnswer", equipmentAnswer);

          const dropdown_answers = equipmentAnswer["dropdown_answers"];
          dropdown_answers.push(resp.imageB64);

          equipmentAnswer["dropdown_answers"] = dropdown_answers;
          equipmentItemAnswersAux["dropdown_answers"] = dropdown_answers;
          equipmentTemplateItemAux["dropdown_answers"] = dropdown_answers;

          //this.previewUrl = resp.imageB64;
          //this.base64 = resp.imageB64;
          //this.avatar = '';
        }
      });
  }

  deleteFileItem(event: any, equipmentItemsIndex: number, index: number) {
    console.log("equipmentItemsIndex", equipmentItemsIndex);
    console.log("index", index);
    const equipmentAnswer = this.equipmentItems[equipmentItemsIndex];
    const equipmentItemAnswersAux =
      this.equipmentItemsAnswers[equipmentItemsIndex - 3];
    const equipmentTemplateItemAux =
      this.equipmentTemplateItems[equipmentItemsIndex - 3];

    const dropdown_answers = equipmentAnswer["dropdown_answers"];
    dropdown_answers.splice(index, 1);
    equipmentAnswer["dropdown_answers"] = dropdown_answers;
    equipmentItemAnswersAux["dropdown_answers"] = dropdown_answers;
    equipmentTemplateItemAux["dropdown_answers"] = dropdown_answers;
    console.log("equipmentAnswer", equipmentAnswer);
    this.cd.detectChanges();
  }

  confirmProccess() {
    let veri = true;

    this.equipmentItems.forEach((it) => {
      if (it.showError) {
        veri = false;
      }
    });

    if (veri && !this.validationIdExist) {
      //const equipmentTemplateItemsAux ;
      console.log("Type Action", this.typeAction);

      if (
        this.typeAction == "add" ||
        (this.typeAction == "edit" &&
          this.equipment["equipment_template_id"] == "")
      ) {
        const dialogRef = this.dialog
          .open(EquipmentConfirmCreateTemplateComponent, {
            data: {
              items: this.equipmentItems,
              template: this.equipmentTemplateItems,
              typeAction: this.typeAction,
              uidEquipment: this.uidEquipment,
              uidTemplate: this.uidTemplate,
              equipment: this.equipment,
              base64: this.base64,
              previewUrl: this.previewUrl,
            },
          })
          .afterClosed()
          .pipe(take(1))
          .subscribe((resp: any) => {
            this.dialog.closeAll();
          });
      } else if (this.typeAction == "edit") {
        this.spinner.show();
        const dataEquipmentAnswer = {
          items: this.equipmentItems,
          firebase_id: this.equipment["equipment_answer_id"],
        };
        this.equipmentService
          .editAnswerEquipment(dataEquipmentAnswer)
          .subscribe(async (answerUpdate) => {
            let data = {
              active: true,
              firebase_id: this.equipment["firebase_id"],
              date_modified: moment().format("X"),
              qr: this.equipmentItems[0]["answer"],
              name: this.equipmentItems[1]["answer"],
              customer_id: this.equipmentItems[2]["answer"],
              user_email_created: this.userEmail,
              equipment_template_id: this.equipment["equipment_template_id"],
              user_id_created: this.userUid,
              deleted: false,
            };

            if (this.base64 !== undefined) {
              const downloadUrl = await this.userService.uploadPhotoReference(
                moment().format("DD-MM-YYYY_hh:mm:ss"),
                this.base64,
                "equipments"
              );
              data["photo"] = downloadUrl;
            } else {
              data["photo"] = this.previewUrl;
            }

            this.equipmentService
              .editEquipment(data)
              .subscribe((equipmentUpdate) => {
                this.spinner.hide();
                const ruta =
                  "/" + this.storageService.getCurrentDB() + "/equipments";
                this.router.navigate([ruta]);
              });
          });
      }
    }
  }

  cancelBtn() {
    const ruta = "/" + this.storageService.getCurrentDB() + "/equipments";
    this.router.navigate([ruta]);
  }
}
