import { Injectable } from "@angular/core";
import "rxjs/add/operator/toPromise";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase/app";
import { ConfigCustomer } from "../models/customer";
import { ConfigCustomer2 } from "../models/customer2";
import { Router } from "@angular/router";
import { Session } from "@app/models/session.model";
import { StorageService } from "./storage.service";
import { UtilsService } from "./helper/utils.helper";
import * as AngularFireStorage2 from "@angular/fire/storage";
import { BehaviorSubject, Observable } from "rxjs";
import { EncryptionDecryptionService } from "@app/services/encryption-decryption.service";
import { environment } from "../../environments/environment";
import { promise } from "selenium-webdriver";
import { resolve } from "url";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class AuthService {
  public customerDB: firebase.database.Database = null;
  public customerDBPrincipal: firebase.database.Database = null;
  public customerstorage = "";
  public generatePrincipal = 0;
  public generateCustomer = 0;
  ruta_cliente = "";
  public customerSelected: ConfigCustomer = null;
  public customerSelected2: ConfigCustomer2 = null;
  customer_route = "";
  public db: AngularFirestore;

  customerSelected$ = new BehaviorSubject<ConfigCustomer>(null);

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    public storageService: StorageService,
    utilsService: UtilsService,
    private encryptDecryptService: EncryptionDecryptionService,
    public http: HttpClient
  ) {
    if (this.router.url === "/") {
      this.customer_route = this.storageService.getCurrentDB();
      this.setDataBaseCustomer(this.customer_route);
    } else {
      var lst = this.router.url.split("/");
      this.customer_route = this.router.url.includes("PrincipalAdmin")
        ? lst[2]
        : lst[1];
      this.setDataBaseCustomer(this.customer_route);
    }
  }

  getCustomToken(custom): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: environment.headerAuthorization,
      "api-customer-key": custom,
    });
    const url = environment.urlEndpointMaster + "/CustomToken";
    return this.http.post(url, {}, { headers });
  }

  async setDataBaseCustomer(
    customer: string,
    resolve = null,
    reject = null
  ): Promise<any> {
    // nueva funcionalidad para creacion de instancia

    return await new Promise(async (resolve, reject) => {
      if (this.generatePrincipal == 0) {
        //const customerApp = firebase.initializeApp(environment.firebase,'Principal');

        const customerApp =
          firebase.apps.find((item) => item["name"] === "Principal") ||
          firebase.initializeApp(environment.firebase, "Principal");

        console.log("entro de nuevo", customerApp);

        await this.getCustomToken(customer).subscribe(
          (response: any) => {
            sessionStorage.setItem("tkns", JSON.stringify(response.token));
            let tk = this.encryptDecryptService.decryptSimpleString(
              response.token.tkn
            );
            customerApp
              .auth()
              .signInWithCustomToken(tk)
              .then((userCredential) => {
                var user = userCredential.user;
              })
              .catch((error) => {});
          },
          (error) => {
            console.log("error", error);
          }
        );
        console.log("entro de nuevo1");
        this.customerDBPrincipal = customerApp.database();
        this.generatePrincipal = 1;
      }

      if (customer == "PrincipalAdmin") {
        const customerApp_0 = firebase.initializeApp(
          environment.firebase,
          "customer"
        );
        this.customerDB = customerApp_0.database();
        sessionStorage.setItem("api_customer_key", "");
        resolve(true);
      } else {
        this.customerDBPrincipal.app
          .firestore()
          .collection("customers")
          .where("url_alias", "==", customer)
          .get()
          .then((snap) => {
            if (snap.size > 0) {
              var datos = snap.docs[0].data();

              if (datos.config_firebase.encrypt) {
                datos = this.encryptDecryptService.decryptConfigFirebase(datos);
              }
              this.customerSelected = {
                apiKey: datos["config_firebase"]["apiKey"],
                authDomain: datos["config_firebase"]["authDomain"],
                databaseURL: datos["config_firebase"]["databaseURL"],
                projectId: datos["config_firebase"]["projectId"],
                storageBucket: datos["config_firebase"]["storageBucket"],
                appId: datos["config_firebase"]["appId"],
                messagingSenderId:
                  datos["config_firebase"]["messagingSenderId"],
                url_pdf: datos["url_pdf"],
                reportExcelBigQuery: datos["reportExcelBigQuery"],
                reportDelays: datos["reportDelays"],
                url_excel_event: datos["url_excel_event"],
                url_excel_bulk:
                  datos["url_excel_bulk"] != undefined
                    ? datos["url_excel_bulk"]
                    : "https://firebasestorage.googleapis.com/v0/b/trackpoint-demos-webpoint.appspot.com/o/layout.xlsx?alt=media&token=986e192e-4ec7-4c58-bcab-9cdb0f8a0567",
                url_logo: datos["logo_url"],
                name: datos["name"],
                domain: datos["domain"],
                url_report: datos["url_report"],
                multi_tenancy: datos["multi_tenancy"] || false,
                multi_tenancy_id: datos["multi_tenancy_id"] || "",
                multi_tenancy_doc: datos["multi_tenancy_doc"] || "",
              };

              sessionStorage.setItem("api_customer_key", datos["url_alias"]);
              //console.log('Generate customer', this.generateCustomer);
              if (this.generateCustomer == 0) {
                const customerApp_0 = firebase.initializeApp(
                  this.customerSelected,
                  "customer"
                );
                this.customerDB = customerApp_0.database();
                console.log("this.customerDB ", this.customerDB);
                this.generateCustomer = 1;
                resolve(true);
              }

              if (resolve != null) {
                resolve(this.customerSelected); // Por ahora solo se utiliza en reset-password
              }

              this.customerSelected$.next(this.customerSelected);
            } else {
              var ruta = "/principal";
              this.router.navigate([ruta]);
            }
          })
          .catch((error) => {});
      }
    });
  }

  async setDataBasePermission(
    user_type: string,
    resolve = null,
    reject = null
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.customerDBPrincipal.app
        .firestore()
        .collection("group_permissions")
        .where("group", "==", user_type)
        .get()
        .then((snap) => {
          var datos = snap.docs[0].data();
          this.storageService.setPermissionGroupUser(datos.permissions);
          resolve(true);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      this.afAuth.auth.signInWithPopup(provider).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      console.log(this.customerSelected);
      if (this.customerSelected.multi_tenancy === true) {
        this.customerDB.app.auth().tenantId =
          this.customerSelected.multi_tenancy_id;
      }
      this.customerDB.app
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  doEmailExists(value) {
    return new Promise<any>((resolve, reject) => {
      this.customerDB.app
        .auth()
        .fetchSignInMethodsForEmail(value.email)
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (this.customerDB.app.auth().currentUser) {
        this.customerDB.app.auth().signOut();
        resolve(true);
      } else {
        reject();
      }
    });
  }

  public getCustomerDB() {
    var lst = this.router.url.split("/");
    var customer = this.router.url.includes("PrincipalAdmin") ? lst[2] : lst[1];
    //var customer = lst[1];
    this.customer_route = customer;
    if (!this.customerDB) {
      this.setDataBaseCustomer(customer);
    }

    return this.customerDB;
  }

  public async getAsyncCustomerDB() {
    var lst = this.router.url.split("/");
    var customer = this.router.url.includes("PrincipalAdmin") ? lst[2] : lst[1];
    this.customer_route = customer;
    if (!this.customerDB) {
      await this.setDataBaseCustomer(customer);
    }

    return this.customerDB;
  }

  public getAsyncPermissionsDB(user_type: string) {
    return new Promise((resolve, reject) => {
      console.log("aqui 1");
      this.setDataBasePermission(user_type, resolve, reject);
    });
  }

  getValidateExistCustomerMaster(body: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const url =
      environment.urlEndpointMaster + "/getValidateExistCustomerMaster";
    return this.http.post(url, body, { headers });
  }

  getTenancyParams() {
    if (this.customerSelected.multi_tenancy) {
      return this.customerDB.app
        .firestore()
        .collection("clients")
        .doc(this.customerSelected.multi_tenancy_doc);
    } else {
      return this.customerDB.app.firestore();
    }
  }

  getTermsConditions() {
    return new Promise<any>((resolve, reject) => {
      this.customerDBPrincipal.app
        .firestore()
        .collection("terms_conditions")
        .get()
        .then((snap) => {
          var datos = snap.docs[0].data();
          resolve(datos);
        });
    });
  }

  setHistoryDatesLogin(user_uid: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: environment.headerAuthorization,
      "api-customer-key": this.customer_route,
    });

    let body = {
      uid: user_uid,
    };

    const url = environment.urlEndpointMaster + "/historyDatesLoginUsers";
    return this.http.post(url, body, { headers });
  }
}
