import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth.service';
import { UtilsService } from '@app/core/helper/utils.helper';
import { PermissionService } from '@app/core/permission.service';
import { SettingsAdminwebService } from '@app/core/settings-adminweb.service';
import { StorageService } from '@app/core/storage.service';
import { UserService } from '@app/core/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboardreportlayout',
  templateUrl: './dashboardreportlayout.component.html',
  styleUrls: ['./dashboardreportlayout.component.scss']
})
export class DashboardreportlayoutComponent implements OnInit {


  @ViewChild('current_page') current_page;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public storageService: StorageService,
    private router: Router,
    public utilsService: UtilsService,
    public translateService: TranslateService,
    public settingsService: SettingsAdminwebService,
    public permisionService: PermissionService

  ) { }

  ngOnInit() {
  }

  salir() {
    this.authService.doLogout().then(() => {
      if (this.storageService.getCurrentDB() != '') {
        const ruta = '/' + this.storageService.getCurrentDB() + '/login';
        this.storageService.logout();
        this.router.navigate([ruta]);
      }
    })
      .catch((err) => {
        // console.error('Error LogOut -> ' + err);
        if (this.storageService.getCurrentDB() != '') {
          const ruta = '/' + this.storageService.getCurrentDB() + '/login';
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      });
  }

}
