import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-check-mobile',
  templateUrl: './modal-check-mobile.component.html',
  styleUrls: ['./modal-check-mobile.component.scss']
})
export class ModalCheckMobileComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public  data:  any) { }

  ngOnInit() {
  }

}
