import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from '../models/session.model';
import { FirebaseUserModel } from './user.model';
import { SettingsAdminwebService } from '@app/core/settings-adminweb.service';
import * as firebase from 'firebase';
import { UserService } from '@app/core/user.service';
import { ConfigCustomer } from '@app/models/customer';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class StorageService {
  private localStorageService;
  private currentSession: Session = null;
  public customerSelected: ConfigCustomer = null;
  private timeZoneConfiguration$ = new BehaviorSubject<any>(undefined);
  constructor(
    private router: Router,
    private settingsService: SettingsAdminwebService,
    private userService: UserService
  ) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
  }

  setCurrentSession(session: Session): void {
    this.currentSession = session;
    this.localStorageService.setItem('currentUser', JSON.stringify(session));
  }

  loadSessionData(): Session {
    var sessionStr = this.localStorageService.getItem('currentUser');
    return sessionStr ? <Session>JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): Session {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.removeItem('settingsAdminWeb');
    this.localStorageService.removeItem('currentUserDetail');
    this.localStorageService.removeItem('currentUserPermissions');
    this.localStorageService.removeItem('filtersDashboard');
    this.localStorageService.removeItem('btnFilterMap');
    //this.removeListTimZones();
    this.removeFiltersDashboardReport();
    this.currentSession = null;
  }

  getCurrentUser(): FirebaseUserModel {
    var session: Session = this.getCurrentSession();
    return session && session.user ? session.user : null;
  }

  isAuthenticated(): boolean {
    return this.getCurrentToken() != null ? true : false;
  }

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return session && session.token ? session.token : null;
  }

  getCurrentDB(): string {
    var session = this.getCurrentSession();
    var current_route = sessionStorage.getItem('api_customer_key') || '';
    return session && session.DB ? session.DB : null;
  }

  logout(): void {
    this.removeCurrentSession();
  }

  setCurrentSettingsAdminWeb(dbSelected: firebase.database.Database): void {
    /* this.settingsService.customerDB = dbSelected;
    let objectSettings = { id: '', data: {} };
    this.settingsService
      .getSettingsAdminWeb()
      .then((response) => {
        response.forEach((doc) => {
          objectSettings.id = doc.id;
          objectSettings.data = doc.data();
          this.localStorageService.setItem(
            'settingsAdminWeb',
            JSON.stringify(objectSettings)
          );
        });
      })
      .catch((error) => {
        console.log('Se detecto un error', error);
        this.localStorageService.setItem('settingsAdminWeb', '');
      }); */

    this.localStorageService.setItem('settingsAdminWeb', '');
  }

  getCurrentSettingsAdminWeb(): string {
    return this.localStorageService.getItem('settingsAdminWeb');
  }

  setDetailDataCurrentUser(
    dbSelected: firebase.database.Database
  ): Promise<any> {
    this.userService.customerDB = dbSelected;
    let dataSession = this.getCurrentSession();
    let promise = new Promise((resolve, reject) => {
      this.userService.customerSelected = this.customerSelected;
      console.log('dataSession.uid', dataSession.uid);
      this.userService
        .getDetailUser(dataSession.uid)
        .then((response) => {
          response.forEach((doc) => {
            console.log('data del usuario logeado ', doc.data());
            this.localStorageService.setItem(
              'currentUserDetail',
              JSON.stringify(doc.data())
            );
          });
          resolve(true);
        })
        .catch((error) => {
          reject();
          this.localStorageService.setItem('currentUserDetail', '');
        });
    });
    return promise;
  }

  getDetailDataCurrentUser(): string {
    return this.localStorageService.getItem('currentUserDetail');
  }

  setPermissionGroupUser(permisionss: any) {
    this.localStorageService.setItem(
      'currentUserPermissions',
      JSON.stringify(permisionss)
    );
  }
  getPermissionGroupUser() {
    return this.localStorageService.getItem('currentUserPermissions');
  }

  setFiltersDashboard(filtersDashboard: any) {
    this.localStorageService.setItem(
      'filtersDashboard',
      JSON.stringify(filtersDashboard)
    );
  }
  getFiltersDashboard() {
    return this.localStorageService.getItem('filtersDashboard');
  }

  removeFiltersDashboard(): void {
    this.localStorageService.removeItem('filtersDashboard');
  }

  setBtnFilterMap(btnFilterMap: any) {
    this.localStorageService.setItem('btnFilterMap', btnFilterMap);
  }

  getBtnFilterMap() {
    return this.localStorageService.getItem('btnFilterMap');
  }

  removeBtnFilterMap(): void {
    this.localStorageService.removeItem('btnFilterMap');
  }

  get keyForDashboardReport() {
    return this.currentSession.uid + 'filtersDashboardReport';
  }

  setFiltersDashboardReport(filtersDashboard: any) {
    this.localStorageService.setItem(
      this.keyForDashboardReport,
      JSON.stringify(filtersDashboard)
    );
  }
  getFiltersDashboardReport() {
    let dataString = this.localStorageService.getItem(
      this.keyForDashboardReport
    );

    if (dataString === null) {
      return {};
    }

    return JSON.parse(dataString);
  }

  removeFiltersDashboardReport(): void {
    this.localStorageService.removeItem(this.keyForDashboardReport);
  }

  setFiltersSheduledEvents(filtersSheduledEvents: any) {
    this.localStorageService.setItem(
      'filtersSheduledEvents',
      JSON.stringify(filtersSheduledEvents)
    );
  }
  getFiltersSheduledEvents() {
    return this.localStorageService.getItem('filtersSheduledEvents');
  }

  setUserSelectedCalendar(filtersSheduledEvents: any) {
    this.localStorageService.setItem(
      'asignedTo_filter_calendar',
      filtersSheduledEvents
    );
  }
  getUserSelectedCalendar() {
    return this.localStorageService.getItem('asignedTo_filter_calendar');
  }

  setStatusMapDashboard(status: any) {
    this.localStorageService.setItem('status_map_dashboard', status);
  }
  getCurrentStatusMapDashboard() {
    return this.localStorageService.getItem('status_map_dashboard');
  }

  removeFiltersSheduledEvents(): void {
    this.localStorageService.removeItem('filtersSheduledEvents');
  }

  getListTimeZones() {
    let dataString = this.localStorageService.getItem('listTimeZones');
    if (!dataString) {
      return null;
    }

    return JSON.parse(dataString);
  }

  getListTimeZonesPromise() {
    let promise = new Promise((resolve, reject) => {
      this.userService.getListTimeZones().subscribe(
        (response) => {
          if (response && response.status && response.status === 'ok') {
            this.setListTimeZones(JSON.stringify(response.data));

            resolve(response.data);
          } else {
            reject(new Error());
          }
        },
        (e) => {
          reject(e);
        }
      );
    });

    return promise;
  }

  setListTimeZones(list: any) {
    this.localStorageService.setItem('listTimeZones', JSON.stringify(list));
  }

  removeListTimZones() {
    this.localStorageService.removeItem('listTimeZones');
  }

  setTimeZoneConfig(data) {
    this.localStorageService.setItem('currentTimeZone', JSON.stringify(data));
  }

  getTimeZoneConfig() {
    let dataString = this.localStorageService.getItem('currentTimeZone');
    if (!dataString) {
      return null;
    }

    return JSON.parse(dataString);
  }
}
