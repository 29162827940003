import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth.service';
import * as firebase from 'firebase';
import { ConfigCustomer } from '@app/models/customer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  customer_route = '';
  api_customer_key = '';
  constructor(
    public http: HttpClient,
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.api_customer_key = sessionStorage.getItem('api_customer_key');
  }

  archivedOrder(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });

    const url = environment.urlEndpointMaster + '/archivedOrder';
    return this.http.post(url, body, { headers });
  }

  deletedOrder(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });

    const url = environment.urlEndpointMaster + '/deletedOrder';
    return this.http.post(url, body, { headers });
  }

  getInfoOrder_(order_uuid: any, customer: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': customer,
    });
    const url =
      environment.urlEndpointMaster + '/getDataEventWeb/' + order_uuid;
    return this.http.post(url, {}, { headers });
  }

  getInfoOrderWithUrl(order_uuid: any, path: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url =
      environment.urlEndpointMaster + '/getDataEventWeb/' + order_uuid;
    return this.http.post(url, { url_path: path }, { headers });
  }

  getOrdersApi(body: any): Observable<any> {
    console.log('llega aqui');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/newDashboardBigQuery';
    return this.http.post(url, body, { headers });
  }

  sendRequestClientReport(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/requestSendClientReportPdf';
    return this.http.post(url, body, { headers });

  }

}
