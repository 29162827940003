import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  title_terms: string = 'AVISO DE PRIVACIDAD';
  terms_conditions: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TermsConditionsComponent>,
  ) {

    if (this.data != '') {
      this.title_terms = this.data.title;
      this.terms_conditions = this.data.terms;
    }

   }

  ngOnInit() {
  }

  cancleTerms(){
    this.dialogRef.close(false);
  }

  aceptedTerms(){
    this.dialogRef.close(true);
  }
}
