import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigCustomer } from '../models/customer';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramingService {
  public customerDB: firebase.database.Database = null;
  public customerSelected: ConfigCustomer = null;
  api_customer_key = '';
  constructor(public http: HttpClient) {
    this.api_customer_key = sessionStorage.getItem('api_customer_key');
  }

  private fooSubject = new Subject<any>();

  publishSomeData(data: any) {
    this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
    return this.fooSubject;
  }

  getScheduled(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/apiCreateEventProgramming';
    return this.http.post(url, body, { headers });
  }

  getInfoOrder(uid: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/getDataEventWeb/' + uid;
    return this.http.post(url, {}, { headers });
  }

  getPeriodicty(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/getPeriodicity';
    return this.http.get(url, { headers });
  }

  getScheduledList(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/getScheduledEvents';
    console.log(url);
    return this.http.get(url, { headers });
  }

  getScheduledEventsFilters(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/getScheduledEventsFilters';
    console.log(url);
    return this.http.post(url, body, { headers });
  }

  getScheduledEventsFiltersCalendar(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url =
      environment.urlEndpointMaster + '/getScheduledEventsFiltersCalendar';
    console.log(url);
    return this.http.post(url, body, { headers });
  }

  saveProgrammingEvent(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });

    const url = environment.urlEndpointMaster + '/apiCreateEventProgramming';
    return this.http.post(url, body, { headers });
  }

  saveProgrammingBulkLoadEvent(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });

    const url = environment.urlEndpointMaster + '/uploadExcelbulk';
    return this.http.post(url, body, { headers });
  }

  deleteProgrammingEvent(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });

    const url = environment.urlEndpointMaster + '/apiDeleteEventProgramming';
    return this.http.post(url, body, { headers });
  }

  editProgrammingEvent(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });

    const url = environment.urlEndpointMaster + '/apiEditEventProgramming';
    return this.http.post(url, body, { headers });
  }

  getLatLongByAddress(url: string) {
    return this.http.get(url);
  }

  updateStatusOrder(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });

    const url = environment.urlEndpointMaster + '/updateStatusOrder';
    return this.http.post(url, body, { headers });
  }
}
