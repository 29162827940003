import { Component, OnInit } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../core/user.service";
import { AuthService } from "../../core/auth.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { StorageService } from "../../core/storage.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from "@angular/common";
import { ModalGenericService } from "@app/services/modal-generic.service";
import { take } from "rxjs/operators";
import { PermissionService } from "../../core/permission.service";
import { Session } from "../../models/session.model";
import { MatDialog } from "@angular/material/dialog";
import { ModalImageComponent } from "../modal-image/modal-image.component";

@Component({
  selector: "app-user-add",
  templateUrl: "./user-add.component.html",
  styleUrls: ["./user-add.component.scss"],
})
export class UserAddComponent implements OnInit {
  // imageChangedEvent: any = '';
  errorMessage: string = "";
  errorMessageFN: string = "";
  errorMessageLN: string = "";
  errorMessageEM: string = "";
  errorMessageR: string = "";
  errorMessageS: string = "";

  // croppedImage: any = '';
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  reloadPreview: boolean = true;
  showActive: boolean = false;
  formulario: any = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    job: "",
    city: "",
    role: "",
    status: "",
    password: "",
    repeat_password: "",
    name_user: "",
    lastName_user: "",
    photoURL: "",
    userId: "",
  };

  zone_list_selected: any[] = [];
  zoneSelect = [];

  uid: string = "";
  private sub: any;
  isDisableds: boolean = false;
  listAvatares: any = [];
  listAvatar: any = [];
  avatar: string = "Azul";
  get isEdit(): Boolean {
    return this.uid !== "";
  }

  get listAv() {
    return this.listAvatar;
  }

  base64;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public storageService: StorageService,
    private spinner: NgxSpinnerService,
    private location: Location,
    private modalGeneric: ModalGenericService,
    public permisionService: PermissionService,
    private dialog: MatDialog
  ) {
    if (authService.customerDB != null) {
      userService.customerDB = authService.customerDB;
      userService.customerSelected = authService.customerSelected;
      userService.getCurrentUser().then((user) => {});
    }
    this.formulario.status = "inactive";
  }

  async getAllZoneFirebase() {
    try {
      let firebase_tenan = await this.authService.getTenancyParams();
      const querySnapshot = await firebase_tenan.collection("zones").get();
      console.log(`query data ${!querySnapshot.empty}`);
      querySnapshot.forEach((doc) => {
        const { name, users } = doc.data();
        console.log(name, users);
        const dataZone = {
          id: doc.id,
          text: name,
        };
        this.zone_list_selected.push({ ...dataZone });
        if (users && users.includes(this.uid)) {
          this.zoneSelect.push({ ...dataZone });
        }
      });
    } catch (error) {
      console.log(error);
    }
    console.log("zone list", this.zone_list_selected);
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      const finalUrl = this.router.url;
      this.isDisableds = finalUrl.includes("/user-list/view/");
      this.uid = params["id"] || "";
      if (this.uid) {
        this.showActive = true;
        this.spinner.show();
        this.userService.getUser({ uid: this.uid }).subscribe((response) => {
          let selected_user = response.data;
          if (selected_user) {
            console.log("GET USER ", selected_user);

            this.formulario.name_user = selected_user.name_user
              ? selected_user.name_user
              : selected_user.displayName;
            this.formulario.lastName_user = selected_user.lastName_user
              ? selected_user.lastName_user
              : "";

            this.formulario.email = selected_user.email;
            this.formulario.role = selected_user.profile;
            this.formulario.status = selected_user.disabled
              ? "inactive"
              : "active";
            this.formulario.department = selected_user.departamento;
            this.formulario.office = selected_user.sucursal;
            this.formulario.photoURL = selected_user.photoURL;
            this.previewUrl = selected_user.photoURL;
            this.formulario.phone = selected_user.phone;
            this.formulario.city = selected_user.city;
            this.formulario.job = selected_user.job;
            this.formulario.department = selected_user.departamento;
            this.formulario.department = selected_user.departamento;
            this.formulario.userId = selected_user.userId;
            if (this.listAvatares.length > 0) {
              if (this.formulario.role === "SUPERVISOR") {
                this.listAvatar = this.listAvatares.filter(
                  (x) => x.perfil === "ADMINISTRADOR"
                );
              } else {
                this.listAvatar = this.listAvatares.filter(
                  (x) => x.perfil == this.formulario.role
                );
              }

              let find = this.listAvatar.find(
                (x) => x.photo == this.formulario.photoURL
              );
              console.log("editthis.listAvatares 1-", find);
              if (find != undefined) {
                this.avatar = find.color;
              } else {
                this.avatar = "";
              }
            }
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.cancelSave();
          }
        });
      } else {
        this.showActive = false;
        this.formulario.password = "FakePass2021";
        this.formulario.repeat_password = "FakePass2021";
      }
    });
    this.userService.getAvatares().subscribe((response) => {
      this.listAvatares = response.data;
      console.log(
        "editthis.listAvatares 2",
        this.listAvatares,
        this.formulario
      );
      if (this.uid) {
        if (this.formulario.role === "SUPERVISOR") {
          this.listAvatar = this.listAvatares.filter(
            (x) => x.perfil === "ADMINISTRADOR"
          );
        } else {
          this.listAvatar = this.listAvatares.filter(
            (x) => x.perfil == this.formulario.role
          );
        }

        let find = this.listAvatar.find(
          (x) => x.photo == this.formulario.photoURL
        );
        if (find != undefined) {
          this.avatar = find.color;
        } else {
          this.avatar = "";
        }
        console.log("editthis.listAvatares 2-", this.avatar);
      }
    });
    this.getAllZoneFirebase();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getImage() {
    const dialogRef = this.dialog
      .open(ModalImageComponent, {
        data: {
          cutout_shape: "circle",
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp.button == "aceptar") {
          this.previewUrl = resp.imageB64;
          this.base64 = resp.imageB64;
          this.avatar = "";
        }
      });

    // if (!this.isDisableds) {
    //   const input = document.getElementById('file');
    //   input.click();
    // }
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    console.log("this.fileData>>>>>>>>>>>>>>>>>>>", this.fileData);
    this.preview();
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.base64 = reader.result;
    };
  }

  applyReload() {
    this.reloadPreview = false;
    this.previewUrl = "";
    setTimeout(() => {
      this.reloadPreview = true;
    }, 100);
  }

  validateEmialFormat(email) {
    var patron =
      /^[a-z0-9_-]+(?:.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    if (!patron.test(email)) {
      this.errorMessageEM = this.translateService.instant(
        "PAGES.USER_ADD.ERRORS.FORMAT_EMAIL"
      );
    } else {
      this.errorMessageEM = "";
    }
  }

  sendModelError(msg: string) {
    this.modalGeneric.onModelerror(
      false, //disableClose
      msg, //message
      false, // cancelButton
      this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
      true, // info
      false //imagen info
    );
  }

  async saveUser() {
    var enter = true;
    console.log("EL FORMULARIO HA LLEGADO ", JSON.stringify(this.formulario));

    if (this.formulario.name_user.trim() === "") {
      enter = false;
      this.errorMessageFN =
        this.translateService.instant("PAGES.USER_ADD.ERRORS.GENERAL") +
        this.translateService
          .instant("PAGES.USER_ADD.FORM.FIRST_NAME")
          .toLowerCase() +
        this.translateService
          .instant("PAGES.USER_ADD.ERRORS.VALID")
          .toLowerCase();
      //this.sendModelError(this.translateService.instant('PAGES.USER_ADD.ERRORS.EMPTY_FIRST_NAME'))
    }
    if (this.formulario.lastName_user.trim() === "") {
      enter = false;
      this.errorMessageLN =
        this.translateService.instant("PAGES.USER_ADD.ERRORS.GENERAL") +
        this.translateService
          .instant("PAGES.USER_ADD.FORM.LAST_NAME")
          .toLowerCase() +
        this.translateService
          .instant("PAGES.USER_ADD.ERRORS.VALID")
          .toLowerCase();
      //this.sendModelError(this.translateService.instant('PAGES.USER_ADD.ERRORS.EMPTY_LAST_NAME'))
    }
    if (this.formulario.email.trim() === "") {
      enter = false;
      this.errorMessageEM =
        this.translateService.instant("PAGES.USER_ADD.ERRORS.GENERAL") +
        this.translateService
          .instant("PAGES.USER_ADD.FORM.EMAIL")
          .toLowerCase() +
        this.translateService
          .instant("PAGES.USER_ADD.ERRORS.VALID")
          .toLowerCase();
      //this.sendModelError(this.translateService.instant('PAGES.USER_ADD.ERRORS.EMPTY_EMAIL'))
    }
    if (this.formulario.role.trim() === "") {
      enter = false;
      //this.sendModelError(this.translateService.instant('PAGES.USER_ADD.ERRORS.EMPTY_ROLE'))
      this.errorMessageR =
        this.translateService.instant("PAGES.USER_ADD.ERRORS.GENERAL") +
        this.translateService
          .instant("PAGES.USER_ADD.FORM.ROLE")
          .toLowerCase() +
        this.translateService
          .instant("PAGES.USER_ADD.ERRORS.VALID")
          .toLowerCase();
    }
    if (this.formulario.status.trim() === "") {
      enter = false;
      //this.sendModelError(this.translateService.instant('PAGES.USER_ADD.ERRORS.EMPTY_STATUS'))
      this.errorMessageS =
        this.translateService.instant("PAGES.USER_ADD.ERRORS.GENERAL") +
        this.translateService
          .instant("PAGES.USER_ADD.FORM.STATUS")
          .toLowerCase() +
        this.translateService
          .instant("PAGES.USER_ADD.ERRORS.VALID")
          .toLowerCase();
    }
    if (!this.uid && this.formulario.password.trim() === "") {
      enter = false;
      this.sendModelError(
        this.translateService.instant("PAGES.USER_ADD.ERRORS.EMPTY_PASSWORD")
      );
    }
    if (
      !this.uid &&
      this.formulario.password.trim() !== this.formulario.repeat_password.trim()
    ) {
      enter = false;
      this.sendModelError(
        this.translateService.instant("PAGES.USER_ADD.ERRORS.DIFERENT_PASSWORD")
      );
    }
    if (
      this.uid &&
      this.formulario.password.trim() !== "" &&
      this.formulario.password.trim() !== this.formulario.repeat_password.trim()
    ) {
      enter = false;
      this.sendModelError(
        this.translateService.instant("PAGES.USER_ADD.ERRORS.DIFERENT_PASSWORD")
      );
    }

    if (enter) {
      const session = this.storageService.getCurrentSession();
      this.spinner.show();

      console.log("EL FORMULARIO HA LLEGADO ", JSON.stringify(this.formulario));

      let data: any = {
        displayName: `${this.formulario.name_user} ${this.formulario.lastName_user}`,
        name_user: this.formulario.name_user,
        lastName_user: this.formulario.lastName_user,
        email: this.formulario.email,
        profile: this.formulario.role,
        sucursal: this.formulario.office,
        departamento: this.formulario.department,
        photoURL: this.formulario.photoURL,
        phone: this.formulario.phone || "",
        city: this.formulario.city || "",
        job: this.formulario.job || "",
        password: this.formulario.password,
        disabled: this.formulario.status === "inactive",
        base64: this.base64 || "",
        userId: this.formulario.userId || "",
      };
      if (this.uid) {
        data.uid = this.uid;

        if (this.uid == session.uid) {
          session.displayName = data.displayName;
          this.storageService.setCurrentSession(session);
        }
        if (this.formulario.password.trim() === "") {
          delete data.password;
        }
      }

      if (this.base64) {
        const downloadUrl = await this.userService.uploadPhoto(
          this.uid,
          this.base64
        );
        data.base64 = "";
        data.photoURL = downloadUrl;
        if (this.uid) {
          if (this.uid == session.uid) {
            const session = this.storageService.getCurrentSession();
            session.photoUrl = downloadUrl;
            this.storageService.setCurrentSession(session);
          }
        }
      } else if (
        this.formulario.photoURL != null &&
        this.formulario.photoURL != undefined
      ) {
        if (this.uid) {
          if (this.uid == session.uid) {
            const session = this.storageService.getCurrentSession();
            session.photoUrl = this.formulario.photoURL;
            this.storageService.setCurrentSession(session);
          }
        }
      }

      console.log("~ data", data);

      if (this.uid) {
        await this.saveZoneUserId();
      }

      this.userService.createUser(data).subscribe((response) => {
        this.spinner.hide();
        if (response.status == "ok") {
          if (this.isEdit) {
            this.sendSuccess();
          } else {
            this.sendSuccess();
          }
        } else if (response.status == "fail") {
          if (!response.message.code) {
            this.sendSuccess();
          } else {
            this.sendModelError(
              this.translateService.instant(
                "PAGES.USER_ADD.ERRORS." +
                  (response.message.code || "").toUpperCase()
              )
            );
          }
        }
      });
    }
  }

  async saveZoneUserId() {
    let firebase_tenan = await this.authService.getTenancyParams();
    this.zoneSelect.map(async (itemZone) => {
      console.log(itemZone.id);
      const containersIdZone = await firebase_tenan
        .collection("zones")
        .where("users", "array-contains", this.uid)
        .get();
      containersIdZone.forEach((item) => {
        const { users = [] } = item.data();
        item.ref.update({
          users: users.filter((userId) => userId !== this.uid),
        });
      });
      const querySnapshot = await firebase_tenan
        .collection("zones")
        .doc(itemZone.id)
        .get();
      const { users = [] } = querySnapshot.data();
      users.push(this.uid);
      querySnapshot.ref.update({ users });
    });
  }

  sendSuccess() {
    this.modalGeneric
      .onModelexito(
        false, //disableClose
        this.uid
          ? this.translateService.instant("PAGES.USER_ADD.SUCCESS.UPDATED")
          : this.translateService.instant("PAGES.USER_ADD.SUCCESS.SAVED"), //message
        false, // cancelButton
        this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
        true, // info
        false //imagen info
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        const ruta = "/" + this.storageService.getCurrentDB() + "/user-list";
        //this.router.navigate([ruta]);
        location.href = ruta;
      });
  }

  cancelSave() {
    // const ruta = '/' + this.storageService.getCurrentDB() + '/user-list';
    // this.router.navigate([ruta]);
    this.location.back();
  }

  cleanSave() {
    this.formulario.email = "";
    this.formulario.password = "";
    this.formulario.first_name = "";
    this.formulario.name_user = "";
    this.formulario.lastName_user = "";
    this.formulario.last_name = "";
    this.formulario.status = "inactive";
    this.formulario.role = "";
    this.formulario.office = "";
    this.formulario.department = "";
    this.formulario.phone = "";
    this.formulario.job = "";
    this.formulario.city = "";
    this.formulario.repeat_password = "";
    this.formulario.userId = "";
  }

  validPassword() {
    if (this.formulario.password.trim() !== "") {
      let password = this.formulario.password;

      // Do not show anything when the length of password is zero.
      if (password.length === 0) {
        return ["has-error"];
      }
      // Create an array and push all possible values that you want in password
      var matchedCase = new Array();
      matchedCase.push("[$@$!%*#?&]"); // Special Charector
      matchedCase.push("[A-Z]"); // Uppercase Alpabates
      matchedCase.push("[0-9]"); // Numbers
      matchedCase.push("[a-z]"); // Lowercase Alphabates

      // Check the conditions
      var ctr = 0;
      for (var i = 0; i < matchedCase.length; i++) {
        if (new RegExp(matchedCase[i]).test(password)) {
          ctr++;
        }
      }
      // Display it
      var response = ["very_week"];
      switch (ctr) {
        case 0:
        case 1:
        case 2:
          break;
        case 3:
          response = ["medium"];
          break;
        case 4:
          response = ["strong"];
          break;
      }

      return response;
    } else {
      return [];
    }
  }

  eventChange(change) {
    if (change == "name") {
      this.errorMessageFN = "";
    }
    if (change == "email") {
      this.errorMessageEM = "";
    }
    if (change == "select") {
      this.errorMessageR = "";
      if (this.formulario.role === "SUPERVISOR") {
        this.listAvatar = this.listAvatares.filter(
          (x) => x.perfil == "ADMINISTRADOR"
        );
      } else {
        this.listAvatar = this.listAvatares.filter(
          (x) => x.perfil == this.formulario.role
        );
      }

      let find = this.listAvatar.find((x) => x.color == "Azul");

      this.avatar = "Azul";
      this.previewUrl = find.photo;
      this.formulario.photoURL = find.photo;
      console.log(find);
    }
    if (change == "last") {
      this.errorMessageLN = "";
    }
  }

  eventChangeAvatar(change) {
    let find = this.listAvatar.find((x) => x.color == change);
    this.previewUrl = find.photo;
    this.formulario.photoURL = find.photo;
    console.log(find);
  }

  onchangeZone(event) {
    console.log(`zone select`, event);
    this.zoneSelect = event.map((item) => item.id);
  }

  editProfile() {
    const ruta =
      "/" + this.storageService.getCurrentDB() + "/user-list/edit/" + this.uid;
    this.router.navigate([ruta]);
  }
}
