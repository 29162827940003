import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StorageService} from "@app/core/storage.service";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "@app/core/user.service";
import {ModalGenericService} from "@app/services/modal-generic.service";
import {TranslateService} from "@ngx-translate/core";
import {take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {TimezoneConversionService} from "@app/core/timezone-conversion.service";

@Component({
  selector: 'app-configuration-timezone',
  templateUrl: './configuration-timezone.component.html',
  styleUrls: ['./configuration-timezone.component.scss']
})
export class ConfigurationTimezoneComponent implements OnInit, OnDestroy {
  formTimeZone: FormGroup = new FormGroup({
      timezone: new FormControl('', [Validators.required])
  })
  timeZones: any[] = [];
  onDestroy: Subject<any> = new Subject<any>();
  constructor(private storageService: StorageService,
              private spinner: NgxSpinnerService,
              private modalGeneric: ModalGenericService,
              public translateService: TranslateService,
              public userService: UserService,
              private timezoneConversionService: TimezoneConversionService) {
              this.spinner.show();
              this.timeZones = this.storageService.getListTimeZones();
              this.userService.getSettingsClient().pipe(takeUntil(this.onDestroy)).subscribe((response) => {
                  if (response.data && response.data.length > 0) {
                      this.formTimeZone.get('timezone').setValue(response.data[0].timezone_id ? response.data[0].timezone_id : '');
                  }
                  this.spinner.hide();
              }, error => {
                  this.spinner.hide();
              });
  }

  ngOnInit() {
  }

    async saveConfiguration(){
        this.spinner.show();

        var configuration_client2 = {
            timezone_id: this.formTimeZone.get('timezone').value
        }

        this.userService.saveConfigurationClient(configuration_client2).pipe(takeUntil(this.onDestroy)).subscribe(response => {
            this.spinner.hide();
            if (response.status === 'ok') {
                let foundTimeZone = this.timeZones.find(item => item['firebase_id'] == configuration_client2.timezone_id);
                if (foundTimeZone) {
                    this.storageService.setTimeZoneConfig(foundTimeZone);
                    this.timezoneConversionService.getInfoCurrentTime$(foundTimeZone['reference'], (foundTimeZone['label_short'] || foundTimeZone['label'])).pipe(takeUntil(this.onDestroy)).subscribe((response) => {

                    });
                }
                this.sendSuccess(this.translateService.instant('PAGES.CONFIGURATION_CLIENT.LOGOTIPO'));
            } else {
                this.sendModelError(this.translateService.instant('PAGES.CONFIGURATION_CLIENT.ERROR'));
            }
        }, error => {
            this.spinner.hide();
            this.sendModelError(error.toString());
        });

    }

    sendSuccess(txt: string) {
        this.modalGeneric.onModelexito(
            false, //disableClose
            txt, //message
            false,  // cancelButton
            this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
            true, // info
            false, //imagen info
        )
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                this.spinner.hide();
            })
    }


    sendModelError(msg: string) {
        this.modalGeneric.onModelerror(
            false, //disableClose
            msg, //message
            false,  // cancelButton
            this.translateService.instant('MESSAGE.ACEPTAR'),  //confimButtonText
            true, // info
            false, //imagen info
        )
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.unsubscribe();
    }

}
