import { FormControl, FormGroup } from '@angular/forms';
export class PasswordValidator {
    static match(control: FormControl): any {

        let group:any = control.parent;
        if( group == undefined){
            return null;
        }

        const passwordInput = group.controls.password;
        const passwordConfirmationInput = group.controls.confirmPassword;
        if (passwordInput.pristine || passwordConfirmationInput.pristine) {
           
            return null;
        }
        if (passwordInput.value != passwordConfirmationInput.value) {

            return { match: true };
        }
        return null
    }
    static mayusMinus(control: FormControl): any {
        if (!control.value) return null
        const hasUpper = /[A-Z]/.test(control.value)
        const hasLower = /[a-z]/.test(control.value)
        if (hasLower && hasUpper) return null
        else return { 'invalid_upperLower' : true }
    }
    static hasNum(control: FormControl): any {
        if (!control.value) return null
        const hasNumber = /[0-9]/.test(control.value)
        if (hasNumber) return null
        else return { 'number_missing' : true }
    }
    static hasSymbol(control: FormControl): any {
        if (!control.value) return null
        const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(control.value)
        if (hasSymbol) return null
        else return { 'symbol_missing' : true }
    }
}