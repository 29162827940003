import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../core/events.service';
import { AuthService } from '../../core/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../core/user.service';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { MatDialog} from  '@angular/material/dialog';
import { ModalImageComponent } from '@app/user-list/modal-image/modal-image.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilValidator } from '@app/validators/UtilValidator';

@Component({
  selector: 'app-configuration-webhook',
  templateUrl: './configuration-webhook.component.html',
  styleUrls: ['./configuration-webhook.component.scss']
})
export class ConfigurationWebhookComponent implements OnInit {

  previewUrl: any = '';
  public settings_client: any = [];
  fileData: File = null;

  webhookForm: FormGroup;

  constructor(
    public eventsService: EventsService,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    public userService: UserService,
    private modalGeneric: ModalGenericService,
    public translateService: TranslateService,
    private  dialog:  MatDialog,
    private fb: FormBuilder
  ) {
    userService.customerDB = authService.customerDB;
    userService.customerSelected = authService.customerSelected;
    userService.getCurrentUser().then((user) => {});
    
    
    this.webhookForm = this.fb.group({
      webhook_header: ['', Validators.compose([ UtilValidator.isJsonValid ] ) ],
      webhook_url: ['',  Validators.compose([ UtilValidator.isUrlValid] ) ]
    });

    this.spinner.show();
    userService.getSettingsClient().subscribe((response) => {
      console.log('RESPONSE DE GET SETTINGS ', response);
      console.log('RESPONSE DE GET SETTINGS ----- ', response.data[0]);
      this.settings_client = response.data[0];
      //this.previewUrl = this.settings_client.logo_client;
      this.spinner.hide();

      this.webhookForm.setValue({
        webhook_header: this.settings_client.webhook_header, 
        webhook_url: this.settings_client.webhook_url
      });
     
      
    });
   }

  ngOnInit() {
   
  }

  async saveConfiguration(){


    console.log('0000', this.webhookForm.value )

    let dataToSend = this.webhookForm.value;

    let webhook_header = dataToSend['webhook_header'];
    let webhook_url = dataToSend['webhook_url'];

    this.spinner.show();
      
    var configuratio_client2 = {
      webhook_header:webhook_header,
      webhook_url:webhook_url
    }

    this.userService.saveConfigurationClient(configuratio_client2).subscribe(response => {

      this.spinner.hide();
      if (response.status === 'ok') {
        this.sendSuccess(this.translateService.instant('PAGES.CONFIGURATION_CLIENT.LOGOTIPO'));
      } else {
        this.sendModelError(this.translateService.instant('PAGES.CONFIGURATION_CLIENT.ERROR'))
      }

    });

  }

  sendSuccess(txt: string) {
    this.modalGeneric.onModelexito(
        false, //disableClose
        txt, //message
        false,  // cancelButton
        this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
        true, // info
        false, //imagen info
    )
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.spinner.show();
          window.location.reload();
        })
  }


  sendModelError(msg: string) {
    this.modalGeneric.onModelerror(
        false, //disableClose
        msg, //message
        false,  // cancelButton
        this.translateService.instant('MESSAGE.ACEPTAR'),  //confimButtonText
        true, // info
        false, //imagen info
    )
  }

}
