import { UserService } from "@app/core/user.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "../../core/storage.service";
import { AuthService } from "@app/core/auth.service";
import { PermissionService } from "../../core/permission.service";
import { NotificationService } from "../../services/notification.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { TimezoneConversionService } from "@app/core/timezone-conversion.service";
import { EncryptionDecryptionService } from "../../services/encryption-decryption.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() user_email: string = "";
  @Input() title: string = "";
  @Input() showoptions: boolean = true;
  @Output() logOut = new EventEmitter<boolean>();
  rolUser: string = "";
  nameUser: string = "";
  ruta_home = "";
  photo_user = "assets/profile.svg";
  uid = "";
  notify: any[] = [];
  closed: number;
  start: number;
  vencidos: number;
  quatity: number = 0;
  onDestroy: Subject<any> = new Subject<any>();
  dataCurrentTime: any = {};
  item: any;
  url_report_authorize = "";
  constructor(
    private router: Router,
    public storageService: StorageService,
    public userService: UserService,
    public authService: AuthService,
    public permisionService: PermissionService,
    private notifyservice: NotificationService,
    private spinner: NgxSpinnerService,
    private timezoneConversionService: TimezoneConversionService,
    private encryptDecryptService: EncryptionDecryptionService
  ) {
    if (authService.customerDB != null) {
      let dataSettings = this.storageService.getCurrentSettingsAdminWeb();
      if (
        dataSettings == null ||
        dataSettings == undefined ||
        dataSettings == ""
      ) {
        this.storageService.setCurrentSettingsAdminWeb(
          this.authService.customerDB
        );
      }
      let dataDetailUser = this.storageService.getDetailDataCurrentUser();
      if (
        dataDetailUser == null ||
        dataDetailUser == undefined ||
        dataDetailUser == ""
      ) {
        this.storageService
          .setDetailDataCurrentUser(this.authService.customerDB)
          .then((response) => {
            if (response) {
              this.setValuesUser();
            }
          })
          .catch((error) => {
            console.log("Error obteenr datos del usuario", error);
          });

        //this.setValuesUser();
      } else {
        this.setValuesUser();
      }
    }
    let currentTimezone = this.storageService.getTimeZoneConfig();
    this.timezoneConversionService.initCurrentTime(this.onDestroy);
    this.timezoneConversionService
      .getInfoCurrentTime$(
        currentTimezone.reference,
        currentTimezone.label_short || currentTimezone.label
      )
      .pipe(takeUntil(this.onDestroy))
      .subscribe((response) => {
        if (response) {
          this.dataCurrentTime = response;
        } else {
          this.timezoneConversionService.initCurrentTime(this.onDestroy);
        }
      });
    this.ruta_home = "/" + this.storageService.getCurrentDB() + "/dashboard";
  }

  ngOnInit() {
    if (this.authService.customerDB != null) {
      this.userService.customerDB = this.authService.customerDB;
      this.notifyservice.customerDB = this.authService.customerDB;
      this.notifyservice.customerSelected = this.authService.customerSelected;
      this.getNotifications();
      this.getSettings();
    }
  }
  public getNotifications() {
    this.notifyservice.customerDB.app
      .firestore()
      .collection("notifications")
      .limit(1)
      .orderBy("created", "desc")
      .onSnapshot((res) => {
        this.notifyservice
          .getNotificaction()
          .pipe(takeUntil(this.onDestroy))
          .subscribe(
            (response) => {
              console.log("response getNotificaction", response);
              this.notify = response.notifications;
              this.closed = response.closed;
              this.start = response.iniciaron;
              this.vencidos = response.vencidos;
              this.quatity = response.quantityNotRead;
            },
            (error) => {
              console.log("error", error);
            }
          );
      });
  }

  getSettings() {
    this.userService.getSettingsClient().subscribe((respose: any) => {
      this.url_report_authorize = respose.data[0].url_report;
    });
  }

  public setValuesUser() {
    let dataDetailUser = this.storageService.getDetailDataCurrentUser();
    let sessionlUser = this.storageService.getCurrentSession();
    //console.log('SESSION DE USUARIO ',sessionlUser );
    if (dataDetailUser) {
      dataDetailUser = JSON.parse(dataDetailUser);
      this.authService.getAsyncPermissionsDB(dataDetailUser["profile"]);
      this.rolUser = dataDetailUser["profile"];
      this.uid = dataDetailUser["uid"];
      console.log("this.uid", this.uid);
      this.nameUser = sessionlUser.displayName;
      if (sessionlUser.photoUrl != "") {
        this.photo_user = sessionlUser.photoUrl;
      }
    }
  }
  setReadNotifications(isBatch: boolean, item: any = {}) {
    console.log("item", item);
    this.item = item;
    this.spinner.show();
    let body = { isBatch: isBatch, ids: [] };
    if (isBatch) {
      body.ids = this.notify
        .filter((not) => !not.read)
        .map((notmap) => {
          return notmap.not_id;
        });
    } else {
      body.ids = item["read"] === false ? [item["not_id"]] : [];
    }
    if (isBatch && body.ids.length === 0) {
      this.spinner.hide();
      return false;
    } else if (body.ids.length === 0) {
      this.spinner.hide();
      this.DetailGo(item["event"].split(" - ")[0]);
      return false;
    }
    this.userService
      .setReadNotifications(body)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (response) => {
          if (!isBatch) {
            this.DetailGo(item["event"].split(" - ")[0], true);
          } else {
            this.getNotifications();
            this.spinner.hide();
          }
        },
        (error) => {
          if (!isBatch) {
            this.DetailGo(item["event"].split(" - ")[0], true);
          } else {
            this.spinner.hide();
          }
        }
      );
  }
  DetailGo(id: string, updateNot: boolean = false) {
    this.spinner.hide();
    if (this.storageService.getCurrentDB() != "") {
      this.userService.customerDB.app
        .firestore()
        .collection("orders")
        .where("order_number", "==", id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc: any) => {
            let urlActividades = "";

            if (
              this.item.authorization &&
              this.item.authorization === true &&
              (this.item.authorization_type == 1 ||
                this.item.authorization_type == 2)
            ) {
              urlActividades =
                this.url_report_authorize +
                doc.id +
                "&authorizer=" +
                encodeURIComponent(
                  this.encryptDecryptService.encryptRerturnSimpleString(
                    this.uid
                  )
                );
            } else {
              urlActividades =
                `${location.protocol}//${location.host}/` +
                this.storageService.getCurrentDB() +
                "/detail-order?order_uui=" +
                doc.id;
            }

            window.open(urlActividades, "blank");
          });
          /**/
        })
        .catch((err) => {
          console.log("Error Firebae -> " + err);
        });
      if (updateNot) {
        this.getNotifications();
      }
    }
  }

  public _logOut() {
    this.logOut.emit(true);
  }

  public _sendToFieldActivity(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta = "/" + this.storageService.getCurrentDB() + "/dashboard";
      this.router.navigate([ruta]);
    }
  }

  public _sendToUserList(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta = "/" + this.storageService.getCurrentDB() + "/user-list";
      this.router.navigate([ruta]);
    }
  }

  public _sendToFolrmularios(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta = "/" + this.storageService.getCurrentDB() + "/events-list";
      this.router.navigate([ruta]);
    }
  }

  public _sendToEquipments(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta = "/" + this.storageService.getCurrentDB() + "/equipments";
      this.router.navigate([ruta]);
    }
  }

  public _sendToProgramming(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta =
        "/" + this.storageService.getCurrentDB() + "/programming-list";
      this.router.navigate([ruta]);
    }
  }

  public _sendToCustomersList(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta = "/" + this.storageService.getCurrentDB() + "/customer-list";
      this.router.navigate([ruta]);
    }
  }

  public _sendToCustomers(event: any) {
    if (this.storageService.getCurrentDB() != "") {
      const ruta =
        "/sintra/" + this.storageService.getCurrentDB() + "/dashboard-admin";
      this.router.navigate([ruta]);
    }
  }

  public _sendToConfiguration(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta = "/" + this.storageService.getCurrentDB() + "/configuration";
      this.router.navigate([ruta]);
    }
  }

  public _sendToClassification(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta =
        "/" + this.storageService.getCurrentDB() + "/classification-list";
      this.router.navigate([ruta]);
    }
  }

  public _sentToCatalogs(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta =
        "/" + this.storageService.getCurrentDB() + "/configuration/catalogs";
      console.log(ruta);
      this.router.navigate([ruta]);
    }
  }

  public _sendToHourLabor(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta =
        "/" + this.storageService.getCurrentDB() + "/configuration/hour-labor";
      this.router.navigate([ruta]);
    }
  }

  public _sendToWebhook(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta =
        "/" + this.storageService.getCurrentDB() + "/configuration/webhook";
      this.router.navigate([ruta]);
    }
  }

  public _sendToTimeZone(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta =
        "/" + this.storageService.getCurrentDB() + "/configuration/time-zone";
      this.router.navigate([ruta]);
    }
  }

  public _sendToDashboardReport(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta =
        "/" + this.storageService.getCurrentDB() + "/dashboardreport";
      this.router.navigate([ruta]);
    }
  }

  public _sendToHelp(event: any) {
    event.preventDefault();
    if (this.storageService.getCurrentDB() != "") {
      const ruta = "/" + this.storageService.getCurrentDB() + "/help";
      this.router.navigate([ruta]);
    }
  }

  goProfile() {
    const ruta =
      "/" + this.storageService.getCurrentDB() + "/user-list/view/" + this.uid;
    this.router.navigate([ruta], { state: { view_profile: true } });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
