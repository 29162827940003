import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { StorageService } from '../core/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigCustomer } from '../models/customer';
import { PasswordValidator } from '../validators/PasswordValidator';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private sub: any;
  private jwt: string;
  private uid: string = '';

  showForm: boolean = false;
  showErrors: boolean = false;

  resetForm: FormGroup;

  url_logo_client = '/assets/logo.png';
  error: string = '';
  private onDestroy = new Subject<void>();
  constructor(
    public userService: UserService,
    public authService: AuthService,
    public translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public storageService: StorageService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private modalGeneric: ModalGenericService
  ) {
    this.createForm();
  }

  hasErrorEmptyField(field): any {
    console.log(field);

    if (field.invalid && (field.dirty || field.touched)) {
      return true;
    }

    return false;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.jwt = params['jwt'] || '';
      if (this.jwt) {
        //this.showActive = true;
        this.spinner.show();
        this.authService.customerSelected$
          .pipe(takeUntil(this.onDestroy))
          .subscribe((customerSelected: ConfigCustomer) => {
            if (customerSelected) {
              this.userService.customerSelected = customerSelected;

              this.userService
                .validJwtForResetPassword({ jwt: this.jwt })
                .subscribe(
                  (response) => {
                    console.log(response);
                    if (response.status == 'ok') {
                      this.showForm = true;
                      let jwtP = this.jwt.split('.');
                      console.log(jwtP);
                      if (jwtP.length == 3) {
                        let expObj = JSON.parse(atob(jwtP[1]));

                        this.uid = expObj['uid'];
                      }
                    } else {
                      this.showForm = false;
                      this.error = response.message;
                    }
                    this.spinner.hide();
                  },
                  (err) => {
                    if (
                      err.message ===
                      "Cannot read properties of null (reading 'length')"
                    ) {
                      window.location.reload();
                    } else {
                      this.error = err.error;
                      this.spinner.hide();
                    }
                  }
                );
            }
          });
      } else {
      }
    });
  }

  createForm() {
    this.resetForm = this.fb.group({
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordValidator.mayusMinus,
          PasswordValidator.hasNum,
        ]),
      ],
      confirmPassword: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordValidator.mayusMinus,
          PasswordValidator.hasNum,
          PasswordValidator.match,
        ]),
      ],
    });
  }

  checkErrors() {
    if (this.resetForm.valid) {
      this.showErrors = false;
    } else {
      this.showErrors = true;
    }
  }

  tryReset() {
    if (this.resetForm.valid) {
      this.showErrors = false;
      let dataToSend = this.resetForm.value;

      dataToSend['uid'] = this.uid;
      dataToSend['jwt'] = this.jwt;

      this.spinner.show();
      this.userService.resetPassword(dataToSend).subscribe(
        (response) => {
          this.spinner.hide();

          if (response.status == 'ok') {
            this.modalGeneric
              .onModelexito(
                false, //disableClose
                this.translateService.instant(
                  'PAGES.RESET_PASSWORD.SUCCESSUPDATED'
                ), //message
                false, // cancelButton
                this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                true, // info
                false //imagen info
              )
              .afterClosed()
              .pipe(take(1))
              .subscribe(() => {
                const ruta = '/' + this.authService.customer_route + '/login';
                this.router.navigate([ruta]);
              });
          } else {
            this.modalGeneric.onModelerror(
              false, //disableClose
              this.translateService.instant(
                'PAGES.RESET_PASSWORD.ERRORUPDATED'
              ), //message
              false, // cancelButton
              this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
              true, // info
              false //imagen info
            );
          }
        },
        (err) => {
          this.error = err.error;
          this.spinner.hide();
        }
      );
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
    this.sub.unsubscribe();
  }
}
