import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigCustomer } from '../models/customer';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HourlaborService {
  public customerDB: firebase.database.Database = null;
  public customerSelected: ConfigCustomer = null;
  api_customer_key = '';
  constructor(public http: HttpClient) {
    this.api_customer_key = sessionStorage.getItem('api_customer_key');
  }

  getHourLabor(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/gethourlabor';
    return this.http.get(url, { headers });
  }

  ws_post_create(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });

    const url = environment.urlEndpointMaster + '/createhour';
    return this.http.post(url, body, { headers });
  }

}
